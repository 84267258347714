import { SetPageCalendarModule } from '@max/common/setpage';
import { Card } from '../card';
import { CardSwiperLayout } from './card-swiper-layout';
import { ListViewLayout } from './list-view-layout';

interface EventsCalendarProps {
  module: SetPageCalendarModule;
}

export const EventsCalendar = ({ module }: EventsCalendarProps) => {
  const layout = module.styles?.layout ?? 'list-view';

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      {layout === 'list-view' && <ListViewLayout events={module.events} />}
      {layout === 'card-swiper' && <CardSwiperLayout events={module.events} />}
    </Card>
  );
};
