import { SetPage } from '@max/common/setpage';
import styled from 'styled-components';
import circleBottom from '../../assets/masks/circle-bottom.png';
import drips from '../../assets/masks/drips.png';
import offset from '../../assets/masks/offset.png';
import shield from '../../assets/masks/shield.png';
import { useAssetsContext } from '../../contexts';
import { MOBILE_SELECTOR } from '../../utils';

const Container = styled.div`
  height: 300px;
  color: var(--set-page-accent-color);
  animation: var(--set-page-accent-animation);
  background-size: var(--set-page-accent-background-size);
  background-color: var(--set-page-accent-background-color);
  background-image: var(--set-page-accent-background-image);
  overflow: hidden;
  position: relative;

  @container (${MOBILE_SELECTOR}) {
    height: 200px;
  }

  [data-set-page-cover='none'] & {
    opacity: 0;
  }

  [data-set-page-cover='straight'] & {
  }

  [data-set-page-cover='card'] & {
    border-radius: 0 0 50px 50px;
  }

  [data-set-page-cover='diagonal'] & {
    clip-path: polygon(0 0, 100% 0, 100% 60%, 0 100%);
  }

  [data-set-page-cover='shield'] & {
    mask-image: url(${shield});
    mask-position: bottom;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }

  [data-set-page-cover='offset'] & {
    mask-image: url(${offset});
    mask-position: bottom;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }

  [data-set-page-cover='drips'] & {
    mask-image: url(${drips});
    mask-position: bottom;
    mask-repeat: repeat-x;
    mask-size: cover;
  }

  [data-set-page-cover='circle-bottom'] & {
    mask-image: url(${circleBottom});
    mask-position: bottom;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }

  [data-set-page-theme='rabun'] & {
    margin-bottom: -50px;
  }

  [data-set-page-theme='tropic-colour'] & {
    margin-bottom: -80px;
    mask-image: linear-gradient(0, #eff4f400 10%, #eff4f4 85%);
    clip-path: none;
    border-radius: 0;
  }

  &[data-cropped='true'] {
    width: 720px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface CoverProps {
  page: SetPage;
}

export const Cover = ({ page }: CoverProps) => {
  const { assetUrl } = useAssetsContext();
  const showBackground = page.settings.show_background_image ?? true;

  return (
    <Container data-cropped={page.settings.crop_page_width}>
      {showBackground && page.background && (
        <Image src={assetUrl({ url: page.background })} />
      )}
    </Container>
  );
};
