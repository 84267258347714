import { SetPageButtonLink } from '@max/common/setpage';
import styled from 'styled-components';
import { ButtonLinkItem } from './button-link-item';

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

interface ListLayoutProps {
  buttons: SetPageButtonLink[];
  layout: string;
  defaultImage?: string;
}

export const ListLayout = ({
  buttons,
  layout,
  defaultImage,
}: ListLayoutProps) => {
  return (
    <List>
      {buttons.map((button, index) => (
        <ButtonLinkItem
          data-aos="set-page-fade-right"
          key={index}
          link={button}
          layout={layout}
          defaultImage={defaultImage}
        />
      ))}
    </List>
  );
};
