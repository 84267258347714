import { Concert } from '@max/common/creator';
import { SetPageShow } from '@max/common/setpage';
import { getDistance } from 'geolib';
import { useConcertsContext, useCoordinatesContext } from '../../contexts';
import { getDateTime } from '../../utils';
import { NEAR_DISTANCE } from './constants';

interface ShowItem {
  show: SetPageShow;
  concert: Concert;
  distance: number;
}

export const useShows = (shows: SetPageShow[]) => {
  const { concerts = [] } = useConcertsContext();
  const { coordinates } = useCoordinatesContext();
  const near: ShowItem[] = [];
  const far: ShowItem[] = [];

  for (const show of shows) {
    const concert = concerts.find((concert) => concert.id === show.id);

    if (concert) {
      const distance = coordinates
        ? getDistance(
            {
              lat: coordinates.latitude,
              lng: coordinates.longitude,
            },
            {
              lat: concert.coords[0],
              lng: concert.coords[1],
            },
          )
        : Infinity;

      if (distance <= NEAR_DISTANCE) {
        near.push({ show, concert, distance });
      } else {
        far.push({ show, concert, distance });
      }
    }
  }

  near.sort((a, b) => a.distance - b.distance);

  far.sort(
    (a, b) =>
      getDateTime(a.concert.date).toMillis() -
      getDateTime(b.concert.date).toMillis(),
  );

  const all = near.concat(far);

  return {
    head: all.slice(0, 5),
    tail: all.slice(5, all.length),
  } as const;
};
