import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

declare global {
  interface Window {
    Cookiebot: {
      renew: () => void;
    };
  }
}

const Container = styled.div`
  padding: 16px 20px;
  background: var(--set-page-card-background-color);
  font-size: 10px;
  text-align: center;

  a {
    color: #7a7a7a;
  }

  &[data-cropped='true'] {
    width: 720px;
    max-width: 100%;
    margin: 0 auto;
  }
`;

interface FooterProps {
  crop?: boolean;
}

export const Footer = ({ crop }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <Container data-cropped={crop}>
      <Trans i18nKey="copyright">
        This page was created by{' '}
        <a target="_blank" href="https://set.bio" rel="noreferrer">
          SET.Bio
        </a>{' '}
        | All Rights Reserved |{' '}
        <Link onClick={() => window.Cookiebot?.renew()}>Cookies</Link>
      </Trans>
    </Container>
  );
};

const Link = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
