import * as v from 'valibot';
import { i18n } from '../i18n';
import { isUSAPhoneValid } from './is-usa-phone-valid';
import { isUsaZipValid } from './is-usa-zip-valid';

export const FirstNameSchema = v.pipe(
  v.string(),
  v.trim(),
  v.minLength(1, () => i18n.t('firstName.minLengthError')),
);

export const LastNameSchema = v.pipe(
  v.string(),
  v.trim(),
  v.minLength(1, () => i18n.t('lastName.minLengthError')),
);

export const EmailSchema = v.pipe(
  v.string(),
  v.trim(),
  v.minLength(1, () => i18n.t('email.minLengthError')),
  v.email(() => i18n.t('email.invalidError')),
);

export const CountrySchema = v.pipe(
  v.string(),
  v.trim(),
  v.minLength(1, () => i18n.t('country.minLengthError')),
);

export const USAPhoneSchema = v.pipe(
  v.string(),
  v.trim(),
  v.minLength(1, () => i18n.t('USAPhone.minLengthError')),
  v.check(isUSAPhoneValid, () => i18n.t('USAPhone.invalidError')),
);

export const PhoneSchema = (dialCode?: string) =>
  v.pipe(
    v.string(),
    v.trim(),
    v.minLength(1, () => i18n.t('phone.minLengthError')),
    v.check(
      (input) => (dialCode ? `+${dialCode}` !== input : true),
      () => i18n.t('phone.minLengthError'),
    ),
  );

export const OptionalPhoneSchema = v.pipe(
  v.string(),
  v.trim(),
  v.check(
    (input) => {
      if (input.charAt(0) === '+') {
        return input.length > 1;
      }

      return true;
    },
    () => i18n.t('phone.invalidError'),
  ),
);

export const USAZipCodeSchema = v.pipe(
  v.string(),
  v.trim(),
  v.minLength(1, () => i18n.t('zipCode.minLengthError')),
  v.check(isUsaZipValid, () => i18n.t('zipCode.invalidError')),
);

export const PostalCodeSchema = v.pipe(
  v.string(),
  v.trim(),
  v.minLength(1, () => i18n.t('postalCode.minLengthError')),
);

export const CountryCodeSchema = v.pipe(
  v.string(),
  v.trim(),
  v.minLength(1, () => i18n.t('country.minLengthError')),
);

export const OptinSchema = v.pipe(
  v.boolean(() => i18n.t('optin.agreeError')),
  v.value(true, () => i18n.t('optin.agreeError')),
);
