import { SetPage, SetPageMerchModule } from '@max/common/setpage';
import { Card } from '../card';
import { GridLayout } from './grid-layout';
import { ListLayout } from './list-layout';
import { SwiperLayout } from './swiper-layout';

interface MerchProps {
  page?: SetPage;
  module: SetPageMerchModule;
}

export const Merch = ({ page, module }: MerchProps) => {
  const layout = module.styles?.layout ?? 'standard-buttons';
  const columns = module.styles?.columnsLayout ?? 'three-images';

  const products = module.products.filter(
    (product) => product.selected !== false,
  );

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      {layout === 'image-links' && (
        <GridLayout module={module} merch={products} columns={columns} />
      )}
      {layout === 'card-swiper' && (
        <SwiperLayout page={page} module={module} merch={products} />
      )}
      {(layout === 'image-buttons-right' ||
        layout === 'image-buttons-left') && (
        <ListLayout module={module} merch={products} layout={layout} />
      )}
    </Card>
  );
};
