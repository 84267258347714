import styled from 'styled-components';

const Container = styled.form`
  display: flex;
  border: 1px solid var(--set-page-accent-color);
  border-radius: 999px;

  [data-bg-type='none'] & {
    border-color: var(--set-page-main-border-color);
  }

  [data-bg-type='image'] &,
  [data-bg-type='video'] & {
    border-color: var(--set-page-white);
  }
`;

const Input = styled.input`
  flex: 1;
  background: none;
  border: 0;
  padding: 10px 15px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  color: inherit;

  &::placeholder {
    color: inherit;
  }
`;

const Submit = styled.button`
  background: none;
  border: 0;
  margin: 0;
  padding: 0 15px;
  border-left: 1px solid var(--set-page-accent-color);
  font-size: 14px;
  text-transform: uppercase;
  color: inherit;

  [data-bg-type='none'] & {
    border-color: var(--set-page-main-border-color);
  }

  [data-bg-type='image'] &,
  [data-bg-type='video'] & {
    border-color: var(--set-page-white);
  }
`;

export const MailchimpForm = () => {
  return (
    <Container
      onSubmit={(event) => {
        event.preventDefault();
        alert('TODO: Mailchimp');
      }}
    >
      <Input placeholder="Email" />
      <Submit>Subscribe</Submit>
    </Container>
  );
};
