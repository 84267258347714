import { SetPageSignupFormModule } from '@max/common/setpage';
import * as Dialog from '@radix-ui/react-dialog';
import { useForm } from '@tanstack/react-form';
import { transparentize } from 'polished';
import { ReactNode, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { defaultCountries, parseCountry } from 'react-international-phone';
import styled from 'styled-components';
import {
  useAnalytics,
  useCoordinatesContext,
  useSignupFormContext,
} from '../../contexts';
import { useDialogProps } from '../../hooks';
import { SignupFormValues } from '../../types';
import {
  CountryCodeSchema,
  CountrySchema,
  EmailSchema,
  FirstNameSchema,
  LastNameSchema,
  MOBILE_SELECTOR,
  OptinSchema,
  OptionalPhoneSchema,
  PhoneSchema,
  PostalCodeSchema,
  USAPhoneSchema,
  USAZipCodeSchema,
  validate,
} from '../../utils';
import { Button } from '../button';
import {
  Checkbox,
  CountrySelect,
  Field,
  FieldError,
  InputGroup,
  Label,
  PhoneInput,
  TextInput,
} from '../form';
import { OptinLabel } from './optin-label';

export const DialogContent = styled(Dialog.Content)<{
  $backgroundUrl?: string;
}>`
  position: relative;
  width: 386px;
  border-radius: 12px;
  outline: none;
  color: var(--set-page-main-color);

  @container (${MOBILE_SELECTOR}) {
    align-self: end;
    width: 100%;
    max-height: 90%;
    overflow: auto;
    border-radius: 20px 20px 0 0;
  }

  &[data-background-type='none'] {
    background: var(--set-page-white);
  }

  &[data-background-type='theme-color-black-gradient'] {
    background: linear-gradient(
      var(--set-page-accent-background-color) 0%,
      var(--set-page-black) 60%
    );
  }

  &[data-background-type='image-overlay'] {
    background-position: center;
    background-size: cover;
    background-image: ${(p) =>
      `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.9)), url('${p.$backgroundUrl}')`};
  }
`;

const DialogClose = styled(Dialog.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  top: 14px;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
`;

const Disclaimer = styled.div`
  font-size: 14px;
`;

const Image = styled.img`
  [data-background-type='image-overlay'] &,
  &[data-image-type='none'] {
    display: none !important;
  }

  &[data-image-type='circular'] {
    display: block;
    width: 114px;
    height: 114px;
    margin: 50px auto -20px;
    object-fit: cover;
    border-radius: 114px;
    border: 1px solid var(--set-page-white);
  }

  &[data-image-type='wide'] {
    width: 100%;
    height: 180px;
    margin-bottom: -20px;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 50px 40px;
`;

const Title = styled(Dialog.Title)`
  margin-bottom: 14px;
  font-size: 22px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
`;

const DialogButton = styled(Button)<{ $color?: string; $background?: string }>`
  &[data-color='true'] {
    --set-page-accent-color: ${(p) => p.$color};
    --set-page-accent-animation: none;
    --set-page-accent-background-size: none;
    --set-page-accent-background-color: ${(p) => p.$background};
    --set-page-accent-background-color-50: ${(p) =>
      p.$background && transparentize(0.5, p.$background)};
    --set-page-accent-background-image: none;
  }
`;

const getCountry = (countryCode?: string) => {
  const data = defaultCountries.find(
    (c) => parseCountry(c).iso2.toLowerCase() === countryCode?.toLowerCase(),
  );

  return data ? parseCountry(data) : undefined;
};

interface SignupFormContentProps {
  dialogClose?: ReactNode;
  module: SetPageSignupFormModule;
  onClose?: () => void;
}

export const SignupFormContent = forwardRef<
  HTMLDivElement,
  SignupFormContentProps
>(({ dialogClose, module, onClose }, ref) => {
  const signup = useSignupFormContext();
  const dialog = useDialogProps();
  const coords = useCoordinatesContext();
  const { track } = useAnalytics();
  const { t } = useTranslation();

  const defaultCountry = useMemo(
    () => getCountry(coords.countryCode),
    [coords.countryCode],
  );

  const form = useForm<SignupFormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone:
        module.phoneValidation === 'required'
          ? `+${defaultCountry?.dialCode ?? '1'}`
          : '',
      countryCode: defaultCountry?.iso2
        ? defaultCountry.iso2
        : module.phoneValidation === 'required'
          ? 'us'
          : '',
      zip: '',
      optins: {},
    },
    onSubmit: async ({ value }) => {
      track?.({ type: 'submit' });

      await signup.onSubmit?.({
        values: {
          ...value,
          optins: Object.fromEntries(
            (module.optins ?? []).map((optin) => [
              optin.id,
              value.optins[optin.id] ?? false,
            ]),
          ),
        },
        close: onClose,
      });
    },
  });

  const countryCode = form.useStore((state) => state.values.countryCode);

  return (
    <DialogContent
      ref={ref}
      data-set-page-mode={module.backgroundType === 'none' ? 'light' : 'dark'}
      data-background-type={module.backgroundType}
      $backgroundUrl={module.imageUrl}
      onOpenAutoFocus={(e) => e.preventDefault()}
      {...dialog.contentProps}
    >
      <Dialog.Description style={{ display: 'none' }} />
      {dialogClose ?? (
        <DialogClose>
          <FaTimes
            size={18}
            color={module.backgroundType === 'none' ? 'black' : 'white'}
          />
        </DialogClose>
      )}
      {module.imageUrl && (
        <Image
          data-image-type={module.imageType}
          src={module.imageUrl}
          data-aos="set-page-fade-right"
        />
      )}
      <Form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await form.handleSubmit();
        }}
      >
        {module.headerText && <Title>{module.headerText}</Title>}
        <form.Field
          name="firstName"
          validators={{
            onChange: (e) =>
              e.fieldApi.state.meta.isTouched &&
              validate(e.value, FirstNameSchema),
          }}
          children={(firstName) => (
            <form.Field
              name="lastName"
              validators={{
                onChange: (e) =>
                  e.fieldApi.state.meta.isTouched &&
                  validate(e.value, LastNameSchema),
              }}
              children={(lastName) => (
                <Field>
                  <Label htmlFor={firstName.name}>{t('fullName.label')}</Label>
                  <InputGroup>
                    <TextInput
                      id={firstName.name}
                      name={firstName.name}
                      value={firstName.state.value}
                      placeholder={t('firstName.placeholder')}
                      isInvalid={!!firstName.state.meta.errors.length}
                      onBlur={firstName.handleBlur}
                      onChange={(e) => firstName.handleChange(e.target.value)}
                    />
                    <TextInput
                      id={lastName.name}
                      name={lastName.name}
                      value={lastName.state.value}
                      placeholder={t('lastName.placeholder')}
                      isInvalid={!!lastName.state.meta.errors.length}
                      onBlur={lastName.handleBlur}
                      onChange={(e) => lastName.handleChange(e.target.value)}
                    />
                  </InputGroup>
                  {firstName.state.meta.errors && (
                    <FieldError>{firstName.state.meta.errors}</FieldError>
                  )}
                  {lastName.state.meta.errors && (
                    <FieldError>{lastName.state.meta.errors}</FieldError>
                  )}
                </Field>
              )}
            />
          )}
        />
        <form.Field
          name="email"
          validators={{
            onChange: (e) =>
              e.fieldApi.state.meta.isTouched && validate(e.value, EmailSchema),
          }}
          children={(field) => (
            <Field>
              <Label htmlFor={field.name}>{t('email.label')}</Label>
              <TextInput
                id={field.name}
                name={field.name}
                value={field.state.value}
                placeholder={t('email.placeholder')}
                isInvalid={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors && (
                <FieldError>{field.state.meta.errors}</FieldError>
              )}
            </Field>
          )}
        />
        {module.phoneValidation === 'required' ? (
          <form.Field
            name="phone"
            validators={{
              onChangeListenTo: ['countryCode'],
              onChange: (e) => {
                if (e.fieldApi.state.meta.isTouched) {
                  const code = e.fieldApi.form.getFieldValue('countryCode');
                  const country = getCountry(code);

                  return validate(
                    e.value,
                    code === 'us'
                      ? USAPhoneSchema
                      : PhoneSchema(country?.dialCode),
                  );
                }
              },
            }}
            children={(phoneField) => (
              <form.Field
                name="countryCode"
                validators={{
                  onChange: (e) =>
                    e.fieldApi.state.meta.isTouched &&
                    validate(e.value, CountryCodeSchema),
                }}
                children={(countryCodeField) => (
                  <Field>
                    <Label htmlFor={phoneField.name}>{t('phone.label')}</Label>
                    <PhoneInput
                      id={phoneField.name}
                      name={phoneField.name}
                      value={phoneField.state.value}
                      placeholder={t('phone.placeholder')}
                      isInvalid={!!phoneField.state.meta.errors.length}
                      onBlur={phoneField.handleBlur}
                      onChange={(phone, country) => {
                        phoneField.handleChange(phone);
                        countryCodeField.handleChange(country.iso2);
                      }}
                    />
                    {countryCodeField.state.meta.errors && (
                      <FieldError>
                        {countryCodeField.state.meta.errors}
                      </FieldError>
                    )}
                    {phoneField.state.meta.errors && (
                      <FieldError>{phoneField.state.meta.errors}</FieldError>
                    )}
                  </Field>
                )}
              />
            )}
          />
        ) : (
          <>
            <form.Field
              name="countryCode"
              validators={{
                onChange: (e) =>
                  e.fieldApi.state.meta.isTouched &&
                  validate(e.value, CountrySchema),
              }}
              children={(field) => (
                <Field>
                  <Label htmlFor={field.name}>{t('country.label')}</Label>
                  <CountrySelect
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    placeholder={t('country.placeholder')}
                    isInvalid={!!field.state.meta.errors.length}
                    onBlur={field.handleBlur}
                    onChange={(v) => field.handleChange(v)}
                  />
                  {field.state.meta.errors && (
                    <FieldError>{field.state.meta.errors}</FieldError>
                  )}
                </Field>
              )}
            />
            {module.phoneValidation === 'optional' && (
              <form.Field
                name="phone"
                validators={{
                  onChange: (e) =>
                    e.fieldApi.state.meta.isTouched &&
                    validate(e.value, OptionalPhoneSchema),
                }}
                children={(field) => (
                  <Field>
                    <Label htmlFor={field.name}>{t('phone.label')}</Label>
                    <TextInput
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      placeholder={t('phone.placeholder')}
                      isInvalid={!!field.state.meta.errors.length}
                      onBlur={field.handleBlur}
                      onChange={(e) =>
                        field.handleChange(
                          e.target.value
                            .replace(/[^\d+]+/g, '')
                            .replace(/(.)\++/g, '$1'),
                        )
                      }
                    />
                    {field.state.meta.errors && (
                      <FieldError>{field.state.meta.errors}</FieldError>
                    )}
                  </Field>
                )}
              />
            )}
          </>
        )}
        <form.Field
          name="zip"
          validators={{
            onChangeListenTo: ['countryCode'],
            onChange: (e) =>
              e.fieldApi.state.meta.isTouched &&
              validate(
                e.value,
                e.fieldApi.form.getFieldValue('countryCode') === 'us'
                  ? USAZipCodeSchema
                  : PostalCodeSchema,
              ),
          }}
          children={(field) => (
            <Field>
              <Label htmlFor={field.name}>
                {countryCode === 'us'
                  ? t('zipCode.label')
                  : t('postalCode.label')}
              </Label>
              <TextInput
                id={field.name}
                name={field.name}
                value={field.state.value}
                placeholder={
                  countryCode === 'us'
                    ? t('zipCode.placeholder')
                    : t('postalCode.placeholder')
                }
                isInvalid={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors && (
                <FieldError>{field.state.meta.errors}</FieldError>
              )}
            </Field>
          )}
        />
        {module.optins?.map((optin, index) => (
          <form.Field
            key={index}
            name={`optins.${optin.id}`}
            validators={{
              onChange: (e) =>
                optin?.required && validate(e.value, OptinSchema),
            }}
            children={(field) => (
              <Field>
                <Checkbox
                  id={field.name}
                  name={field.name}
                  checked={field.state.value}
                  label={<OptinLabel optin={optin} />}
                  onBlur={field.handleBlur}
                  onCheckedChange={(v) => field.handleChange(v === true)}
                />
                {field.state.meta.errors && (
                  <FieldError>{field.state.meta.errors}</FieldError>
                )}
              </Field>
            )}
          />
        ))}
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <DialogButton
              data-color={!!module.buttonBackgroundColor}
              type="submit"
              disabled={!canSubmit}
              $background={module.buttonBackgroundColor}
              $color={module.buttonTextColor}
            >
              {isSubmitting ? 'Loading...' : module.buttonText}
            </DialogButton>
          )}
        />
        {module.disclaimers?.map((disclaimer, key) => (
          <Disclaimer
            key={key}
            dangerouslySetInnerHTML={{ __html: disclaimer }}
          />
        ))}
      </Form>
    </DialogContent>
  );
});
