import { SetPageImageGalleryModule } from '@max/common/setpage';
import { Card } from '../card';
import { CardSwiperLayout } from './card-swiper-layout';
import { CarouselLayout } from './carousel-layout';
import { ImageLinksLayout } from './image-links-layout';
import { MasonryGridLayout } from './masonry-grid-layout';

interface ImageGalleryProps {
  module: SetPageImageGalleryModule;
}

export const ImageGallery = ({ module }: ImageGalleryProps) => {
  const layout = module.styles?.layout ?? 'card-swiper';
  const columns = module.styles?.columnsLayout ?? 'three-images';

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      {layout === 'card-swiper' && <CardSwiperLayout images={module.images} />}
      {layout === 'image-links' && (
        <ImageLinksLayout images={module.images} columns={columns} />
      )}
      {layout === 'grid' && <MasonryGridLayout images={module.images} />}
      {layout === 'carousel' && <CarouselLayout images={module.images} />}
    </Card>
  );
};
