import { assetUrlBuilder } from '@max/assets';
import { PropsWithChildren, createContext, useContext } from 'react';

export interface AssetsContext {
  assetUrl: ReturnType<typeof assetUrlBuilder>;
}

const Context = createContext<AssetsContext>({
  assetUrl: () => {
    throw new Error('AssetsContext is not configured');
  },
});

export const AssetsProvider = ({
  children,
  ...props
}: PropsWithChildren<{ base: string }>) => (
  <Context.Provider value={{ assetUrl: assetUrlBuilder(props) }}>
    {children}
  </Context.Provider>
);

export const useAssetsContext = () => useContext(Context);
