import { Concert } from '@max/common/creator';
import { PropsWithChildren, createContext, useContext } from 'react';

export interface ConcertsContext {
  concerts?: Concert[];
}

const Context = createContext<ConcertsContext>({});

export const ConcertsProvider = ({
  children,
  ...props
}: PropsWithChildren<ConcertsContext>) => (
  <Context.Provider value={props}>{children}</Context.Provider>
);

export const useConcertsContext = () => useContext(Context);
