import { PropsWithChildren, createContext, useContext } from 'react';

export interface HostContext {
  portalContainer?: HTMLElement | null;
  isPhonePreview?: boolean;
  onTakeoverOpenChange?: (open: boolean) => void;
}

const Context = createContext<HostContext>({});

export const HostProvider = ({
  children,
  ...props
}: PropsWithChildren<HostContext>) => (
  <Context.Provider value={props}>{children}</Context.Provider>
);

export const useHostContext = () => useContext(Context);
