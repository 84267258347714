import { GenericSchema, safeParse, safeParseAsync } from 'valibot';

export const validate = (value: unknown, schema: GenericSchema) => {
  const result = safeParse(schema, value, { abortEarly: true });
  if (result.success) return;
  return result.issues[0].message;
};

export const validateAsync = async (value: unknown, schema: GenericSchema) => {
  const result = await safeParseAsync(schema, value, { abortEarly: true });
  if (result.success) return;
  return result.issues[0].message;
};
