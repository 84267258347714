import { SetPageEmbedModule, SetPageEmbedPlatform } from '@max/common/setpage';
import styled from 'styled-components';
import { Card } from '../card';
import { CodeEmbed } from '../code-embed';

const DOMAINS: Record<SetPageEmbedPlatform, string[]> = {
  instagram: [],
  tiktok: [],
  facebook: ['facebook.com', 'www.facebook.com'],
  twitter: [],
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  [data-set-page-theme='springer'] &,
  [data-set-page-theme='tropic-colour'] & {
    background-color: var(--set-page-card-background-color);
    padding: 20px;
    border-radius: 20px;
  }
`;

interface SetPageEmbedProps {
  module: SetPageEmbedModule;
}

export const SocialEmbed = ({ module }: SetPageEmbedProps) => {
  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      <Container data-aos="set-page-fade-right">
        <CodeEmbed code={module.code} domains={DOMAINS[module.platform]} />
      </Container>
    </Card>
  );
};
