import { useLocation } from '@tanstack/react-router';

const PRE_PROD_HOSTNAME = 'set-page.pages.dev';

export const useArtistGroupId = () => {
  const location = useLocation();
  const search = location.search as Record<string, unknown>;

  // Force to use injected artist group id on production
  if (
    import.meta.env.MODE === 'production' &&
    window.location.hostname !== PRE_PROD_HOSTNAME
  ) {
    return window.ARTIST_GROUP_ID;
  }

  // On stage use injected id if present
  if (window.ARTIST_GROUP_ID) {
    return window.ARTIST_GROUP_ID;
  }

  // Use search param on dev or preview apps
  return search.artistGroupId as string;
};

declare global {
  interface Window {
    // Variable injected by a cloudflare worker
    ARTIST_GROUP_ID?: string;
  }
}
