import { SetPageMedia } from '@max/common/setpage';
import * as Dialog from '@radix-ui/react-dialog';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { useDialogProps } from '../../hooks';
import { ThemePage } from '../../theme';
import { MOBILE_SELECTOR } from '../../utils';
import { Button } from '../button';
import { DialogOverlay } from '../dialog';
import { GridLayout } from './grid-layout';

const DialogButton = styled.div`
  display: grid;
  margin-top: 20px;
`;

const DialogContent = styled(Dialog.Content)`
  position: relative;
  width: 600px;
  border-radius: 12px;
  outline: none;
  color: var(--set-page-main-color);
  background-color: var(--set-page-main-background-color);
  padding: 33px 36px;
  margin: 30px 0;

  @container (${MOBILE_SELECTOR}) {
    align-self: end;
    width: 100%;
    max-height: 90%;
    overflow: auto;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    margin: 0;
  }
`;

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @container (${MOBILE_SELECTOR}) {
    margin-bottom: 16px;
  }
`;

const DialogTitle = styled(Dialog.Title)`
  font-weight: 600;
  font-size: 26px;

  @container (${MOBILE_SELECTOR}) {
    font-weight: 500;
    font-size: 20px;
  }
`;

const DialogClose = styled(Dialog.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  color: var(--set-page-main-color);
`;

interface AllVideosProps {
  videos: SetPageMedia[];
}

export const AllVideos = ({ videos }: AllVideosProps) => {
  const dialog = useDialogProps();

  return (
    <Dialog.Root {...dialog.rootProps}>
      <DialogButton>
        <Dialog.Trigger asChild={true}>
          <Button data-aos="set-page-fade-up">View All</Button>
        </Dialog.Trigger>
      </DialogButton>
      <Dialog.Portal {...dialog.portalProps}>
        <ThemePage asChild>
          <DialogOverlay {...dialog.overlayProps}>
            <DialogContent {...dialog.contentProps}>
              <DialogHeader>
                <DialogTitle>Viewing All Videos</DialogTitle>
                <DialogClose>
                  <FaTimes size={18} />
                </DialogClose>
              </DialogHeader>
              <GridLayout videos={videos} />
            </DialogContent>
          </DialogOverlay>
        </ThemePage>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
