import { SetPageTheme } from '@max/common/setpage';

const FONT_MAPPINGS: Record<SetPageTheme['font']['family'], string> = {
  none: 'none',
  'balsamiq-sans': 'Balsamiq Sans',
  'bree-serif': 'Bree Serif',
  lato: 'Lato',
  lobster: 'Lobster',
  oswald: 'Oswald',
  pacifico: 'Pacifico',
  'playfair-display': 'Playfair Display',
  poppins: 'Poppins',
  roboto: 'Roboto',
  'shadows-into-light': 'Shadows Into Light',
};

export const getThemeFontFamily = (theme: SetPageTheme) => {
  if (theme.font.family === 'none') {
    return FONT_MAPPINGS.poppins;
  }

  return FONT_MAPPINGS[theme.font.family] ?? theme.font.family;
};
