import { PropsWithChildren, Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { i18n } from '../i18n';

interface LocalizationProviderProps {
  globalI18n?: typeof i18n;
}

export const LocalizationProvider = ({
  globalI18n,
  children,
}: PropsWithChildren<LocalizationProviderProps>) => {
  useEffect(() => {
    if (i18n.isInitialized) {
      return;
    }

    i18n.init({
      lng: globalI18n?.language,
    });
  }, [globalI18n]);

  useEffect(() => {
    const handler = (lng: string) => i18n.changeLanguage(lng);
    globalI18n?.on('languageChanged', handler);
    return () => globalI18n?.off('languageChanged', handler);
  }, [globalI18n]);

  return (
    <Suspense>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </Suspense>
  );
};
