import { SetPageBandsInTownModule } from '@max/common/setpage';
import styled from 'styled-components';
import { Card } from '../card';
import { CodeEmbed } from '../code-embed';

const Container = styled.div`
  div.bit-nav-bar-container,
  a.bit-top-track-button {
    display: none;
  }

  div.bit-event {
    border-top: none;
    border-radius: 10px;
    padding: 16px !important;
    background: var(--set-page-card-background-color);
    color: var(--set-page-main-color);
    font-family: var(--set-page-main-font-family);
  }

  div.bit-location {
    font-size: 12px;
  }

  div.bit-upcoming-events {
    border-bottom: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 !important;
  }

  a.bit-button.bit-offers {
    border: none;
    border-radius: 10px;
    font-family: var(--set-page-main-font-family);
    color: var(--set-page-accent-color);
    animation: var(--set-page-accent-animation);
    background-size: var(--set-page-accent-background-size);
    background-color: var(--set-page-accent-background-color);
    background-image: var(--set-page-accent-background-image);
  }

  .bit-widget .bit-rsvp {
    color: var(--set-page-main-color);
    border-radius: 10px;
    border: 1px solid var(--set-page-accent-background-color);
  }

  .bit-widget .bit-venue {
    color: var(--set-page-main-color);
  }

  .bit-widget .bit-lineUp {
    color: var(--set-page-main-color);
  }

  .bit-widget .bit-upcoming-events-show-all-button,
  .bit-past-events-show-all-button {
    color: var(--set-page-main-color);
    border-radius: 10px;
    border: 1px solid var(--set-page-accent-background-color);
  }

  .bit-widget .bit-clickable {
    color: var(--set-page-main-color);
  }
`;

interface BandsInTownProps {
  module: SetPageBandsInTownModule;
}

export const BandsInTown = ({ module }: BandsInTownProps) => {
  return (
    <Card
      title={module.title}
      align={module.styles?.heading.align}
      data-aos="set-page-fade-right"
    >
      <Container>
        <CodeEmbed code={module.code} />
      </Container>
    </Card>
  );
};
