import { Concert } from '@max/common/creator';
import { SetPageShow } from '@max/common/setpage';
import { FaMapMarkerAlt } from 'react-icons/fa';
import styled from 'styled-components';
import BandsintownLogo from '../../assets/svg/bandsintown.svg?react';
import SeatedLogo from '../../assets/svg/seated.svg?react';
import { useAnalytics } from '../../contexts';
import { getDateTime, MOBILE_SELECTOR } from '../../utils';
import { Button } from '../button';
import { NEAR_DISTANCE } from './constants';

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  gap: 15px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  & > a {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 30px;
    width: 140px;
    white-space: nowrap;
  }
  @container (${MOBILE_SELECTOR}) {
    & > a {
      width: 100%;
    }
  }
`;

const Heading = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;

  @container (${MOBILE_SELECTOR}) {
    font-size: 16px;
    line-height: 1.2;
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 18px;

  @container (${MOBILE_SELECTOR}) {
    font-size: 12px;
  }
`;

const ListItem = styled.div`
  display: flex;
  width: 100%;
  padding: 15px;
  border-radius: 12px;
  gap: 15px;
  position: relative;

  [data-set-page-mode='light'] & {
    border: 1px solid #bac2c6;
    color: #1b0076;
    background-color: white;

    ${Subtitle} {
      color: #666666;
    }
  }

  [data-set-page-mode='dark'] & {
    border: 1px solid #52595c;
    color: #ffffff;
    background-color: #1b1b1c;
  }

  @container (${MOBILE_SELECTOR}) {
    flex-direction: column;
  }
`;

const Calendar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border: 1px solid #e8e5f1;
  border-radius: 6px;
  height: 87px;
  min-width: 87px;
  padding-bottom: 7px;
  ${Heading} {
    color: #1b0076 !important;
    font-size: 16px;
  }
`;

const Day = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  border-radius: 6px 6px 0 0;
  width: 100%;
  padding: 5px;
  font-weight: 700;
  color: var(--set-page-accent-color);
  animation: var(--set-page-accent-animation);
  background-size: var(--set-page-accent-background-size);
  background-color: var(--set-page-accent-background-color);
  background-image: var(--set-page-accent-background-image);
`;

const NearYou = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: -11px;
  right: 20px;
  padding: 4px 10px;
  border-radius: 50px;

  font-size: 10px;
  text-transform: uppercase;

  color: var(--set-page-accent-color);
  animation: var(--set-page-accent-animation);
  background-size: var(--set-page-accent-background-size);
  background-color: var(--set-page-accent-background-color);
  background-image: var(--set-page-accent-background-image);
`;

const Sources = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 2px;

  svg {
    height: 20px;
    width: 20px;
  }

  @container (${MOBILE_SELECTOR}) {
    margin-top: 0px;

    svg {
      height: 18px;
      width: 18px;
    }
  }
`;

const SeatedLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 22px;
  width: 22px;

  [data-set-page-mode='light'] & {
    color: white;
    background-color: black;
  }

  [data-set-page-mode='dark'] & {
    color: black;
    background-color: white;
  }

  svg {
    padding: 4px;
  }

  @container (${MOBILE_SELECTOR}) {
    height: 20px;
    width: 20px;

    svg {
      padding: 3px;
    }
  }
`;

interface ShowCardProps {
  show: SetPageShow;
  concert: Concert;
  distance: number;
  displayShowTime?: boolean;
}

export const ShowCard = ({
  show,
  concert,
  distance,
  displayShowTime,
}: ShowCardProps) => {
  const { track } = useAnalytics();

  const importSourceIsBandsintown =
    concert.sources?.bandsintown?.removed === false;
  const importSourceIsSeated = concert.sources?.seated?.removed === false;
  const hasImportSource = importSourceIsBandsintown || importSourceIsSeated;

  let concertZoneDT = getDateTime(concert.date);
  // timeZone might always exist, but it is optional in the type so verify before setting
  if (concert.timeZone) {
    concertZoneDT = concertZoneDT.setZone(concert.timeZone);
  }

  return (
    <ListItem key={show.id} data-aos="set-page-fade-right">
      {distance <= NEAR_DISTANCE && (
        <NearYou>
          <FaMapMarkerAlt /> Near You
        </NearYou>
      )}
      <DetailsWrapper>
        <Calendar>
          <Day>{concertZoneDT.weekdayLong}</Day>
          <Heading>{concertZoneDT.monthShort}</Heading>
          <Heading>{concertZoneDT.day}</Heading>
        </Calendar>
        <Details>
          <Heading>{concert.venue}</Heading>
          <Subtitle>
            {concert.city}, {concert.state}
          </Subtitle>
          {displayShowTime && (
            <Subtitle>{concertZoneDT.toFormat('t ZZZZ')}</Subtitle>
          )}
          {hasImportSource && (
            <Sources>
              {importSourceIsBandsintown && <BandsintownLogo />}
              {importSourceIsSeated && (
                <SeatedLogoContainer>
                  <SeatedLogo />
                </SeatedLogoContainer>
              )}
            </Sources>
          )}
        </Details>
      </DetailsWrapper>
      {!!show.links?.length && (
        <ButtonWrapper>
          {show.links?.map((link, key) => (
            <Button
              key={key}
              as="a"
              href={link.url}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => track?.({ type: 'click', id: link.id })}
            >
              {link.text}
            </Button>
          ))}
        </ButtonWrapper>
      )}
    </ListItem>
  );
};
