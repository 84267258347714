import { SetPageVideoGalleryModule } from '@max/common/setpage';
import { Card } from '../card';
import { AllVideos } from './all-videos';
import { CarouselLayout } from './carousel-layout';
import { GridLayout } from './grid-layout';
import { MasonryLayout } from './masonry-layout';
import { SwiperLayout } from './swiper-layout';

interface VideoGalleryProps {
  module: SetPageVideoGalleryModule;
}

export const VideoGallery = ({ module }: VideoGalleryProps) => {
  const layout = module.styles?.layout ?? 'grid';
  const selected = module.videos.filter((video) => video.selected ?? true);
  const videos = selected.slice(0, 6);

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      {layout === 'grid' && <GridLayout videos={videos} />}
      {layout === 'swiper' && <SwiperLayout videos={videos} />}
      {layout === 'masonry' && <MasonryLayout videos={videos} />}
      {layout === 'carousel' && <CarouselLayout videos={videos} />}
      {selected.length > 6 && <AllVideos videos={selected} />}
    </Card>
  );
};
