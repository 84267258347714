import { SetPageVideoModule } from '@max/common/setpage';
import styled from 'styled-components';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { Card } from '../card';
import { Video } from '../video';

const Container = styled.div`
  aspect-ratio: 16 / 9;
`;

interface SingleVideoProps {
  module: SetPageVideoModule;
}

export const SingleVideo = ({ module }: SingleVideoProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      <Container onClick={() => track?.({ type: 'click', id: module.id })}>
        <Video
          url={module.media.url}
          title={module.media.title}
          thumbnail={
            module.media.background
              ? assetUrl({ url: module.media.background })
              : undefined
          }
        />
      </Container>
    </Card>
  );
};
