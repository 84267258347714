import { ComponentProps, ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import { InputGroup } from './input-group';

const Container = styled.div`
  display: flex;
  height: 44px;
  background: var(--set-page-white);
  border: 1px solid #cccccc;
  border-radius: 6px;

  &:focus-within {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
  }

  &[data-invalid='true'] {
    z-index: 1;
    border-color: #e45c52;
    outline: 1px solid #e45c52;
    outline-offset: -2px;
  }

  ${InputGroup} & {
    min-width: 0;
    flex: 1;
    border-radius: 0;
    margin-left: -1px;

    &:focus-within {
      z-index: 2;
    }

    &:first-child {
      margin-left: 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
`;

const Input = styled.input`
  flex: 1;
  min-width: 0;
  font-size: 13px;
  font-family: var(--set-page-base-font-family);
  color: var(--set-page-black);
  border: 0;
  outline: none;
  background: none;
  padding: 0 13px;

  ::placeholder {
    font-size: 12px;
    color: #999999;
  }
`;

export interface TextInputProps extends ComponentProps<'input'> {
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
  isInvalid?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ leftAddon, rightAddon, isInvalid, ...props }, ref) => {
    return (
      <Container data-invalid={isInvalid}>
        {leftAddon}
        <Input ref={ref} {...props} />
        {rightAddon}
      </Container>
    );
  },
);
