import {
  SetPagePreSaveReleaseModule,
  SetPageRelease,
} from '@max/common/setpage';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import AppleLogo from '../../assets/svg/Apple.svg?react';
import SpotifyLogo from '../../assets/svg/Spotify.svg?react';
import CheckIcon from '../../assets/svg/check-rounded.svg?react';
import { useArtistContext } from '../../contexts';
import { usePreSaveContext } from '../../contexts/presaves';
import { Title } from '../card';

const Grid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  container-type: inline-size;

  [role='dialog'] & {
    height: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;

  [role='dialog'] & {
    height: inherit;
  }
`;

interface BackgroundImageProps {
  $url: string;
}

const BackgroundImage = styled.div<BackgroundImageProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${({ $url }) => `url("${$url}") lightgray 50% / cover no-repeat`};
  z-index: -1;

  [data-full-width='false'] &,
  [data-is-phone-preview='true'] & {
    border-radius: 20px;
    overflow: hidden;
  }
`;

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  z-index: -1;

  [data-color-mode='light'] & {
    background-color: rgba(255, 255, 255, 0.75);
  }

  [data-color-mode='dark'] & {
    background-color: rgba(0, 0, 0, 0.25);
  }

  [data-full-width='false'] &,
  [data-is-phone-preview='true'] & {
    border-radius: 20px;
    overflow: hidden;
  }
`;

interface ContentProps {
  fullWidth?: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 40px 30px;
  z-index: 1;
  position: relative;

  [role='dialog'] & {
    height: inherit;
  }

  [data-color-mode='light'] & {
    color: black;
  }

  [data-color-mode='dark'] & {
    color: white;
  }

  @container (max-width: 600px) {
    flex-direction: column;
    padding: 30px 20px;
    gap: 20px;
  }
`;

interface CoverProps {
  $url: string;
}

const Cover = styled.div<CoverProps>`
  width: 250px;
  aspect-ratio: 1;
  flex-shrink: 0;
  background: ${({ $url }) => `url("${$url}") lightgray 50% / cover no-repeat`};

  [data-color-mode='light'] & {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  }

  [data-color-mode='dark'] & {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.5);
  }

  @container (max-width: 600px) {
    width: 235px;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ReleaseName = styled.h2`
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 5px;

  @container (max-width: 600px) {
    font-size: 24px;
  }
`;

const ReleaseDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  max-width: 220px;
  margin-bottom: 12px;
`;

const ReleaseDate = styled.span`
  font-size: 15px;
  font-weight: 400;
`;

interface SocialButtonProps {
  disabled?: boolean;
  $loading?: boolean;
}

const SocialButton = styled.div<SocialButtonProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 85px;
  color: white;
  width: 235px;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  margin-top: 15px;

  ${({ $loading }) =>
    $loading
      ? css`
          justify-content: center;
        `
      : css`
          cursor: pointer;
          padding-left: 20px;

          @container (max-width: 600px) {
            padding-left: 15px;
          }
        `}

  svg {
    width: 22px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: gray !important;
      cursor: default;
    `}

  @container (max-width: 600px) {
    width: 225px;
    height: 40px;
  }
`;

const SpotifyButton = styled(SocialButton)`
  background: #1db954;

  &:hover {
    background: #1da954;
  }

  svg {
    margin-top: -1px;
  }
`;

const AppleButton = styled(SocialButton)`
  background: #fe2c55;

  &:hover {
    background: #e5284e;
  }

  svg {
    margin-top: -4px;
  }
`;

const Countdown = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const Counter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 52px;
  border-radius: 5.844px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
`;

const CounterNumber = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`;

const CounterTitle = styled.span`
  font-size: 10px;
  font-weight: 400;
`;

interface SavedProps {
  allowFutureReleases: boolean;
}

const Saved = styled.div<SavedProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 235px;

  ${({ allowFutureReleases }) =>
    !allowFutureReleases &&
    css`
      margin: 0 30px;
    `}

  h1 {
    font-size: 26px;
    font-weight: 600;
  }

  p {
    max-width: 220px;
    margin-top: 10px;
  }

  @container (max-width: 600px) {
    & > svg {
      width: 65px;
    }

    h1 {
      font-size: 22px;
    }
  }
`;

const FutureReleases = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Loader = styled.span`
  width: 20px;
  height: 20px;
  border: 3px solid;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: auto;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const DetailLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 235px;
`;

const DetailLoader = styled(Loader)`
  width: 30px;
  height: 30px;
`;

interface InnerPreSaveReleaseProps {
  module: Omit<SetPagePreSaveReleaseModule, 'release'> & {
    release: SetPageRelease;
  };
}

export const InnerPreSaveRelease: React.FC<InnerPreSaveReleaseProps> = ({
  module,
}) => {
  const { id: artistGroupId } = useArtistContext();
  const {
    authorizeSpotify,
    authorizeAppleMusic,
    savingRelease,
    savedRelease,
    preSaves,
    preSavesLoading,
    optInAllPreSaves,
    releaseEndpoint,
  } = usePreSaveContext() || {};
  const [currentDateTime, setCurrentDateTime] = useState(DateTime.now());
  const [releaseDateTime, setReleaseDateTime] = useState<DateTime | null>(null);

  const displayCountdown = module.styles?.displayCountdown;
  const preSaveSpotify = preSaves?.find(
    (preSave) =>
      preSave.artistGroupId === artistGroupId && preSave.service === 'spotify',
  );
  const preSaveAppleMusic = preSaves?.find(
    (preSave) =>
      preSave.artistGroupId === artistGroupId && preSave.service === 'apple',
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (displayCountdown) {
      const updateCurrentDateTime = () => {
        setCurrentDateTime(DateTime.now());
        timeout = setTimeout(() => {
          updateCurrentDateTime();
        }, 1000);
      };

      updateCurrentDateTime();
    }

    return () => clearTimeout(timeout);
  }, [displayCountdown]);

  useEffect(() => {
    const getReleaseDate = async () => {
      try {
        const { data } = await fetch(
          `${releaseEndpoint ?? ''}/api/presave/release`,
          {
            method: 'POST',
            body: JSON.stringify({ id: module.release.id }),
          },
        ).then((res) => res.json());
        if (data.releaseDate) {
          setReleaseDateTime(DateTime.fromISO(data.releaseDate));
        }
      } catch (error) {
        console.error(error);
      }
    };

    getReleaseDate();
  }, [module.release.updatedAt?.toMillis()]);

  const formattedReleaseDate = releaseDateTime?.toFormat('LLL d, yyyy');
  const remainingTime = releaseDateTime?.diff(currentDateTime, [
    'days',
    'hours',
    'minutes',
    'seconds',
  ]);

  const isCheckingPreSaveOnMount =
    ((!artistGroupId || preSavesLoading) && !savingRelease && !savedRelease) ||
    releaseDateTime === null;

  const isLoadingSpotify =
    savingRelease === 'spotify' || savedRelease === 'spotify';
  const isLoadingAppleMusic =
    savingRelease === 'apple_music' || savedRelease === 'apple_music';

  return (
    <Grid>
      <Container
        data-full-width={module.styles?.layout === 'full_width'}
        data-color-mode={module.release.colorMode}
      >
        {module.title && (
          <Title
            title={module.title}
            align={module.styles?.heading.align || 'center'}
          />
        )}
        <Content>
          <BackgroundImage $url={module.release.image} />
          <Overlay />
          <Cover $url={module.release.image} />
          {isCheckingPreSaveOnMount ? (
            <DetailLoaderContainer>
              <DetailLoader />
            </DetailLoaderContainer>
          ) : preSaves?.length && savingRelease === null ? (
            <Saved allowFutureReleases={module.release.allowFutureReleases}>
              <CheckIcon />
              <h1>Pre-Saved!</h1>
              {module.release.allowFutureReleases &&
                (preSaveSpotify?.allReleases === false ||
                  preSaveAppleMusic?.allReleases === false) && (
                  <FutureReleases>
                    <p>Click below to pre-save all of our upcoming music!</p>
                    {preSaveSpotify?.allReleases === false && (
                      <SpotifyButton
                        onClick={() =>
                          optInAllPreSaves?.(module.release.id, preSaveSpotify)
                        }
                      >
                        <SpotifyLogo />
                        Get All Pre-Saves
                      </SpotifyButton>
                    )}
                    {preSaveAppleMusic?.allReleases === false && (
                      <AppleButton
                        onClick={() =>
                          optInAllPreSaves?.(
                            module.release.id,
                            preSaveAppleMusic,
                          )
                        }
                      >
                        <AppleLogo />
                        Get All Pre-Saves
                      </AppleButton>
                    )}
                  </FutureReleases>
                )}
            </Saved>
          ) : (
            <Detail>
              <ReleaseName>{module.release.title}</ReleaseName>
              {module.release.description && (
                <ReleaseDescription>
                  {module.release.description}
                </ReleaseDescription>
              )}
              {releaseDateTime && (
                <ReleaseDate>
                  {releaseDateTime.endOf('day') < DateTime.now().endOf('day')
                    ? 'Released'
                    : 'Releases'}{' '}
                  on {formattedReleaseDate}
                </ReleaseDate>
              )}
              {module.release.preSaveOn.includes('spotify') && (
                <SpotifyButton
                  disabled={!module.release.id}
                  $loading={isLoadingSpotify}
                  onClick={() =>
                    !savingRelease &&
                    module.release.id &&
                    authorizeSpotify?.(module.release.id, module.release.source)
                  }
                >
                  {isLoadingSpotify ? (
                    <Loader />
                  ) : (
                    <>
                      <SpotifyLogo />
                      Pre-Save On Spotify
                    </>
                  )}
                </SpotifyButton>
              )}
              {module.release.preSaveOn.includes('apple_music') && (
                <AppleButton
                  disabled={!module.release.id}
                  $loading={isLoadingAppleMusic}
                  onClick={() =>
                    !savingRelease &&
                    module.release.id &&
                    authorizeAppleMusic?.(
                      module.release.id,
                      module.release.source,
                    )
                  }
                >
                  {isLoadingAppleMusic ? (
                    <Loader />
                  ) : (
                    <>
                      <AppleLogo />
                      Pre-Save On Apple
                    </>
                  )}
                </AppleButton>
              )}
              {displayCountdown &&
                releaseDateTime &&
                releaseDateTime.toMillis() > Date.now() &&
                remainingTime && (
                  <Countdown>
                    <Counter>
                      <CounterNumber>
                        {Math.floor(remainingTime.days)}
                      </CounterNumber>
                      <CounterTitle>Days</CounterTitle>
                    </Counter>
                    <Counter>
                      <CounterNumber>
                        {Math.floor(remainingTime.hours)}
                      </CounterNumber>
                      <CounterTitle>hours</CounterTitle>
                    </Counter>
                    <Counter>
                      <CounterNumber>
                        {Math.floor(remainingTime.minutes)}
                      </CounterNumber>
                      <CounterTitle>Minutes</CounterTitle>
                    </Counter>
                    <Counter>
                      <CounterNumber>
                        {Math.floor(remainingTime.seconds)}
                      </CounterNumber>
                      <CounterTitle>Seconds</CounterTitle>
                    </Counter>
                  </Countdown>
                )}
            </Detail>
          )}
        </Content>
      </Container>
    </Grid>
  );
};

interface PreSaveReleaseProps {
  module: SetPagePreSaveReleaseModule;
}

export const PreSaveRelease = ({ module }: PreSaveReleaseProps) => {
  if (!module.release) {
    return null;
  }

  return (
    <InnerPreSaveRelease
      module={{
        ...module,
        release: module.release,
      }}
    />
  );
};
