import { SetPageCTAModule } from '@max/common/setpage';
import { readableColor } from 'polished';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useAnalytics } from '../../contexts';
import { CTA } from '../cta';

const Container = styled.div<{ $color?: string }>`
  padding: 100px 20px;
  position: relative;
  overflow: hidden;

  [data-set-page-theme='rabun'] & {
    border-radius: 20px;
  }

  &[data-bg-type='color'] {
    color: var(--set-page-accent-color);
    animation: var(--set-page-accent-animation);
    background-size: var(--set-page-accent-background-size);
    background-color: var(--set-page-accent-background-color);
    background-image: var(--set-page-accent-background-image);
  }

  &[data-bg-type='image'],
  &[data-bg-type='video'] {
    color: var(--set-page-white);
  }

  [data-set-page-theme='tropic-colour'] &[data-bg-type='image'],
  [data-set-page-theme='tropic-colour'] &[data-bg-type='video'] {
    &::before {
      content: '';
      height: 50%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        var(--set-page-main-background-color) 10%,
        transparent
      );
      z-index: 1;
    }

    &::after {
      background: linear-gradient(
        0deg,
        var(--set-page-main-background-color) 10%,
        transparent
      );
      bottom: 0;
      content: '';
      height: 50%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  z-index: 1;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

const Content = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
`;

const Cover = styled.div`
  position: absolute;
  inset: 0;

  [data-set-page-theme='rabun'] &:after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, #00000050 0%, #00000050 100%);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

interface CallToActionProps {
  module: SetPageCTAModule;
}

export const CallToAction = ({ module }: CallToActionProps) => {
  const { track } = useAnalytics();
  const override =
    (module.background?.type === 'color' && !module.background.color) ||
    module.defaultStyles;

  return (
    <Container
      data-aos="set-page-fade-right"
      data-bg-type={module.background?.type}
      style={
        module.background?.type === 'color' && module.background.color
          ? {
              color: readableColor(module.background.color),
              animation: 'none',
              background: module.background.color,
            }
          : {}
      }
    >
      <Body>
        {module.title && <Title>{module.title}</Title>}
        {module.content && <Content>{module.content}</Content>}
        {module.cta && (
          <Action onClick={() => track?.({ type: 'click', id: module.id })}>
            <CTA cta={module.cta} override={override} />
          </Action>
        )}
      </Body>
      {module.background?.type === 'image' && module.background.url && (
        <Cover>
          <Image src={module.background.url} />
        </Cover>
      )}
      {module.background?.type === 'video' && module.background.url && (
        <Cover>
          <ReactPlayer
            url={module.background.url}
            width="100%"
            height="100%"
            playing={true}
            muted={true}
            controls={false}
          />
        </Cover>
      )}
    </Container>
  );
};
