import * as Dialog from '@radix-ui/react-dialog';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { useDialogProps } from '../../hooks';
import { ThemePage } from '../../theme';
import { DialogOverlay } from '../dialog';

const Preview = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  border: 0;
  border-radius: 20px;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
`;

const PreviewImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.2s ease-in-out;

  ${Preview}:hover & {
    transform: scale(1.05);
  }
`;

const DialogContent = styled(Dialog.Content)`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  overflow: hidden;
  max-width: 90%;
`;

const DialogClose = styled(Dialog.Close)`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding: 0 20px;
`;

const FullImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

interface ImageProps {
  url: string;
  title: string;
}

export const Image = ({ url, title }: ImageProps) => {
  const dialog = useDialogProps();

  return (
    <Dialog.Root {...dialog.rootProps}>
      <Dialog.Trigger asChild>
        <Preview>
          <PreviewImg src={url} alt={title} />
        </Preview>
      </Dialog.Trigger>
      <Dialog.Portal {...dialog.portalProps}>
        <ThemePage asChild>
          <DialogOverlay {...dialog.overlayProps}>
            <DialogContent {...dialog.contentProps}>
              <DialogHeader>
                <DialogClose>
                  <FaTimes />
                </DialogClose>
              </DialogHeader>
              <FullImg src={url} alt={title} />
            </DialogContent>
          </DialogOverlay>
        </ThemePage>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
