import {
  SetPageMusicLinkAlbum,
  SetPageMusicLinkSong,
} from '@max/common/setpage';
import { useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MOBILE_SELECTOR } from '../../utils';
import { LineClamp } from '../../utils/text';
import { SwiperModal } from './swiper-modal';

const SwiperWrapper = styled.div`
  overflow: hidden;
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;

  div.swiper-button-prev {
    color: #fff;
    height: 100%;
    top: 5%;
    left: 0;
    width: 10%;
    justify-content: flex-start;
  }
  div.swiper-button-prev::after {
    font-weight: 900;
    margin-left: calc(20px - 0.5vw);
  }
  div.swiper-button-next {
    color: #fff;
    top: 5%;
    right: 0;
    height: 100%;
    width: 10%;
    justify-content: flex-end;
  }
  div.swiper-button-next::after {
    font-weight: 900;
    margin-right: calc(20px - 0.5vw);
  }
`;
const ContentWrapper = styled.div<{ $src: string; $gradient?: string }>`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.022),
      rgba(0, 0, 0, 0.782)
    ),
    url(${({ $src }) => $src});
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
`;
const Text = styled(LineClamp)`
  font-size: 22px;
  position: relative;
  width: 100%;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;

  @container (${MOBILE_SELECTOR}) {
    font-size: 18px;
  }
`;
const Description = styled.div`
  font-size: 16px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  @container (${MOBILE_SELECTOR}) {
    font-size: 12px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
const IconContainer = styled.div`
  align-items: center;
  border: 1px solid #fff;
  border-radius: 100%;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
  flex-shrink: 0;
  margin-left: auto;
  margin-bottom: 5px;
`;

interface SwiperLayoutProps {
  items: (SetPageMusicLinkAlbum | SetPageMusicLinkSong)[];
  defaultImage?: string;
}

export const SwiperLayout = ({ items, defaultImage }: SwiperLayoutProps) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);

  return (
    <SwiperWrapper data-aos="set-page-fade-right">
      <Swiper
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={items.length === 1 ? 1 : 1.3}
        navigation={true}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>
            <ContentWrapper
              $src={item.image ?? defaultImage}
              onClick={() => {
                setOpen(true);
                setIndex(index);
              }}
            >
              <Content>
                <Text $lines={2}>{item.name}</Text>
                <Description>Listen Now</Description>
              </Content>
              <IconContainer>
                <FaAngleRight size={12} />
              </IconContainer>
            </ContentWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
      <SwiperModal open={open} setOpen={setOpen} items={items} index={index} />
    </SwiperWrapper>
  );
};
