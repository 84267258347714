import { SetPageButtonLink } from '@max/common/setpage';
import { readableColor, transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { MOBILE_SELECTOR } from '../../utils';
import { Button } from '../button';

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledButton = styled(Button)<{ $bgColor?: string }>`
  ${({ $bgColor }) =>
    $bgColor &&
    css`
      --set-page-accent-color: ${readableColor($bgColor)};
      --set-page-accent-background-color: ${$bgColor};
      --set-page-accent-background-color-50: ${transparentize(0.5, $bgColor)};
      --set-page-accent-background-image: none;
      --set-page-accent-animation: none;
      --set-page-accent-background-size: none;
    `}
`;
const ButtonWrapper = styled.a<{ $bgColor: string; $reverse: boolean }>`
  color: var(--set-page-accent-color);
  animation: var(--set-page-accent-animation);
  background-size: var(--set-page-accent-background-size);
  background-color: var(--set-page-accent-background-color);
  background-image: var(--set-page-accent-background-image);
  display: flex;
  text-decoration: none;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
  overflow: hidden;
  position: relative;
  ${({ $reverse }) =>
    $reverse &&
    css`
      flex-direction: row-reverse;
      & > img {
        rotate: -20deg;
        transform: translate(5px, 5px);
      }
    `}
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 5px;
  flex-grow: 1;
`;
const Image = styled.img`
  object-fit: cover;
  display: block;
  width: 70px;
  aspect-ratio: 1;
  rotate: 20deg;
  scale: 1.2;
  transform: translate(-5px, 5px);
  border: 3px solid #ffffff;
`;
const Text = styled.div`
  font-size: 18px;
  font-weight: 600;

  @container (${MOBILE_SELECTOR}) {
    font-size: 16px;
  }
`;
const Description = styled.div`
  font-size: 16px;
  @container (${MOBILE_SELECTOR}) {
    font-size: 12px;
  }
`;

interface ButtonLinkItemProps {
  link: SetPageButtonLink;
  layout: string;
  defaultImage?: string;
}

export const ButtonLinkItem = ({
  link,
  layout,
  defaultImage,
}: ButtonLinkItemProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return layout === 'standard-buttons' ? (
    <StyledButton
      data-aos="set-page-fade-right"
      forwardedAs="a"
      $bgColor={link.color || ''}
      href={link.url}
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => track?.({ type: 'click', id: link.id })}
    >
      <Text>{link.text}</Text>
    </StyledButton>
  ) : (
    <ButtonWrapper
      data-aos="set-page-fade-right"
      $reverse={layout === 'image-buttons-left'}
      $bgColor={link.color || ''}
      href={link.url}
      rel="noopener noreferrer"
      target="_blank"
      style={{
        background: link.color,
        color: link.color && readableColor(link.color),
      }}
      onClick={() => track?.({ type: 'click', id: link.id })}
    >
      <Content>
        <Text>{link.text}</Text>
        <Description>{link.description}</Description>
      </Content>
      {(link.photo || defaultImage) && (
        <Image src={assetUrl({ url: link.photo || defaultImage || '' })} />
      )}
    </ButtonWrapper>
  );
};
