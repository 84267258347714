import { createInstance } from 'i18next';
import translations from './translations.json';

export const i18n = createInstance({
  debug: import.meta.env.DEV,

  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },

  resources: translations,
});
