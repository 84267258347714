import { Coordinates } from '@max/set-page-ui';
import { useGeolocation } from '@uidotdev/usehooks';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

export const useCoordinates = (): Coordinates | undefined => {
  const geolocation = useGeolocation();

  const iplocation = useQuery<Coordinates>(
    'coordinates',
    () => fetch('/geo').then((r) => r.json()),
    { enabled: !geolocation.loading && geolocation.latitude == null },
  );

  return useMemo(() => {
    if (geolocation.loading) {
      return undefined;
    }

    if (geolocation.latitude && geolocation.longitude) {
      return {
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
      };
    }

    return iplocation.data;
  }, [
    geolocation.loading,
    geolocation.latitude,
    geolocation.longitude,
    iplocation.data,
  ]);
};
