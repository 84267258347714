import * as Dialog from '@radix-ui/react-dialog';
import { FaPlayCircle, FaTimes } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useDialogProps } from '../../hooks';
import { ThemePage } from '../../theme';
import { MOBILE_SELECTOR } from '../../utils';
import { LineClamp } from '../../utils/text';
import { DialogOverlay } from '../dialog';

const Preview = styled.button`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  border: 0;
  border-radius: 20px;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
`;

const Icon = styled(FaPlayCircle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--set-page-accent-background-color);
`;

const Overlay = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 25%,
    rgba(0, 0, 0, 0.5) 75%
  );

  @container (${MOBILE_SELECTOR}) {
    padding: 10px 15px;
  }
`;

const Description = styled(LineClamp)`
  color: white;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  font-family: var(--set-page-main-font-family);

  @container (${MOBILE_SELECTOR}) {
    font-size: 18px;
  }
`;

const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s linear;

  ${Preview}:hover & {
    transform: scale(1.03);
  }
`;

const DialogContent = styled(Dialog.Content)`
  width: 80%;
  max-width: 1200px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  overflow: hidden;

  @container (${MOBILE_SELECTOR}) {
    width: 90%;
  }
`;

const DialogClose = styled(Dialog.Close)`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  padding: 0 20px;
`;

interface VideoProps {
  url: string;
  title?: string;
  thumbnail?: string;
}

export const Video = ({ url, title, thumbnail }: VideoProps) => {
  const dialog = useDialogProps();

  return (
    <Dialog.Root {...dialog.rootProps}>
      <Dialog.Trigger asChild>
        <Preview data-aos="set-page-fade-right">
          <Cover src={thumbnail} alt={title} />
          <Overlay>
            <Description $lines={2} as="div">
              {title}
            </Description>
          </Overlay>
          <Icon size={50} fill="white" />
        </Preview>
      </Dialog.Trigger>
      <Dialog.Portal {...dialog.portalProps}>
        <ThemePage asChild>
          <DialogOverlay {...dialog.overlayProps}>
            <DialogContent {...dialog.contentProps}>
              <DialogHeader>
                <DialogClose>
                  <FaTimes />
                </DialogClose>
              </DialogHeader>
              <div style={{ aspectRatio: '16/9' }}>
                <ReactPlayer
                  url={url}
                  playing={true}
                  width="100%"
                  height="100%"
                />
              </div>
            </DialogContent>
          </DialogOverlay>
        </ThemePage>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
