import { SetPageModule } from '@max/common/setpage';
import { ScriptHTMLAttributes } from 'react';

export const EMBED_SCRIPTS = {
  bands_in_town: {
    src: 'https://widget.bandsintown.com/main.min.js',
    async: true,
  },
  instagram: {
    src: 'https://www.instagram.com/embed.js',
    async: true,
  },
  tiktok: {
    src: 'https://www.tiktok.com/embed.js',
    async: true,
  },
  twitter: {
    src: 'https://platform.twitter.com/widgets.js',
    async: true,
  },
} as const;

export const getEmbedScripts = (modules?: SetPageModule[]) => {
  const scripts: ScriptHTMLAttributes<HTMLScriptElement>[] = [];

  if (modules?.some((module) => module.type === 'seated_events')) {
    // Do nothing, we are rendering with custom iframe to support multi seated events on same page
  }

  if (modules?.some((module) => module.type === 'bands_in_town')) {
    scripts.push(EMBED_SCRIPTS.bands_in_town);
  }

  if (
    modules?.some(
      (module) => module.type === 'embed' && module.platform === 'instagram',
    )
  ) {
    scripts.push(EMBED_SCRIPTS.instagram);
  }

  if (
    modules?.some(
      (module) => module.type === 'embed' && module.platform === 'tiktok',
    )
  ) {
    scripts.push(EMBED_SCRIPTS.tiktok);
  }

  if (
    modules?.some(
      (module) => module.type === 'embed' && module.platform === 'facebook',
    )
  ) {
    // Do nothing, facebook uses an iframe
  }

  if (
    modules?.some(
      (module) => module.type === 'embed' && module.platform === 'twitter',
    )
  ) {
    scripts.push(EMBED_SCRIPTS.twitter);
  }

  return scripts;
};
