import { SetPageStreamingModule } from '@max/common/setpage';
import { FaAngleRight } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAnalytics } from '../../contexts';
import { MOBILE_SELECTOR } from '../../utils';
import { Button } from '../button';
import { gradients, streamingIconsDark, streamingIconsLight } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  overflow: hidden;
  padding-top: 15px;

  [data-set-page-theme='rabun'] & {
    padding: 15px;
  }

  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;

  div.swiper-button-prev {
    color: #fff;
    height: 100%;
    top: 5%;
    left: 0;
    width: 10%;
    justify-content: flex-start;
  }
  div.swiper-button-prev::after {
    font-weight: 900;
    margin-left: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
  div.swiper-button-next {
    color: #fff;
    top: 5%;
    right: 0;
    height: 100%;
    width: 10%;
    justify-content: flex-end;
  }
  div.swiper-button-next::after {
    font-weight: 900;
    margin-right: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
`;
const ContentWrapper = styled.a<{ $src: string; $gradient?: string }>`
  background-image: linear-gradient(${({ $gradient }) => $gradient}),
    url(${({ $src }) => $src});
  width: 100%;
  height: 100%;
  min-height: 280px;
  background-size: cover;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
  text-decoration: none;
`;
const ContentSlide = styled.div`
  aspect-ratio: 1;
  border-radius: 15px;
  background-color: grey;
`;
const Title = styled.div`
  font-size: 22px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  // justify according to theme settings
  justify-content: center;
  & > span {
    position: relative;
    color: var(--set-page-main-color);
    background-color: var(--set-page-main-background-color);
    width: fit-content;
    padding: 0 10px;
    z-index: 5;
  }

  @container (${MOBILE_SELECTOR}) {
    font-size: 18px;
  }
`;
const HR = styled.div`
  position: absolute;
  width: 100%;
  height: 0.5px;
  background: var(--set-page-main-color);
  opacity: 0.5;
`;
const Content = styled.div`
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;

  @container (${MOBILE_SELECTOR}) {
    font-size: 12px;
  }
`;
const Logo = styled.img`
  object-fit: cover;
  width: 40%;
  max-width: 150px;
`;

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const ListItem = styled.a`
  display: flex;
  padding: 20px 25px;
  background-color: var(--set-page-card-background-color);
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
`;

const IconContainer = styled.div`
  align-items: center;
  border: 1px solid #fff;
  border-radius: 100%;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
  flex-shrink: 0;
`;

export type StreamingLinksProps = {
  module: SetPageStreamingModule;
};

export const StreamingLinks = ({ module }: StreamingLinksProps) => {
  const { mode, id } = useTheme();
  const { track } = useAnalytics();

  return (
    <Wrapper>
      <Title>
        <span>{module.title}</span>
        {id === 'springer' && <HR />}
      </Title>
      {id === 'rabun' ? (
        <ListWrapper>
          {module.links?.map((link, i) => {
            return (
              <ListItem
                data-aos="set-page-fade-right"
                key={i}
                href={link.url}
                rel="noopener noreferrer"
                target="_blank"
                onClick={() => track?.({ type: 'click', id: link.id })}
              >
                <Logo
                  src={
                    mode === 'light'
                      ? streamingIconsLight[link.service]
                      : streamingIconsDark[link.service]
                  }
                  alt=""
                />
                {link.title && <Button as="div">{link.title}</Button>}
              </ListItem>
            );
          })}
        </ListWrapper>
      ) : (
        <Swiper
          modules={[Navigation]}
          spaceBetween={15}
          slidesPerView={1.35}
          navigation={true}
          style={{ width: '100%' }}
        >
          {module.links?.map((link, i) => {
            return (
              <SwiperSlide key={i}>
                <ContentSlide>
                  <ContentWrapper
                    $src={link.image || ''}
                    $gradient={gradients[i]}
                    href={link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Logo src={streamingIconsDark[link.service]} alt="" />
                    <Content>
                      {link.service === 'Youtube_Videos'
                        ? 'Watch on '
                        : 'Listen on '}{' '}
                      {link.service.replace('_', ' ')}
                      <IconContainer>
                        <FaAngleRight size={12} />
                      </IconContainer>
                    </Content>
                  </ContentWrapper>
                </ContentSlide>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Wrapper>
  );
};
