import { SetPageSeatedModule } from '@max/common/setpage';
import parse from 'html-react-parser';
import { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Card } from '../card';

const Container = styled.div`
  .seated-events-table {
    border: 0;
  }

  .seated-event-row {
    border: 0;
  }

  .seated-event-row:hover {
    background: none;
  }

  [data-set-page-theme='springer'] &,
  [data-set-page-theme='tropic-colour'] & {
    .seated-events-table {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .seated-event-row {
      border-radius: 8px;
      background: var(--set-page-card-background-color);
    }
  }

  .seated-follow-box {
    display: none;
  }

  .seated-follow-box + div {
    display: none;
  }

  .seated-event-link {
    border: 0 !important;
    color: var(--set-page-accent-color) !important;
    animation: var(--set-page-accent-animation) !important;
    background-size: var(--set-page-accent-background-size) !important;
    background-color: var(--set-page-accent-background-color) !important;
    background-image: var(--set-page-accent-background-image) !important;
  }
`;

interface SeatedProps {
  module: SetPageSeatedModule;
}

export const Seated = ({ module }: SeatedProps) => {
  const iframe = useRef<HTMLIFrameElement>(null);
  const [loaded, setLoaded] = useState(false);
  const [children, setChildren] = useState<ReactNode>(null);

  useEffect(() => {
    if (loaded && iframe.current?.contentDocument) {
      const subdoc = iframe.current?.contentWindow?.document;

      const copy = () => {
        if (subdoc?.body) {
          setChildren(
            parse(subdoc.body.innerHTML, {
              replace: (domNode) => {
                if (domNode.type === 'script') {
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  return <></>;
                }
              },
            }),
          );
        }

        const styles = subdoc?.querySelectorAll<HTMLLinkElement>(
          'link[rel="stylesheet"]',
        );

        styles?.forEach((style) => {
          style.id = style.href;

          if (!document.getElementById(style.id)) {
            document.head.appendChild(style);
          }
        });
      };

      copy();

      const observer = new MutationObserver(copy);

      observer.observe(iframe.current.contentDocument.body, {
        childList: true,
        subtree: true,
      });

      return () => observer.disconnect();
    }
  }, [loaded]);

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      <Container>
        <iframe
          ref={iframe}
          loading="eager"
          title={module.title}
          srcDoc={module.code}
          style={{ display: 'none' }}
          onLoad={() => setLoaded(true)}
        />
        {children}
      </Container>
    </Card>
  );
};
