import { ParsedCountry, usePhoneInput } from 'react-international-phone';
import { Except } from 'type-fest';
import { CountrySelect } from './country-select';
import { TextInput, TextInputProps } from './text-input';

export interface PhoneInputProps
  extends Except<TextInputProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string, country: ParsedCountry) => void;
}

export const PhoneInput = ({ value, onChange, ...props }: PhoneInputProps) => {
  const { inputRef, inputValue, handlePhoneValueChange, country, setCountry } =
    usePhoneInput({
      value,
      forceDialCode: true,
      onChange: ({ phone, country }) => onChange?.(phone, country),
    });

  return (
    <TextInput
      {...props}
      ref={inputRef}
      value={inputValue}
      onChange={handlePhoneValueChange}
      leftAddon={
        <CountrySelect
          value={country.iso2}
          tabIndex={-1}
          isAddon={true}
          onChange={(v) => setCountry(v, { focusOnInput: true })}
        />
      }
    />
  );
};
