import { SetPageSurveyLinkModule } from '@max/common/setpage';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import styled, { css } from 'styled-components';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { MOBILE_SELECTOR } from '../../utils';
import { Button } from '../button';
import { Card } from '../card';

const Heading = styled.div<{ $invertTextColor: boolean }>`
  color: ${({ $invertTextColor }) =>
    $invertTextColor ? '#ffffff' : 'var(--set-page-main-color)'};
  font-size: 26px;
  line-height: 36px;
  text-align: center;
  font-weight: 500;

  @container (${MOBILE_SELECTOR}) {
    font-size: 20px;
    line-height: 28px;
  }
`;
const Body = styled.div<{ $invertTextColor: boolean }>`
  color: ${({ $invertTextColor }) =>
    $invertTextColor ? '#ffffff' : 'var(--set-page-main-color)'};
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 25px;
  z-index: 5;
  & > a {
    width: 100%;
    max-width: 400px;
    & > svg {
      margin-left: 7px;
      width: 12px;
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  aspect-ratio: 1;
  img {
    width: 100%;
    object-fit: contain;
  }
`;

const StyledCard = styled(Card)<{ $src?: string }>`
  ${({ $src }) =>
    $src &&
    css`
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75), black),
        url(${$src}) !important;
      background-repeat: no-repeat;
      background-size: cover !important;
      background-position: center !important;
      border-radius: 18px;
      min-height: 300px;
      padding: 50px !important;
      border: 5px solid #1b1b1c;
      overflow: hidden;
    `}
  display: flex;
  flex-direction: row;
  gap: 30px;
  position: relative !important;
  [data-set-page-theme='rabun'] & {
    padding: 50px !important;
  }
  @container (${MOBILE_SELECTOR}) {
    flex-direction: column !important;
  }
`;

const BlurOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
`;

interface SurveyLinkProps {
  module: SetPageSurveyLinkModule;
}

export const SurveyLink = ({ module }: SurveyLinkProps) => {
  const layout = module.styles?.layout;
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <StyledCard
      $src={
        layout === 'image-background' && module.imageUrl
          ? assetUrl({ url: module.imageUrl })
          : undefined
      }
    >
      {module.imageUrl && layout === 'image-left' && (
        <ImageContainer data-aos="set-page-fade-right">
          <img src={assetUrl({ url: module.imageUrl })} alt="" />
        </ImageContainer>
      )}
      {module.imageUrl && layout === 'image-background' && <BlurOverlay />}
      <ContentWrapper>
        <Heading
          $invertTextColor={layout === 'image-background'}
          data-aos="set-page-fade-right"
        >
          {module.headerText}
        </Heading>
        {module.bodyText && (
          <Body
            data-aos="set-page-fade-right"
            $invertTextColor={layout === 'image-background'}
          >
            {module.bodyText}
          </Body>
        )}
        <Button
          data-aos="set-page-fade-up"
          as="a"
          href={module.buttonUrl}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => track?.({ type: 'click', id: module.id })}
        >
          {module.buttonText} <FaArrowUpRightFromSquare />
        </Button>
      </ContentWrapper>
    </StyledCard>
  );
};
