import { SetPageShowsModule } from '@max/common/setpage';
import * as Dialog from '@radix-ui/react-dialog';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import BandsintownLogo from '../../assets/svg/bandsintown-full.svg?react';
import SeatedLogo from '../../assets/svg/seated-full.svg?react';
import { useDialogProps } from '../../hooks';
import { ThemePage } from '../../theme';
import { MOBILE_SELECTOR } from '../../utils';
import { Button } from '../button';
import { Card } from '../card';
import { DialogOverlay } from '../dialog';
import { ShowCard } from './show-card';
import { useShows } from './use-shows';

const HeadList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`;

const DialogContent = styled(Dialog.Content)`
  position: relative;
  width: 600px;
  border-radius: 12px;
  outline: none;
  color: var(--set-page-main-color);
  background-color: var(--set-page-main-background-color);
  padding: 33px 36px;
  margin: 30px 0;

  @container (${MOBILE_SELECTOR}) {
    align-self: end;
    width: 100%;
    max-height: 90%;
    overflow: auto;
    border-radius: 20px 20px 0 0;
    padding: 20px;
    margin: 0;
  }
`;

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @container (${MOBILE_SELECTOR}) {
    margin-bottom: 16px;
  }
`;

const DialogTitle = styled(Dialog.Title)`
  font-weight: 600;
  font-size: 26px;

  @container (${MOBILE_SELECTOR}) {
    font-weight: 500;
    font-size: 20px;
  }
`;

const DialogClose = styled(Dialog.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  color: var(--set-page-main-color);
`;

const TailList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 22px;
`;

const PoweredBy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  span {
    color: #999;
    font-size: 11px;
    font-weight: 600;
    padding-top: 3px;
  }

  svg {
    height: 20px;

    [data-set-page-mode='light'] & {
      color: black;
    }

    [data-set-page-mode='dark'] & {
      color: white;
    }
  }
`;

interface ShowsProps {
  module: SetPageShowsModule;
}

export const Shows = ({ module }: ShowsProps) => {
  const dialog = useDialogProps();
  const shows = useShows(module.shows);
  const allShows = [...shows.head, ...shows.tail];
  const includesBandsintownShows = allShows.some(
    ({ concert }) => concert.sources?.bandsintown?.removed === false,
  );
  const includesSeatedShows = allShows.some(
    ({ concert }) => concert.sources?.seated?.removed === false,
  );
  const includesShowsFromSources =
    includesBandsintownShows || includesSeatedShows;

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      <HeadList>
        {shows.head.map(({ show, concert, distance }) => (
          <ShowCard
            key={show.id}
            show={show}
            concert={concert}
            distance={distance}
            displayShowTime={!module.hideShowTimes}
          />
        ))}
        {!!shows.tail.length && (
          <Dialog.Root {...dialog.rootProps}>
            <Dialog.Trigger asChild={true}>
              <Button data-aos="set-page-fade-up">View All Shows</Button>
            </Dialog.Trigger>
            <Dialog.Portal {...dialog.portalProps}>
              <ThemePage asChild>
                <DialogOverlay {...dialog.overlayProps}>
                  <DialogContent {...dialog.contentProps}>
                    <DialogHeader>
                      <DialogTitle>Upcoming Shows</DialogTitle>
                      <DialogClose>
                        <FaTimes size={18} />
                      </DialogClose>
                    </DialogHeader>
                    <TailList>
                      {allShows.map(({ show, concert, distance }) => (
                        <ShowCard
                          key={show.id}
                          show={show}
                          concert={concert}
                          distance={distance}
                          displayShowTime={!module.hideShowTimes}
                        />
                      ))}
                    </TailList>
                  </DialogContent>
                </DialogOverlay>
              </ThemePage>
            </Dialog.Portal>
          </Dialog.Root>
        )}
        {includesShowsFromSources && (
          <PoweredBy data-aos="set-page-fade-up">
            <span>POWERED BY</span>
            {includesBandsintownShows && <BandsintownLogo width={115} />}
            {includesSeatedShows && <SeatedLogo />}
          </PoweredBy>
        )}
      </HeadList>
    </Card>
  );
};
