import { SetPage, SetPageModule } from '@max/common/setpage';
import { BandsInTown } from '../bandsintown';
import { ButtonsLinks } from '../buttons-links';
import { CallToAction } from '../call-to-action';
import { Content } from '../content';
import { EventsCalendar } from '../events-calendar';
import { ImageGallery } from '../image-gallery';
import { Merch } from '../merch';
import { MusicLinks } from '../music-links';
import { PreSaveRelease } from '../pre-save-release/pre-save-release';
import { Seated } from '../seated';
import { Shows } from '../shows';
import { SignupForm } from '../signup-form';
import { SingleVideo } from '../single-video';
import { SocialEmbed } from '../social-embed';
import { SpotifyEmbed } from '../spotify-embed';
import { StreamingLinks } from '../streaming-links';
import { SurveyLink } from '../survey-link';
import { Testimonials } from '../testimonials';
import { VideoGallery } from '../video-gallery';

interface ModuleProps {
  page: SetPage;
  module: SetPageModule;
}

export const Module = ({ page, module }: ModuleProps) => {
  switch (module.type) {
    case 'video':
      return <SingleVideo module={module} />;
    case 'video_gallery':
      return <VideoGallery module={module} />;
    case 'image_gallery':
      return <ImageGallery module={module} />;
    case 'calendar':
      return <EventsCalendar module={module} />;
    case 'embed':
      return <SocialEmbed module={module} />;
    case 'streaming':
      return <StreamingLinks module={module} />;
    case 'testimonials':
      return <Testimonials module={module} />;
    case 'merch':
      return <Merch page={page} module={module} />;
    case 'buttons_links':
      return <ButtonsLinks module={module} defaultImage={page.profileImage} />;
    case 'cta':
      return <CallToAction module={module} />;
    case 'content':
      return <Content module={module} />;
    case 'seated_events':
      return <Seated module={module} />;
    case 'bands_in_town':
      return <BandsInTown module={module} />;
    case 'music_links':
      return <MusicLinks module={module} />;
    case 'signup_form':
      return <SignupForm module={module} />;
    case 'survey_link':
      return <SurveyLink module={module} />;
    case 'shows':
      return <Shows module={module} />;
    case 'spotify_embed':
      return <SpotifyEmbed module={module} />;
    case 'pre_save_release':
      return <PreSaveRelease module={module} />;
    default:
      console.warn('MODULE NOT FOUND: ', module.type);
      return null;
  }
};
