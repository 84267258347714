import { ArtistGroup } from '@max/common/artists';
import { PropsWithChildren, createContext, useContext } from 'react';

export interface ArtistContext {
  id?: string;
  artist?: ArtistGroup;
}

const Context = createContext<ArtistContext>({});

export const ArtistProvider = ({
  children,
  ...props
}: PropsWithChildren<ArtistContext>) => (
  <Context.Provider value={props}>{children}</Context.Provider>
);

export const useArtistContext = () => useContext(Context);
