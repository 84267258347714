import { SetPageButtonLink } from '@max/common/setpage';
import { FaAngleRight } from 'react-icons/fa';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { useAssetsContext } from '../../contexts';

const ContentWrapper = styled.a<{ $src?: string; $gradient?: string }>`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.022),
      rgba(0, 0, 0, 0.782)
    ),
    url(${({ $src }) => $src});
  width: 100%;
  aspect-ratio: 1 !important;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
  text-decoration: none;
`;
const ContentSlide = styled.div`
  border-radius: 20px;
  background-color: grey;
`;
const Text = styled.div`
  font-size: 22px;
  position: relative;
  display: flex;
  width: 100%;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
  & > span {
    position: relative;
    color: var(--set-page-main-color);
    background-color: var(--set-page-main-background-color);
    width: fit-content;
    padding: 0 10px;
    z-index: 5;
  }
`;
const Description = styled.div`
  font-size: 16px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;
const Content = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
const IconContainer = styled.div`
  align-items: center;
  border: 1px solid #fff;
  border-radius: 100%;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
  flex-shrink: 0;
  margin-left: auto;
  margin-bottom: 5px;
`;

interface ButtonLinkSwiperItemProps {
  link: SetPageButtonLink;
  defaultImage?: string;
}

export const ButtonLinkSwiperItem = ({
  link,
  defaultImage,
}: ButtonLinkSwiperItemProps) => {
  const { assetUrl } = useAssetsContext();

  return (
    <ContentSlide>
      <ContentWrapper
        $src={assetUrl({ url: link.photo || defaultImage || '' })}
        href={link.url}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Content hidden={link.hideText}>
          {link.text && <Text>{link.text}</Text>}
          {link.description && <Description>{link.description}</Description>}
        </Content>
        <IconContainer>
          <FaAngleRight size={12} />
        </IconContainer>
      </ContentWrapper>
    </ContentSlide>
  );
};
