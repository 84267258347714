import { buildApi } from '@max/common/api';
import { getAuth, getIdToken } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

export const api = buildApi({
  fetchMap: {
    'secure.getPublicKey': `${import.meta.env.VITE_SECURE_WORKER_ENDPOINT}/publicKey`,
  },
  getToken: async () => {
    const auth = getAuth();

    if (auth.currentUser) {
      return getIdToken(auth.currentUser);
    }

    console.warn(
      'An attempt is being made to call an API endpoint before we have an authenticated user',
    );

    return '';
  },
  httpsCallable: (name) => httpsCallable(getFunctions(), name),
});
