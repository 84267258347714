interface BaseAsset {
  /**
   * Base assets URL.  If none is provided the following
   * environmental variables will be checked:
   *
   *  1. REACT_APP_ASSETS_BASE
   *  2. ASSETS_BASE
   *
   * Example: https://assets.set.live
   */
  base?: string;
}

interface TransformOptions {
  /**
   * Add a fade-to-black gradient on the bottom of the image.
   */
  gradient?: boolean;

  /**
   * Add a "SET" logo to the bottom left of the image.
   */
  watermark?: boolean;
}

interface UrlAsset extends BaseAsset, TransformOptions {
  /**
   * Fully qualified URL of the asset to load
   */
  url: string;
}

interface GroupAsset extends BaseAsset, TransformOptions {
  /**
   * Get the image for the given artist group ID.
   */
  groupId: string;
}

interface EventAsset extends BaseAsset, TransformOptions {
  /**
   * Get the image for the give SETLive event ID.
   */
  eventId: string;
}

export const toUrlSafeBase64 = (str: string): string => {
  // Encode the string into standard Base64 using btoa
  const base64 = btoa(str);

  // Convert the standard Base64 to URL-safe Base64
  const urlSafeBase64 = base64
    .replace(/\+/g, "-") // Replace '+' with '-'
    .replace(/\//g, "_") // Replace '/' with '_'
    .replace(/=+$/, ""); // Remove '=' padding

  return urlSafeBase64;
};

export const fromUrlSafeBase64 = (urlSafeBase64: string): string => {
  // Convert URL-safe Base64 back to standard Base64
  const base64 = urlSafeBase64
    .replace(/-/g, "+") // Replace '-' with '+'
    .replace(/_/g, "/"); // Replace '_' with '/'

  // Add padding if necessary (Base64 strings must be divisible by 4)
  const paddedBase64 = base64.padEnd(
    base64.length + ((4 - (base64.length % 4)) % 4),
    "=",
  );

  // Decode the Base64 string using atob
  return atob(paddedBase64);
};

const isUrl = (url: string): boolean => !!url.match(/^https?:\/\//i);

const buildUrl = (url: URL, options: TransformOptions): string => {
  if (options.gradient) {
    url.searchParams.set("g", "1");
  }

  if (options.watermark) {
    url.searchParams.set("w", "1");
  }

  return url.toString();
};

export type AssetOptions = UrlAsset | GroupAsset | EventAsset;

export const assetUrlBuilder = (opts: BaseAsset = {}) => {
  function assetUrl(options: UrlAsset): string | undefined;
  function assetUrl(options: GroupAsset): string | undefined;
  function assetUrl(options: EventAsset): string | undefined;
  function assetUrl(options: AssetOptions): string | undefined {
    const base =
      opts.base ||
      options.base ||
      globalThis.process?.env?.REACT_APP_ASSETS_BASE ||
      globalThis.process?.env?.ASSETS_BASE;

    if (!base || !isUrl(base)) {
      throw new Error("Invalid assets base URL");
    }

    const url = (() => {
      try {
        if ("url" in options) {
          const encodedUrl = isUrl(options.url)
            ? toUrlSafeBase64(options.url)
            : options.url;
          return new URL(`/a/${encodedUrl}`, base);
        }

        if ("groupId" in options) {
          return new URL(`/g/${options.groupId}`, base);
        }

        if ("eventId" in options) {
          return new URL(`/e/${options.eventId}`, base);
        }
      } catch (error) {
        console.error("Error generating asset URL", error);
      }
    })();

    if (url) {
      return buildUrl(url, options);
    }

    return undefined;
  }

  return assetUrl;
};

export const assetUrl = assetUrlBuilder();
