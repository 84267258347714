import * as styled from 'styled-components';
import { MOBILE_SELECTOR } from '../utils';
import { gradient } from './keyframes';

const texture = (name: string, base = '') => `${base}/textures/${name}.jpg`;

interface ThemeGlobalProps {
  baseUrl?: string;
  animateOnScroll?: boolean;
}

export const ThemeGlobal = styled.createGlobalStyle<ThemeGlobalProps>`
  @layer gradients, animations;

  :root {
    --set-page-white: #ffffff;
    --set-page-black: #000000;
    --set-page-base-font-family: 'Poppins', sans-serif;
    --z-index-modal-overlay: 8888;
    --z-index-modal-content: 9999;
  }

  [data-set-page-mode='light'] {
    --set-page-main-color: #424954;
    --set-page-main-background-color: #eff4f4;
    --set-page-main-border-color: #333333;
    --set-page-card-background-color: var(--set-page-white);
  }

  [data-set-page-mode='dark'] {
    --set-page-main-color: var(--set-page-white);
    --set-page-main-background-color: #111111;
    --set-page-main-border-color: #e4e6e9;
    --set-page-card-background-color: #1b1b1c;
  }

  @layer gradients {
    [data-set-page-gradient='pinkpurple'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(225, 0, 255, 1);
      --set-page-accent-background-color-50: rgba(225, 0, 255, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(225, 0, 255, 1) 0%,
        rgba(127, 0, 255, 1) 100%
      );
      --set-page-accent-start-color: rgba(225, 0, 255, 1);
      --set-page-accent-end-color: rgba(127, 0, 255, 1);
    }

    [data-set-page-gradient='pinkblue'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(252, 0, 255, 1);
      --set-page-accent-background-color-50: rgba(252, 0, 255, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(252, 0, 255, 1) 0%,
        rgba(0, 219, 222, 1) 100%
      );
      --set-page-accent-start-color: rgba(252, 0, 255, 1);
      --set-page-accent-end-color: rgba(0, 219, 222, 1);
    }

    [data-set-page-gradient='blueblue'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(0, 210, 255, 1);
      --set-page-accent-background-color-50: rgba(0, 210, 255, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(0, 210, 255, 1) 0%,
        rgba(58, 123, 213, 1) 100%
      );
      --set-page-accent-start-color: rgba(0, 210, 255, 1);
      --set-page-accent-end-color: rgba(58, 123, 213, 1);
    }

    [data-set-page-gradient='mango'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(255, 226, 89, 1);
      --set-page-accent-background-color-50: rgba(255, 226, 89, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(255, 226, 89, 1) 0%,
        rgba(255, 167, 81, 1) 100%
      );
      --set-page-accent-start-color: rgba(255, 226, 89, 1);
      --set-page-accent-end-color: rgba(255, 167, 81, 1);
    }

    [data-set-page-gradient='black'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(67, 67, 67, 1);
      --set-page-accent-background-color-50: rgba(67, 67, 67, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(67, 67, 67, 1) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      --set-page-accent-start-color: rgba(67, 67, 67, 1);
      --set-page-accent-end-color: rgba(0, 0, 0, 1);
    }

    [data-set-page-gradient='greenblue'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(0, 242, 96, 1);
      --set-page-accent-background-color-50: rgba(0, 242, 96, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(0, 242, 96, 1) 0%,
        rgba(5, 117, 230, 1) 100%
      );
      --set-page-accent-start-color: rgba(0, 242, 96, 1);
      --set-page-accent-end-color: rgba(5, 117, 230, 1);
    }

    [data-set-page-gradient='darkpink'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(255, 0, 153, 1);
      --set-page-accent-background-color-50: rgba(255, 0, 153, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(255, 0, 153, 1) 0%,
        rgba(73, 50, 64, 1) 100%
      );
      --set-page-accent-start-color: rgba(255, 0, 153, 1);
      --set-page-accent-end-color: rgba(73, 50, 64, 1);
    }

    [data-set-page-gradient='peach'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(255, 153, 102, 1);
      --set-page-accent-background-color-50: rgba(255, 153, 102, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(255, 153, 102, 1) 0%,
        rgba(255, 94, 98, 1) 100%
      );
      --set-page-accent-start-color: rgba(255, 153, 102, 1);
      --set-page-accent-end-color: rgba(255, 94, 98, 1);
    }

    [data-set-page-gradient='peachpurple'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(252, 70, 107, 1);
      --set-page-accent-background-color-50: rgba(252, 70, 107, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(252, 70, 107, 1) 0%,
        rgba(63, 94, 251, 1) 100%
      );
      --set-page-accent-start-color: rgba(252, 70, 107, 1);
      --set-page-accent-end-color: rgba(63, 94, 251, 1);
    }

    [data-set-page-gradient='yellowgreen'] {
      --set-page-accent-color: var(--set-page-black);
      --set-page-accent-background-color: rgba(253, 252, 71, 1);
      --set-page-accent-background-color-50: rgba(253, 252, 71, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(253, 252, 71, 1) 0%,
        rgba(36, 254, 65, 1) 100%
      );
      --set-page-accent-start-color: rgba(253, 252, 71, 1);
      --set-page-accent-end-color: rgba(36, 254, 65, 1);
    }

    [data-set-page-gradient='orangepink'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(255, 106, 0, 1);
      --set-page-accent-background-color-50: rgba(255, 106, 0, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(255, 106, 0, 1) 0%,
        rgba(238, 9, 121, 1) 100%
      );
      --set-page-accent-start-color: rgba(255, 106, 0, 1);
      --set-page-accent-end-color: rgba(238, 9, 121, 1);
    }

    [data-set-page-gradient='huntergreen'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(56, 239, 125, 1);
      --set-page-accent-background-color-50: rgba(56, 239, 125, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(56, 239, 125, 1) 0%,
        rgba(17, 153, 142, 1) 100%
      );
      --set-page-accent-start-color: rgba(56, 239, 125, 1);
      --set-page-accent-end-color: rgba(17, 153, 142, 1);
    }

    [data-set-page-gradient='greengreen'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(155, 233, 49, 1);
      --set-page-accent-background-color-50: rgba(155, 233, 49, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(155, 233, 49, 1) 0%,
        rgba(88, 187, 68, 1) 100%
      );
      --set-page-accent-start-color: rgba(155, 233, 49, 1);
      --set-page-accent-end-color: rgba(88, 187, 68, 1);
    }

    [data-set-page-gradient='lightpeach'] {
      --set-page-accent-color: var(--set-page-black);
      --set-page-accent-background-color: rgba(255, 221, 225, 1);
      --set-page-accent-background-color-50: rgba(255, 221, 225, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(255, 221, 225, 1) 0%,
        rgba(238, 156, 167, 1) 100%
      );
      --set-page-accent-start-color: rgba(255, 221, 225, 1);
      --set-page-accent-end-color: rgba(238, 156, 167, 1);
    }

    [data-set-page-gradient='orangeorange'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: rgba(254, 140, 0, 1);
      --set-page-accent-background-color-50: rgba(254, 140, 0, 0.5);
      --set-page-accent-background-image: linear-gradient(
        120deg,
        rgba(254, 140, 0, 1) 0%,
        rgba(248, 54, 0, 1) 100%
      );
      --set-page-accent-start-color: rgba(254, 140, 0, 1);
      --set-page-accent-end-color: rgba(248, 54, 0, 1);
    }

    [data-set-page-gradient='gradient-1'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #8a06d8;
      --set-page-accent-background-color-50: #8a06d850;
      --set-page-accent-background-image: linear-gradient(
        135deg,
        #be0af5 0.06%,
        #5600bb 99.99%
      );
      --set-page-accent-start-color: #be0af5;
      --set-page-accent-end-color: #5600bb;
    }

    [data-set-page-gradient='gradient-2'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #233b9f;
      --set-page-accent-background-color-50: #233b9f50;
      --set-page-accent-background-image: linear-gradient(
        135deg,
        #1d74d7 -0.16%,
        #270067 100.29%
      );
      --set-page-accent-start-color: #1d74d7;
      --set-page-accent-end-color: #270067;
    }

    [data-set-page-gradient='gradient-3'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #940539;
      --set-page-accent-background-color-50: #94053950;
      --set-page-accent-background-image: linear-gradient(
        135deg,
        #eb0a0a -0.17%,
        #5a0056 84.03%
      );
      --set-page-accent-start-color: #eb0a0a;
      --set-page-accent-end-color: #5a0056;
    }

    [data-set-page-gradient='gradient-4'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #994501;
      --set-page-accent-background-color-50: #99450150;
      --set-page-accent-background-image: linear-gradient(
        135deg,
        #bf7f00 4.34%,
        #6d0000 100.29%
      );
      --set-page-accent-start-color: #bf7f00;
      --set-page-accent-end-color: #6d0000;
    }

    [data-set-page-gradient='gradient-5'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #16816d;
      --set-page-accent-background-color-50: #16816d50;
      --set-page-accent-background-image: linear-gradient(
        137.22deg,
        #2e870d -0.17%,
        #007bc2 97.76%
      );
      --set-page-accent-start-color: #2e870d;
      --set-page-accent-end-color: #007bc2;
    }

    [data-set-page-gradient='gradient-6'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #b1015a;
      --set-page-accent-background-color-50: #b1015a50;
      --set-page-accent-background-image: linear-gradient(
        135deg,
        #e60060 3.01%,
        #7d0053 94.74%
      );
      --set-page-accent-start-color: #e60060;
      --set-page-accent-end-color: #7d0053;
    }

    [data-set-page-gradient='neutral-1'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #000000;
      --set-page-accent-background-color-50: #00000050;
      --set-page-accent-background-image: #000000;
      --set-page-accent-start-color: #000000;
      --set-page-accent-end-color: #000000;
    }

    [data-set-page-gradient='neutral-2'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #3e4b5f;
      --set-page-accent-background-color-50: #3e4b5f50;
      --set-page-accent-background-image: #3e4b5f;
      --set-page-accent-start-color: #3e4b5f;
      --set-page-accent-end-color: #3e4b5f;
    }

    [data-set-page-gradient='neutral-3'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #655048;
      --set-page-accent-background-color-50: #65504850;
      --set-page-accent-background-image: #655048;
      --set-page-accent-start-color: #655048;
      --set-page-accent-end-color: #655048;
    }
  }

  @layer animations {
    [data-set-page-animation='animation-1'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #e73c7e;
      --set-page-accent-background-color-50: #e73c7e50;
      --set-page-accent-animation: ${gradient} 15s ease infinite;
      --set-page-accent-background-size: 400% 400%;
      --set-page-accent-background-image: linear-gradient(
        -45deg,
        #ee7752,
        #e73c7e,
        #23a6d5,
        #23d5ab
      );

      --set-page-accent-start-color: #ee7752;

      --set-page-accent-end-color: #23a6d5;
    }

    [data-set-page-animation='animation-2'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #27ba59;
      --set-page-accent-background-color-50: #27ba5950;
      --set-page-accent-animation: ${gradient} 15s ease infinite;
      --set-page-accent-background-size: 400% 400%;
      --set-page-accent-background-image: linear-gradient(
        -45deg,
        #9ce931,
        #27ba59,
        #fe5536,
        #d72d77
      );
      --set-page-accent-start-color: #9ce931;
      --set-page-accent-end-color: #fe5536;
    }

    [data-set-page-animation='animation-3'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #ff005e;
      --set-page-accent-background-color-50: #ff005e50;
      --set-page-accent-animation: ${gradient} 15s ease infinite;
      --set-page-accent-background-size: 400% 400%;
      --set-page-accent-background-image: linear-gradient(
        -45deg,
        #e000ff,
        #ff005e,
        #85ff00,
        #deff00
      );
      --set-page-accent-start-color: #e000ff;
      --set-page-accent-end-color: #85ff00;
    }

    [data-set-page-animation='animation-4'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #434343;
      --set-page-accent-background-color-50: #43434350;
      --set-page-accent-animation: ${gradient} 15s ease infinite;
      --set-page-accent-background-size: 400% 400%;
      --set-page-accent-background-image: linear-gradient(
        -45deg,
        #000000,
        #434343,
        #282828,
        #363636
      );
      --set-page-accent-start-color: #000000;
      --set-page-accent-end-color: #282828;
    }

    [data-set-page-animation='animation-5'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #18ff00;
      --set-page-accent-background-color-50: #18ff0050;
      --set-page-accent-animation: ${gradient} 15s ease infinite;
      --set-page-accent-background-size: 400% 400%;
      --set-page-accent-background-image: linear-gradient(
        -45deg,
        #ff0099,
        #18ff00,
        #fe5536,
        #b0ff00
      );
      --set-page-accent-start-color: #ff0099;
      --set-page-accent-end-color: #fe5536;
    }

    [data-set-page-animation='animated-1'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #756196;
      --set-page-accent-background-color-50: #75619650;
      --set-page-accent-animation: ${gradient} 10s ease infinite;
      --set-page-accent-background-size: 400% 400%;
      --set-page-accent-background-image: linear-gradient(
        135.58deg,
        #3179a5 8.03%,
        #b94887 93.69%
      );
      --set-page-accent-start-color: #3179a5;
      --set-page-accent-end-color: #b94887;
    }

    [data-set-page-animation='animated-2'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #a75028;
      --set-page-accent-background-color-50: #a7502850;
      --set-page-accent-animation: ${gradient} 10s ease infinite;
      --set-page-accent-background-size: 400% 400%;
      --set-page-accent-background-image: linear-gradient(
        135.58deg,
        #d4371c 8.03%,
        #488240 93.69%
      );
      --set-page-accent-start-color: #d4371c;
      --set-page-accent-end-color: #488240;
    }

    [data-set-page-animation='animated-3'] {
      --set-page-accent-color: var(--set-page-white);
      --set-page-accent-background-color: #000000;
      --set-page-accent-background-color-50: #00000050;
      --set-page-accent-animation: ${gradient} 10s ease infinite;
      --set-page-accent-background-size: 400% 400%;
      --set-page-accent-background-image: linear-gradient(
        135.58deg,
        #3b3b3b 8.03%,
        #000000 93.69%
      );
      --set-page-accent-start-color: #3b3b3b;
      --set-page-accent-end-color: #000000;
    }
  }

  /* Artifact */
  [data-set-page-texture='artifact-1'] {
    --set-page-texture-url: url(${(p) => texture('artifact-1', p.baseUrl)});
  }

  [data-set-page-texture='artifact-2'] {
    --set-page-texture-url: url(${(p) => texture('artifact-2', p.baseUrl)});
  }

  [data-set-page-texture='artifact-3'] {
    --set-page-texture-url: url(${(p) => texture('artifact-3', p.baseUrl)});
  }

  [data-set-page-texture='artifact-4'] {
    --set-page-texture-url: url(${(p) => texture('artifact-4', p.baseUrl)});
  }

  [data-set-page-texture='artifact-5'] {
    --set-page-texture-url: url(${(p) => texture('artifact-5', p.baseUrl)});
  }

  /* Flare */
  [data-set-page-texture='flare-1'] {
    --set-page-texture-url: url(${(p) => texture('flare-1', p.baseUrl)});
  }

  [data-set-page-texture='flare-2'] {
    --set-page-texture-url: url(${(p) => texture('flare-2', p.baseUrl)});
  }

  [data-set-page-texture='flare-3'] {
    --set-page-texture-url: url(${(p) => texture('flare-3', p.baseUrl)});
  }

  [data-set-page-texture='flare-4'] {
    --set-page-texture-url: url(${(p) => texture('flare-4', p.baseUrl)});
  }

  [data-set-page-texture='flare-5'] {
    --set-page-texture-url: url(${(p) => texture('flare-5', p.baseUrl)});
  }

  /* Grain */
  [data-set-page-texture='grain-1'] {
    --set-page-texture-url: url(${(p) => texture('grain-1', p.baseUrl)});
  }

  [data-set-page-texture='grain-2'] {
    --set-page-texture-url: url(${(p) => texture('grain-2', p.baseUrl)});
  }

  [data-set-page-texture='grain-3'] {
    --set-page-texture-url: url(${(p) => texture('grain-3', p.baseUrl)});
  }

  [data-set-page-texture='grain-4'] {
    --set-page-texture-url: url(${(p) => texture('grain-4', p.baseUrl)});
  }

  [data-set-page-texture='grain-5'] {
    --set-page-texture-url: url(${(p) => texture('grain-5', p.baseUrl)});
  }

  /* Paper */
  [data-set-page-texture='paper-1'] {
    --set-page-texture-url: url(${(p) => texture('paper-1', p.baseUrl)});
  }

  [data-set-page-texture='paper-2'] {
    --set-page-texture-url: url(${(p) => texture('paper-2', p.baseUrl)});
  }

  [data-set-page-texture='paper-3'] {
    --set-page-texture-url: url(${(p) => texture('paper-3', p.baseUrl)});
  }

  [data-set-page-texture='paper-4'] {
    --set-page-texture-url: url(${(p) => texture('paper-4', p.baseUrl)});
  }

  [data-set-page-texture='paper-5'] {
    --set-page-texture-url: url(${(p) => texture('paper-5', p.baseUrl)});
  }

  /* New */
  [data-set-page-mode='light'][data-set-page-texture='distressed'] {
    --set-page-texture-url: url(${(p) =>
      texture('distressed-light', p.baseUrl)});
  }

  [data-set-page-mode='dark'][data-set-page-texture='distressed'] {
    --set-page-texture-url: url(${(p) =>
      texture('distressed-dark', p.baseUrl)});
  }

  [data-set-page-mode='light'][data-set-page-texture='geometric'] {
    --set-page-texture-url: url(${(p) =>
      texture('geometric-light', p.baseUrl)});
  }

  [data-set-page-mode='dark'][data-set-page-texture='geometric'] {
    --set-page-texture-url: url(${(p) => texture('geometric-dark', p.baseUrl)});
  }

  [data-set-page-mode='light'][data-set-page-texture='lights'] {
    --set-page-texture-url: url(${(p) => texture('lights-light', p.baseUrl)});
  }

  [data-set-page-mode='dark'][data-set-page-texture='lights'] {
    --set-page-texture-url: url(${(p) => texture('lights-dark', p.baseUrl)});
  }

  [data-set-page-mode='light'][data-set-page-texture='paper'] {
    --set-page-texture-url: url(${(p) => texture('paper-light', p.baseUrl)});
  }

  [data-set-page-mode='dark'][data-set-page-texture='paper'] {
    --set-page-texture-url: url(${(p) => texture('paper-dark', p.baseUrl)});
  }

  [data-set-page-mode='light'][data-set-page-texture='water'] {
    --set-page-texture-url: url(${(p) => texture('water-light', p.baseUrl)});
  }

  [data-set-page-mode='dark'][data-set-page-texture='water'] {
    --set-page-texture-url: url(${(p) => texture('water-dark', p.baseUrl)});
  }

  [data-set-page-mode='light'][data-set-page-texture='watercolor'] {
    --set-page-texture-url: url(${(p) =>
      texture('watercolor-light', p.baseUrl)});
  }

  [data-set-page-mode='dark'][data-set-page-texture='watercolor'] {
    --set-page-texture-url: url(${(p) =>
      texture('watercolor-dark', p.baseUrl)});
  }

  [data-set-page-mode='light'][data-set-page-texture='waves'] {
    --set-page-texture-url: url(${(p) => texture('waves-light', p.baseUrl)});
  }

  [data-set-page-mode='dark'][data-set-page-texture='waves'] {
    --set-page-texture-url: url(${(p) => texture('waves-dark', p.baseUrl)});
  }

  /* Animate on scroll */
  [data-set-page-enable-aos='true'] {
    [data-aos='set-page-fade-left'] {
      opacity: 0;
      transform: translateX(30px);
      &.aos-animate {
        transform: translateX(0);
        opacity: 1;
      }
    }

    [data-aos='set-page-fade-socials'] {
      opacity: 0;
      transform: translateX(30px);
      &.aos-animate {
        transform: translateX(0);
        opacity: 1;
      }

      @container (${MOBILE_SELECTOR}) {
        transform: translateY(20px);
        &.aos-animate {
          transform: translateY(0);
        }
      }
    }

    [data-aos='set-page-fade-right'] {
      opacity: 0;
      transform: translateX(-30px);
      &.aos-animate {
        transform: translateX(0);
        opacity: 1;
      }
    }

    [data-aos='set-page-fade-down'] {
      opacity: 0;
      transform: translateY(-20px);
      &.aos-animate {
        transform: translateX(0);
        opacity: 1;
      }
    }

    [data-aos='set-page-fade-up'] {
      opacity: 0;
      transform: translateY(20px);
      &.aos-animate {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;
