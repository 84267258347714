import { SetPageSurveyLinkModule } from '@max/common/setpage';
import { forwardRef } from 'react';
import { FaCircle, FaTimesCircle } from 'react-icons/fa';
import { FaArrowUpRightFromSquare } from 'react-icons/fa6';
import { useAnalytics } from '../../contexts';
import { useDialogProps } from '../../hooks';
import {
  DialogButton,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  Image,
  MainContent,
  TextContent,
} from './dialog';

interface SurveyLinkProps {
  module: SetPageSurveyLinkModule;
}

export const SurveyLink = forwardRef<HTMLDivElement, SurveyLinkProps>(
  ({ module }, ref) => {
    const dialog = useDialogProps();
    const { track } = useAnalytics();

    return (
      <DialogContent
        ref={ref}
        data-background-type={module.backgroundType}
        $backgroundUrl={module.backgroundUrl}
        $backgroundColor={module.backgroundColor}
        {...dialog.contentProps}
      >
        <DialogClose>
          <FaCircle size={25} color="white" />
          <FaTimesCircle
            size={25}
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        </DialogClose>
        <MainContent>
          <Image src={module.imageUrl} />
          <TextContent>
            <DialogTitle>{module.headerText}</DialogTitle>
            <DialogDescription>{module.bodyText}</DialogDescription>
          </TextContent>
          <DialogButton
            data-color={!!module.buttonBackgroundColor}
            forwardedAs="a"
            href={module.buttonUrl}
            target="_blank"
            $background={module.buttonBackgroundColor}
            $color={module.buttonTextColor}
            onClick={() => track?.({ type: 'click', id: module.id })}
          >
            {module.buttonText} <FaArrowUpRightFromSquare />
          </DialogButton>
        </MainContent>
      </DialogContent>
    );
  },
);
