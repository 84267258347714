import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { ReactNode } from 'react';
import { FaCheck } from 'react-icons/fa';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 6px;
`;

const Label = styled.label`
  display: block;
  font-size: 12px;
  font-family: var(--set-page-base-font-family);
  line-height: 1.3;

  a {
    color: inherit;
  }
`;

const Root = styled(RadixCheckbox.Root)`
  display: flex;
  width: 14px;
  height: 14px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: var(--set-page-white);
  border: 1px solid #bfbfbf;
  padding: 6px;
`;

const Indicator = styled(RadixCheckbox.Indicator)`
  display: flex;
  width: 14px;
  height: 14px;
  align-items: center;
  justify-content: center;
  color: var(--set-page-black);
`;

interface CheckboxProps extends RadixCheckbox.CheckboxProps {
  label?: ReactNode;
}

export const Checkbox = ({ label, ...props }: CheckboxProps) => {
  return (
    <Container>
      <Root {...props}>
        <Indicator>
          <FaCheck size={6} />
        </Indicator>
      </Root>
      {label && <Label htmlFor={props.id}>{label}</Label>}
    </Container>
  );
};
