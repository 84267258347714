import { SetPageMerchModule, SetPageProduct } from '@max/common/setpage';
import styled from 'styled-components';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { Button } from '../button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  &[data-columns='one-image'] {
    grid-template-columns: 1fr;
  }

  &[data-columns='two-images'] {
    grid-template-columns: repeat(2, 1fr);
  }

  &[data-columns='three-images'] {
    grid-template-columns: repeat(3, 1fr);
  }

  &[data-columns='four-images'] {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const GridItem = styled.a<{ $src?: string }>`
  display: block;
  aspect-ratio: 1;
  width: 100%;
  background-image: url(${({ $src }) => $src});
  background-size: 100%;
  background-position: center;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-size: 105%;
  }
`;

const MerchButton = styled.div`
  display: flex;
  flex-direction: column;
`;

interface GridLayoutProps {
  module: SetPageMerchModule;
  merch: SetPageProduct[];
  columns: string;
}

export const GridLayout = ({ module, merch, columns }: GridLayoutProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <Container>
      <Grid data-columns={columns}>
        {merch?.map((item, index) => (
          <GridItem
            data-aos="set-page-fade-right"
            key={index}
            $src={assetUrl({ url: item.photo })}
            href={item.link}
            rel="noopener noreferrer"
            target="_blank"
            onClick={() => track?.({ type: 'click', id: item.id })}
          />
        ))}
      </Grid>
      {module.displayMerchStoreButton &&
        module.merchStoreButtonText &&
        module.merchStoreButtonUrl && (
          <MerchButton>
            <Button
              data-aos="set-page-fade-right"
              as="a"
              href={module.merchStoreButtonUrl}
              target="_blank"
              rel="noreferrer"
              onClick={() => track?.({ type: 'click', id: module.id })}
            >
              {module.merchStoreButtonText}
            </Button>
          </MerchButton>
        )}
    </Container>
  );
};
