import { SetPageSignupFormModule } from '@max/common/setpage';
import * as Dialog from '@radix-ui/react-dialog';
import { useState } from 'react';
import styled from 'styled-components';
import { useSignupFormContext } from '../../contexts';
import { useDialogProps } from '../../hooks';
import { ThemePage } from '../../theme';
import { Button } from '../button';
import { DialogOverlay } from '../dialog';
import { SignupFormContent } from './signup-form-content';

const SectionCard = styled.div<{ $backgroundUrl?: string }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  color: var(--set-page-main-color);
  border-radius: 20px;

  [data-set-page-theme='rabun'] & {
    background: var(--set-page-card-background-color);
  }

  &[data-bg-type='theme-color-black-gradient'] {
    background: linear-gradient(
      var(--set-page-accent-background-color) 0%,
      var(--set-page-black) 60%
    );
  }

  &[data-bg-type='image-overlay'] {
    background-position: center;
    background-size: cover;
    background-image: ${(p) =>
      `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.9)), url('${p.$backgroundUrl}')`};
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 300px;
  margin: 0 auto;
`;

const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
`;

const SectionBody = styled.div`
  text-align: center;
  line-height: 1.3;
`;

interface SignupFormProps {
  module: SetPageSignupFormModule;
}

export const SignupForm = ({ module }: SignupFormProps) => {
  const signup = useSignupFormContext();
  const dialog = useDialogProps();
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen} {...dialog.rootProps}>
      <SectionCard
        data-set-page-mode={
          module.backgroundType === 'none' ? undefined : 'dark'
        }
        data-bg-type={module.backgroundType}
        $backgroundUrl={module.imageUrl}
      >
        <SectionContent>
          {module.title && <SectionTitle>{module.title}</SectionTitle>}
          {module.bodyText && <SectionBody>{module.bodyText}</SectionBody>}
          {signup.hasSignup ? (
            <SectionBody>Thanks for signing up!</SectionBody>
          ) : (
            <Dialog.Trigger asChild={true}>
              <Button>{module.sectionButtonText}</Button>
            </Dialog.Trigger>
          )}
        </SectionContent>
      </SectionCard>
      <Dialog.Portal {...dialog.portalProps}>
        <ThemePage asChild>
          <DialogOverlay {...dialog.overlayProps}>
            <SignupFormContent module={module} onClose={() => setOpen(false)} />
          </DialogOverlay>
        </ThemePage>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
