import { SetPageMedia } from '@max/common/setpage';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { Image } from '../image';

const Container = styled.div`
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;
`;

interface CarouselLayoutProps {
  images: SetPageMedia[];
}

export const CarouselLayout = ({ images }: CarouselLayoutProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <Container data-aos="set-page-fade-right">
      <Swiper
        modules={[Navigation, EffectCoverflow]}
        effect="coverflow"
        coverflowEffect={{ depth: 500 }}
        spaceBetween={20}
        slidesPerView="auto"
        navigation={true}
        centeredSlides={true}
        loop={true}
      >
        {images.map((image, index) => (
          <SwiperSlide
            key={index}
            style={{ maxWidth: 280, aspectRatio: 1 }}
            onClick={() => track?.({ type: 'click', id: image.id })}
          >
            <Image
              url={assetUrl({ url: image.url }) ?? ''}
              title={image.title}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
