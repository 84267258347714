import { SetPage } from '@max/common/setpage';
import {
  AnalyticsEvent,
  AnalyticsTrackEvent,
} from '@max/common/setpage/analytics';
import { User } from 'firebase/auth';
import { DocumentSnapshot, addDoc, collection } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { firestore } from '../firebase';

interface AnalyticsProps {
  user?: User | null;
  page?: DocumentSnapshot<SetPage>;
}

export const useAnalytics = ({ user, page }: AnalyticsProps) => {
  const uid = user?.uid;
  const pageId = page?.exists() ? page.id : undefined;

  const [queue, setQueue] = useState<{
    loading: false;
    events: AnalyticsTrackEvent[];
  }>({
    loading: false,
    events: [
      {
        type: 'visit',
        source: document.referrer,
        meta: { userAgent: navigator.userAgent },
      },
    ],
  });

  const track = useCallback((event: AnalyticsTrackEvent) => {
    setQueue((p) => ({ ...p, events: [...p.events, event] }));
  }, []);

  useEffect(() => {
    if (!uid || !pageId) return;
    if (queue.events.length === 0) return;
    if (queue.loading) return;

    const event = queue.events[0];

    setQueue((prev) => ({
      loading: prev.loading,
      events: prev.events.slice(1),
    }));

    const payload: AnalyticsEvent = { ...event, uid, pageId };

    if (['staging', 'development'].includes(import.meta.env.MODE)) {
      console.log(`Event ${JSON.stringify(payload)}`);
    }

    addDoc(collection(firestore, 'set_page_analytics'), payload)
      .catch((e) => console.error(e))
      .finally(() => setQueue((p) => ({ ...p, loading: false })));
  }, [uid, pageId, queue]);

  return track;
};
