import {
  SetPageMusicLinkAlbum,
  SetPageMusicLinkService,
  SetPageMusicLinkSong,
} from '@max/common/setpage';
import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode, forwardRef } from 'react';
import { FaCircle, FaPlayCircle, FaTimesCircle } from 'react-icons/fa';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SpotifySvg from '../../assets/svg/Spotify.svg?react';
import { useAnalytics } from '../../contexts';
import { useDialogProps } from '../../hooks';
import { ICONS, Services } from './constants';

const SERVICES_ORDER: Record<SetPageMusicLinkService, number> = {
  Amazon_Music: 3,
  Apple_Music: 1,
  Deezer: 5,
  Pandora: 4,
  Spotify: 0,
  Tidal: 6,
  YouTube: 2,
};

const DialogContent = styled(Dialog.Content)`
  position: fixed;
  inset: 0;
  z-index: var(--z-index-modal-content);
  outline: none;
`;

const DialogClose = styled(Dialog.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  top: 14px;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
  z-index: 6;
  cursor: pointer;
  border-radius: 50%;
  color: black;
  transform: translateZ(1px);
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0;
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;

  div.swiper-button-prev {
    width: 5%;
    height: 100%;
    top: 10px;
    left: 0;
    text-shadow: 1px 1px 3px #0000008f;
  }

  div.swiper-button-next {
    width: 5%;
    height: 100%;
    top: 10px;
    right: 0;
    text-shadow: 1px 1px 3px #0000008f;
  }
`;

const Card = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  font-family: var(--set-page-base-font-family);

  &[data-color-mode='light'] {
    background: white;
  }

  &[data-color-mode='dark'] {
    background: black;
  }
`;

const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  object-fit: cover;
  filter: blur(20px);
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
  transform: scale(1.1);
`;

const Scrollable = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  overflow: auto;
  z-index: 1;
`;

const Cover = styled.img`
  display: block;
  width: 100%;
  max-width: 500px;
  object-fit: contain;
  mask-image: linear-gradient(black 60%, transparent);
  margin: 0 auto;
  user-select: none;
  pointer-events: none;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 450px;
  margin: -65px auto 0 auto;
  padding: 30px;
  position: relative;
  z-index: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: center;

  [data-color-mode='light'] & {
    color: black;
    text-shadow: 0px 0px 3px white;
  }

  [data-color-mode='dark'] & {
    color: white;
    text-shadow: 0px 0px 3px black;
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;

  [data-color-mode='light'] & {
    color: black;
    text-shadow: 0px 0px 3px white;
  }

  [data-color-mode='dark'] & {
    color: white;
    text-shadow: 0px 0px 3px black;
  }
`;

const Divider = styled.div`
  height: 0.5px;

  [data-color-mode='light'] & {
    background: rgba(0, 0, 0, 0.3);
  }

  [data-color-mode='dark'] & {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const Link = styled.a`
  display: flex;
  gap: 10px;
  height: 50px;
  align-items: center;
  padding: 0 12px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;

  &[data-size='small'] {
    height: 40px;
    font-weight: 600;
    font-size: 12px;
  }

  [data-color-mode='light'] & {
    background: black;
    color: white;

    svg {
      color: white;
      fill: white;
    }
  }

  [data-color-mode='dark'] & {
    background: white;
    color: black;

    svg {
      color: black;
      fill: black;
    }
  }
`;

interface MusicLinksGalleryProps {
  items: (SetPageMusicLinkAlbum | SetPageMusicLinkSong)[];
  defaultImage?: string;
  index: number;
  className?: string;
  dialogClose?: ReactNode;
}

export const MusicLinksGallery = forwardRef<
  HTMLDivElement,
  MusicLinksGalleryProps
>(({ items, index, defaultImage, className, dialogClose }, ref) => {
  const dialog = useDialogProps();
  const { track } = useAnalytics();

  return (
    <DialogContent ref={ref} {...dialog.contentProps}>
      <Container className={className}>
        <Swiper
          data-aos="set-page-fade-right"
          modules={[Navigation]}
          spaceBetween={10}
          slidesPerView={items?.length === 1 ? 1 : 1.15}
          breakpoints={{
            550: {
              spaceBetween: 20,
            },
          }}
          navigation={true}
          initialSlide={index}
          centeredSlides={true}
          style={{ width: '100%', height: '100%' }}
        >
          {items.map((item, index) => (
            <SwiperSlide
              key={index}
              style={{ padding: items?.length === 1 ? '0 20px' : undefined }}
            >
              <Card data-color-mode={item.colorMode}>
                {dialogClose ?? (
                  <DialogClose>
                    <FaCircle size={25} color="white" />
                    <FaTimesCircle
                      size={25}
                      style={{ position: 'absolute', top: 0, left: 0 }}
                    />
                  </DialogClose>
                )}
                <Scrollable>
                  <Cover src={item.spotifyImage ?? defaultImage} alt="" />
                  <Main>
                    <Header>
                      <Title>{item.name}</Title>
                      <Subtitle>{item.artist}</Subtitle>
                      {item.links.Spotify && (
                        <Link
                          data-size="small"
                          href={item.links.Spotify}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{ marginTop: 15 }}
                          onClick={() =>
                            track?.({ type: 'click', id: `${item.id}:Spotify` })
                          }
                        >
                          <SpotifySvg
                            fill={item.colorMode === 'dark' ? 'black' : 'white'}
                            width={20}
                          />
                          Save on Spotify
                        </Link>
                      )}
                    </Header>
                    <Divider />
                    <Body>
                      <Subtitle>Choose a Service</Subtitle>
                      {Object.entries(item.links)
                        .sort(
                          ([aService], [bService]) =>
                            SERVICES_ORDER[
                              aService as SetPageMusicLinkService
                            ] -
                            SERVICES_ORDER[bService as SetPageMusicLinkService],
                        )
                        .map(([service, link], index) => {
                          if (!link) {
                            return null;
                          }

                          const ServiceIcon = ICONS[service as Services];

                          return (
                            <Link
                              key={index}
                              href={link}
                              rel="noopener noreferrer"
                              target="_blank"
                              onClick={() =>
                                track?.({
                                  type: 'click',
                                  id: `${item.id}:${service}`,
                                })
                              }
                            >
                              <ServiceIcon />
                              {service.replace('_', ' ')}
                              <FaPlayCircle
                                size={17}
                                style={{ marginLeft: 'auto', marginRight: 5 }}
                              />
                            </Link>
                          );
                        })}
                    </Body>
                  </Main>
                </Scrollable>
                <Background src={item.spotifyImage ?? defaultImage} />
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </DialogContent>
  );
});
