import styled from 'styled-components';

export const LineClamp = styled.span<{ $lines?: number }>`
  ${({ $lines }) => `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @supports (-webkit-line-clamp: ${$lines ?? 1}) {
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${$lines ?? 1};
    -webkit-box-orient: vertical;
  }
`}
`;
