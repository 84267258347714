import { SetPageSignupFormOptIn } from '@max/common/setpage';
import { Trans, useTranslation } from 'react-i18next';
import { useArtistContext } from '../../contexts';

interface OptinLabelProps {
  optin: SetPageSignupFormOptIn;
}

export const OptinLabel = ({ optin }: OptinLabelProps) => {
  const { t } = useTranslation();
  const { artist } = useArtistContext();
  const artistName = artist?.name ?? '';

  if (optin.id === 'privacy') {
    return (
      <Trans i18nKey="optins.privacy">
        I agree to share the above information with {{ artistName }} and
        SET.Live.{' '}
        <a
          href="https://www.max.live/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </Trans>
    );
  }

  if (optin.id === 'sms') {
    const artistNamePossessive = `${artistName}${artistName.endsWith('s') ? "'" : "'s"}`;
    return t('optins.sms', { artistName, artistNamePossessive });
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: optin.content ?? '',
      }}
    />
  );
};
