import { ArtistGroup } from '@max/common/artists';
import { ReleasePresave } from '@max/common/artists/presave';
import { Concert } from '@max/common/creator';
import { SecurePayload, encryptJsonObject } from '@max/common/crypto';
import { Firestore } from '@max/common/firestore';
import { SetPage, SetPageModule } from '@max/common/setpage';
import {
  AnalyticsProvider,
  ArtistProvider,
  AssetsProvider,
  ConcertsProvider,
  CoordinatesProvider,
  LocalizationProvider,
  Page,
  PreSaveProvider,
  SignupFormProvider,
  SignupFormSubmit,
  ThemeGlobal,
  ThemePage,
  ThemeProvider,
  getEmbedScripts,
  initGlobalI18n,
} from '@max/set-page-ui';
import { createFileRoute } from '@tanstack/react-router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { signInAnonymously } from 'firebase/auth';
import {
  Bytes,
  CollectionReference,
  DocumentReference,
  Query,
  and,
  collection,
  doc,
  or,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  useCollection,
  useDocument,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import { Helmet } from 'react-helmet-async';
import { getPublicKey } from '../crypto';
import { auth, firestore } from '../firebase';
import { useAnalytics } from '../hooks/use-analytics';
import { useArtistGroupId } from '../hooks/use-artist-group-id';
import { useCoordinates } from '../hooks/use-coordinates';

AOS.init({
  duration: 800,
  once: true,
});

const globalI18n = initGlobalI18n();

const Component = () => {
  const { country } = Route.useLoaderData();
  const [user] = useAuthState(auth);
  const [now] = useState<Date>(DateTime.utc().toJSDate());
  const coordinates = useCoordinates();
  const artistGroupId = useArtistGroupId();

  const [artist] = useDocumentDataOnce(
    artistGroupId
      ? (doc(
          firestore,
          'artist_groups',
          artistGroupId,
        ) as DocumentReference<ArtistGroup>)
      : null,
  );

  const [page] = useDocument(
    artistGroupId
      ? (doc(
          firestore,
          'set_page',
          artistGroupId,
        ) as DocumentReference<SetPage>)
      : null,
  );

  const [modules] = useCollection(
    page
      ? (query(
          collection(page.ref, 'modules'),
          or(where('publishAt', '<=', now), where('publishAt', '==', null)),
        ) as CollectionReference<SetPageModule>)
      : null,
  );

  const [concerts] = useCollection(
    page
      ? (query(
          collection(firestore, 'artist_groups', page.id, 'concerts'),
          where('date', '>', now),
          where('deletedAt', '==', null),
        ) as CollectionReference<Concert>)
      : null,
  );

  const documents = modules?.docs.map((doc) => doc.data()) ?? [];
  const sorted = [...documents].sort((a, b) => a.index - b.index);

  const [signup] = useDocument(
    page && user
      ? (doc(page.ref, 'signups', user.uid) as DocumentReference<
          Firestore['set_page/{pageId}/signups/{uid}']
        >)
      : null,
  );

  const handleSignup: SignupFormSubmit = useCallback(
    async ({ values, close }) => {
      if (page && user && artistGroupId) {
        const publicKey = await getPublicKey({
          id: artistGroupId,
          item: 'bio',
        });

        const { encryptedData, encryptedSymmetricKey, iv } =
          await encryptJsonObject({
            jsonObject: values,
            publicKeyPem: publicKey.key,
          });

        const secure: SecurePayload<Bytes> = {
          id: publicKey.id,
          key: Bytes.fromUint8Array(encryptedSymmetricKey),
          data: Bytes.fromUint8Array(encryptedData),
          iv: Bytes.fromUint8Array(iv),
        };

        const ref = doc(page.ref, 'signups', user.uid) as DocumentReference<
          Firestore['set_page/{pageId}/signups/{uid}']
        >;

        await setDoc(ref, { ...values, secure });

        close?.();
      }
    },
    [page, user, artistGroupId],
  );

  const pageData = page?.data();

  const allReleaseIds = [
    ...(pageData?.takeoverPromotions.pre_save_release?.release
      ? [pageData.takeoverPromotions.pre_save_release.release.id]
      : []),
    ...(modules?.docs ?? []).map((doc) => {
      const mod = doc.data();
      return mod.type === 'pre_save_release' ? mod.release?.id : '';
    }),
  ].filter(Boolean);

  const releaseIds = [...new Set(allReleaseIds)];

  const [preSaves, preSavesLoading] = useCollection(
    releaseIds.length > 0
      ? (query(
          collection(firestore, 'release_presaves'),
          and(
            or(
              where('releaseIds', 'array-contains-any', releaseIds),
              and(
                where('artistGroupId', '==', artistGroupId || ''),
                where('allReleases', '==', true),
              ),
            ),
            where('uid', '==', user?.uid),
          ),
        ) as Query<ReleasePresave>)
      : null,
  );

  const optInAllPreSaves = (releaseId: string, preSave: ReleasePresave) => {
    setDoc(
      doc(
        firestore,
        `release_presaves/${preSave.service}-${preSave.artistGroupId}-${preSave.uid}`,
      ),
      { allReleases: true, allReleasesSource: releaseId },
      { merge: true },
    );
  };

  const track = useAnalytics({ user, page });

  const embedScripts = getEmbedScripts(sorted);

  if (!page) {
    return null;
  }

  if (!page.exists()) {
    return <div>Not Found</div>;
  }

  return (
    <>
      <Helmet>
        {embedScripts.map((script) => (
          <script key={script.src} {...script} />
        ))}
      </Helmet>
      <ThemeGlobal />
      <ThemeProvider theme={page.get('theme')}>
        <AssetsProvider base={import.meta.env.VITE_ASSETS_BASE_URL}>
          <LocalizationProvider globalI18n={globalI18n}>
            <ArtistProvider id={artistGroupId} artist={artist}>
              <SignupFormProvider
                hasSignup={signup?.exists()}
                onSubmit={handleSignup}
              >
                <ConcertsProvider
                  concerts={concerts?.docs.map((doc) => doc.data())}
                >
                  <CoordinatesProvider
                    coordinates={coordinates}
                    countryCode={country}
                  >
                    <AnalyticsProvider track={track}>
                      <PreSaveProvider
                        userId={user?.uid}
                        preSaves={preSaves?.docs.map((doc) => doc.data())}
                        preSavesLoading={
                          releaseIds.length === 0 ? undefined : preSavesLoading
                        }
                        optInAllPreSaves={optInAllPreSaves}
                        releaseEndpoint={import.meta.env.VITE_PORTAL_URL}
                        spotify={{
                          authEndpoint: import.meta.env
                            .VITE_SPOTIFY_AUTH_ENDPOINT,
                          clientId: import.meta.env.VITE_SPOTIFY_CLIENT_ID,
                          workerEndpoint: import.meta.env
                            .VITE_SPOTIFY_WORKER_ENDPOINT,
                        }}
                        apple={{
                          musicKitCdn: import.meta.env.VITE_APPLE_MUSIC_KIT_CDN,
                          workerEndpoint: import.meta.env
                            .VITE_APPLE_WORKER_ENDPOINT,
                        }}
                      >
                        <ThemePage asBody enableAos>
                          <Page page={page.data()} modules={sorted} />
                        </ThemePage>
                      </PreSaveProvider>
                    </AnalyticsProvider>
                  </CoordinatesProvider>
                </ConcertsProvider>
              </SignupFormProvider>
            </ArtistProvider>
          </LocalizationProvider>
        </AssetsProvider>
      </ThemeProvider>
    </>
  );
};

export const Route = createFileRoute('/')({
  component: Component,
  beforeLoad: async () => signInAnonymously(auth),
  loader: async () => {
    try {
      const response = await fetch('/geo').then((r) => r.json());
      return { country: response?.country };
    } catch {
      return { country: undefined };
    }
  },
});
