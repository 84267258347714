import { Socials } from '@max/common/setpage';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';
import { useAnalytics, useHostContext } from '../../contexts';
import { ThemePage } from '../../theme';
import { ICONS, NAMES } from './constants';

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

const Link = styled.a`
  display: flex;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  color: var(--set-page-accent-color);
  animation: var(--set-page-accent-animation);
  background-size: var(--set-page-accent-background-size);
  background-color: var(--set-page-accent-background-color);
  background-image: var(--set-page-accent-background-image);

  &:is(button) {
    border: 0;
    cursor: pointer;
    font-size: 12px;
  }
`;

const DropdownMenuContent = styled(DropdownMenu.Content)`
  width: min(var(--radix-dropdown-menu-content-available-width), 285px);
  max-height: var(--radix-dropdown-menu-content-available-height);
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  overflow: auto;
  z-index: 10;
`;

const TailLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 11px 15px;
  color: #333333;
  outline: none;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;

  &:not(:last-child) {
    border-bottom: 1px solid #e6e9eb;
  }

  &[data-highlighted] {
    background: #e6e9eb50;
  }
`;

const MAX = 5;

interface SocialIconsProps {
  socials: Socials;
  className?: string;
  aos?: string;
}

export const SocialIcons = ({ socials, className, aos }: SocialIconsProps) => {
  const host = useHostContext();
  const { track } = useAnalytics();
  // ignore legacy socials
  const socialsToDisplay = Array.isArray(socials)
    ? socials.filter((social) => social.selected)
    : [];

  const head = socialsToDisplay.slice(0, MAX);
  const tail = socialsToDisplay.length > MAX ? socialsToDisplay.slice(MAX) : [];

  return (
    <Container className={className} data-aos={aos}>
      {head.map((social) => {
        const Icon = ICONS[social.id];

        return (
          <Link
            key={social.id}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => track?.({ type: 'click', id: social.id })}
          >
            <Icon />
          </Link>
        );
      })}
      {!!tail.length && (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger disabled={host.isPhonePreview} asChild>
            <Link as="button">+{tail.length}</Link>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <ThemePage asChild>
              <DropdownMenuContent collisionPadding={20} sideOffset={5}>
                <DropdownMenu.Arrow
                  width={20}
                  height={10}
                  style={{ fill: 'white' }}
                />
                {tail.map((social) => {
                  const Icon = ICONS[social.id];
                  const name = NAMES[social.id];

                  if (!social.selected || !social.url) {
                    return null;
                  }

                  return (
                    <DropdownMenu.Item key={social.id} asChild>
                      <TailLink
                        href={social.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                          track?.({ type: 'click', id: social.id })
                        }
                      >
                        <Icon /> {name}
                      </TailLink>
                    </DropdownMenu.Item>
                  );
                })}
              </DropdownMenuContent>
            </ThemePage>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      )}
    </Container>
  );
};
