import * as Dialog from '@radix-ui/react-dialog';
import { readableColor, transparentize } from 'polished';
import styled from 'styled-components';
import { MOBILE_SELECTOR } from '../../utils';
import { Button } from '../button';

export const DialogContent = styled(Dialog.Content)<{
  $backgroundUrl?: string;
  $backgroundColor?: string;
}>`
  position: relative;
  width: 600px;
  border-radius: 20px;
  outline: none;
  padding: 90px 0;
  border: 2px solid #666666;

  @container (${MOBILE_SELECTOR}) {
    align-self: end;
    width: 100%;
    max-height: 90%;
    overflow: auto;
    border-radius: 12px 12px 0 0;
    border: none;
  }

  &[data-background-type='none'] {
    background: var(--set-page-card-background-color);
  }

  &[data-background-type='image'] {
    color: var(--set-page-white);
    background-position: center;
    background-size: cover;
    background-image: ${(p) =>
      `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.9)), url('${p.$backgroundUrl}')`};
  }

  &[data-background-type='color'] {
    color: ${(p) => p.$backgroundColor && readableColor(p.$backgroundColor)};
    background: ${(p) => p.$backgroundColor};
  }
`;

export const DialogClose = styled(Dialog.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 14px;
  top: 14px;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  outline: none;
  z-index: 6;
  cursor: pointer;
  border-radius: 50%;
  color: black;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 300px;
  max-width: 80%;
  margin: 0 auto;
`;

export const Image = styled.img`
  display: block;
  width: 300px;
  max-width: 100%;
  aspect-ratio: 1;
  margin: 0 auto;
  object-fit: cover;
  border: 1px solid #666666;
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DialogTitle = styled(Dialog.Title)`
  font-size: 26px;
  line-height: 1.5;
  text-align: center;
`;

export const DialogDescription = styled(Dialog.Description)`
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
`;

export const DialogButton = styled(Button)<{
  $color?: string;
  $background?: string;
}>`
  display: flex;
  gap: 12px;
  align-items: center;

  &[data-color='true'] {
    --set-page-accent-color: ${(p) => p.$color};
    --set-page-accent-animation: none;
    --set-page-accent-background-size: none;
    --set-page-accent-background-color: ${(p) => p.$background};
    --set-page-accent-background-color-50: ${(p) =>
      p.$background && transparentize(0.5, p.$background)};
    --set-page-accent-background-image: none;
  }
`;
