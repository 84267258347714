import { SetPageCalendarEvent } from '@max/common/setpage';
import styled from 'styled-components';
import { Button } from '../button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Event = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 400;
  border-top: 1px solid #333333;
  gap: 20px;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  font-weight: 600;
`;

interface ListViewLayoutProps {
  events: SetPageCalendarEvent[];
}

export const ListViewLayout = ({ events }: ListViewLayoutProps) => {
  return (
    <Container>
      {events.map((event, index) => (
        <Event key={index}>
          <Info>
            <div>{String(event.date)}</div>
            <Title>{event.title}</Title>
            <div>{event.venue.name}</div>
          </Info>
          <Button
            as="a"
            href={event.url}
            title={event.title}
            target="_blank"
            rel="noreferrer"
          >
            {event.button?.text}
          </Button>
        </Event>
      ))}
    </Container>
  );
};
