import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const initGlobalI18n = () => {
  i18n.use(LanguageDetector).init({
    debug: import.meta.env.DEV,

    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },

    detection: {
      order: ['querystring', 'localStorage', 'navigator'], // order and from where user language should be detected
      caches: ['localStorage'], // cache user language on
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    },

    resources: {},
  });

  return i18n;
};
