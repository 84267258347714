import { SetPageMusicLinksModule } from '@max/common/setpage';
import 'swiper/css';
import 'swiper/css/navigation';
import { Card } from '../card';
import { ListLayout } from './list-layout';
import { SwiperLayout } from './swiper-layout';

export type MusicLinksProps = {
  module: SetPageMusicLinksModule;
  defaultImage?: string;
};

export const MusicLinks = ({ module, defaultImage }: MusicLinksProps) => {
  const layout = module.styles?.layout ?? 'card-swiper';

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      {layout === 'card-swiper' && (
        <SwiperLayout items={module.items} defaultImage={defaultImage} />
      )}
      {(layout === 'image-right' || layout === 'image-left') && (
        <ListLayout
          items={module.items}
          layout={layout}
          defaultImage={defaultImage}
        />
      )}
    </Card>
  );
};
