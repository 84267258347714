import { Slot, Slottable } from '@radix-ui/react-slot';
import {
  ComponentProps,
  ElementRef,
  PropsWithChildren,
  forwardRef,
} from 'react';
import styled, { useTheme } from 'styled-components';

const SvgGradient = styled.svg`
  width: 0;
  height: 0;
  position: absolute;
`;

const SvgGradientStart = styled.stop`
  stop-color: var(--set-page-accent-start-color);
`;

const SvgGradientEnd = styled.stop`
  stop-color: var(--set-page-accent-end-color);
`;

interface ThemeElementProps extends ComponentProps<'div'> {
  asBody?: boolean;
  asChild?: boolean;
  enableAos?: boolean;
}

export const ThemePage = forwardRef<
  ElementRef<'div'>,
  PropsWithChildren<ThemeElementProps>
>(
  (
    { asBody = false, asChild = false, children, enableAos = false, ...props },
    ref,
  ) => {
    const theme = useTheme();
    const Component = asChild ? Slot : 'div';

    return (
      <Component
        ref={ref}
        data-set-page-id={theme.runtime.id}
        data-set-page-body={asBody}
        data-set-page-theme={theme.id}
        data-set-page-mode={theme.mode}
        data-set-page-source={theme.tab.source}
        data-set-page-gradient={theme.tab.gradient}
        data-set-page-cover={theme.cover.style}
        data-set-page-button={theme.button.style}
        data-set-page-animation={theme.animation}
        data-set-page-texture={theme.texture}
        data-set-page-enable-aos={enableAos}
        {...props}
      >
        {asBody && (
          <SvgGradient>
            <defs>
              <linearGradient id={theme.runtime.id}>
                <SvgGradientStart offset={0} />
                <SvgGradientEnd offset={1} />
              </linearGradient>
            </defs>
          </SvgGradient>
        )}
        <Slottable>{children}</Slottable>
      </Component>
    );
  },
);
