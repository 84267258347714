import { SetPageMedia } from '@max/common/setpage';
import styled from 'styled-components';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { Video } from '../video';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 16px;
`;

const GridItem = styled.div`
  aspect-ratio: 1;

  &:nth-child(4n-7) {
    aspect-ratio: auto;
    grid-row-end: span 2;
  }
`;

interface MasonryLayoutProps {
  videos: SetPageMedia[];
}

export const MasonryLayout = ({ videos }: MasonryLayoutProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <Grid>
      {videos.map((video, index) => (
        <GridItem
          key={index}
          onClick={() => track?.({ type: 'click', id: video.id })}
        >
          <Video
            data-aos="set-page-fade-right"
            url={video.url}
            title={video.title}
            thumbnail={
              video.background ? assetUrl({ url: video.background }) : undefined
            }
          />
        </GridItem>
      ))}
    </Grid>
  );
};
