import { SetPageCalendarEvent } from '@max/common/setpage';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const Container = styled.div`
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;

  div.swiper-button-prev {
    color: #fff;
    height: 100%;
    top: 5%;
    left: 0;
    width: 10%;
    justify-content: flex-start;
  }
  div.swiper-button-prev::after {
    font-weight: 900;
    margin-left: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
  div.swiper-button-next {
    color: #fff;
    top: 5%;
    right: 0;
    height: 100%;
    width: 10%;
    justify-content: flex-end;
  }
  div.swiper-button-next::after {
    font-weight: 900;
    margin-right: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
`;

const Card = styled.a`
  display: block;
  position: relative;
  border: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  color: white;
`;

const Background = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;

  ${Card}:hover & {
    transform: scale(1.05);
  }
`;

const Overlay = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  gap: 8px;
  inset: 0;
  background-image: linear-gradient(
    180deg,
    transparent 50%,
    rgba(0, 0, 0, 0.5) 85%
  );
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const Venue = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

const EventDate = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 4px 18px;
  font-size: 12px;
  border-radius: 50px;
  animation: var(--set-page-accent-animation);
  background-size: var(--set-page-accent-background-size);
  background-color: var(--set-page-accent-background-color);
  background-image: var(--set-page-accent-background-image);
`;

interface CardSwiperLayoutProps {
  events: SetPageCalendarEvent[];
}

export const CardSwiperLayout = ({ events }: CardSwiperLayoutProps) => {
  return (
    <Container>
      <Swiper
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={1.35}
        navigation={true}
      >
        {events.map((event, index) => (
          <SwiperSlide key={index}>
            <Card href={event.url} target="_blank" rel="noreferrer">
              <Background src={event.background} />
              <Overlay>
                <Title>{event.title}</Title>
                <Venue>{event.venue.name}</Venue>
              </Overlay>
              <EventDate>{String(event.date)}</EventDate>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
