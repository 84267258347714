import { SetPageContentModule } from '@max/common/setpage';
import styled from 'styled-components';
import { Button } from '../button';
import { Card } from '../card';
import { MailchimpForm } from '../mailchimp-form';

const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7em;
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

interface ContentProps {
  module: SetPageContentModule;
}

export const Content = ({ module }: ContentProps) => {
  const align = module.styles?.heading.align;

  return (
    <Card title={module.title} align={align} data-bg-type="none">
      <Text style={{ textAlign: align }}>{module.content}</Text>
      {module.cta && (
        <Action>
          {module.cta.type === 'link' && (
            <Button as="a" href={module.cta.action}>
              {module.cta.buttonText}
            </Button>
          )}
          {module.cta.type === 'email' && (
            <Button as="a" href={`mailto:${module.cta.action}`}>
              {module.cta.buttonText}
            </Button>
          )}
          {module.cta.type === 'phone' && (
            <Button as="a" href={`tel:+1:${module.cta.action}`}>
              {module.cta.buttonText}
            </Button>
          )}
          {module.cta.type === 'text' && (
            <Button as="a" href={`sms:+1:${module.cta.action}`}>
              {module.cta.buttonText}
            </Button>
          )}
          {module.cta.type === 'form' && (
            <Button onClick={() => alert('TODO: Form')}>
              {module.cta.buttonText}
            </Button>
          )}
          {module.cta.type === 'mailchimp' && <MailchimpForm />}
        </Action>
      )}
    </Card>
  );
};
