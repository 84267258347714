import { CSSProperties, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { MOBILE_SELECTOR } from '../../utils';

const Container = styled.div`
  [data-set-page-theme='rabun'] & {
    border-radius: 20px;
    padding: 20px;
    background: var(--set-page-card-background-color);
  }
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;

const Heading = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  width: fit-content;
  flex-shrink: 0;

  @container (${MOBILE_SELECTOR}) {
    font-size: 20px;
  }

  [data-align='center'] & {
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;
  }

  [data-align='left'] & {
    padding-right: 20px;
  }

  [data-align='right'] & {
    padding-left: 20px;
    margin-left: auto;
    text-align: right;
  }
`;

const Divider = styled.div`
  display: none;
  height: 0.5px;
  flex: 1;

  [data-set-page-theme='springer'] & {
    display: block;
  }

  [data-set-page-mode='dark'] & {
    background: #666666;
  }

  [data-set-page-mode='light'] & {
    background: #cccccc;
  }
`;

interface CardProps {
  title?: string;
  align?: CSSProperties['textAlign'];
}

export const Title = ({ align, title }: CardProps) => {
  return (
    <Header data-align={align} data-aos="set-page-fade-down">
      {(align === 'center' || align === 'right') && <Divider />}
      <Heading>{title}</Heading>
      {(align === 'center' || align === 'left') && <Divider />}
    </Header>
  );
};

export const Card = ({
  title,
  align = 'center',
  children,
  ...props
}: PropsWithChildren<CardProps>) => {
  return (
    <Container {...props}>
      {title && <Title title={title} align={align} />}
      {children}
    </Container>
  );
};
