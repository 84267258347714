import { SetPage, SetPageModule } from '@max/common/setpage';
import styled from 'styled-components';
import { useHostContext } from '../../contexts';
import { Cover } from '../cover';
import { Footer } from '../footer';
import { Module } from '../module';
import { Profile } from '../profile';
import { QuickLinks } from '../quick-links';
import { SocialIcons } from '../social-icons';
import { TakeOverPromotion } from '../take-over-promotion';

const Container = styled.div<{ $isPhonePreview?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${(p) => (p.$isPhonePreview ? '100%' : '100dvh')};
`;

const Body = styled.div`
  flex: 1;
  container-type: inline-size;
  min-height: 400px;
`;

const Section = styled.div`
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 660px;
  padding: 0 20px;

  &[data-full-width='true'] {
    max-width: unset;
    padding: unset;
  }

  [data-set-page-theme='springer'] &,
  [data-set-page-theme='tropic-colour'] & {
    margin-bottom: 32px;
  }

  [data-set-page-theme='springer'] &[data-type='cta'],
  [data-set-page-theme='tropic-colour'] &[data-type='cta'] {
    max-width: none;
    padding: 0;
  }
`;

export interface SetPageRendererProps {
  page: SetPage;
  modules?: SetPageModule[];
}

export const Page = ({ page, modules }: SetPageRendererProps) => {
  const host = useHostContext();
  const takeoverModule =
    page.takeoverType && page.takeoverPromotions[page.takeoverType];

  return (
    <Container $isPhonePreview={host.isPhonePreview}>
      {page.settings.enable_takeover_promotion && takeoverModule && (
        <TakeOverPromotion module={takeoverModule} />
      )}
      <Body>
        <Cover page={page} />
        <Section>
          <Profile page={page} />
        </Section>
        {modules?.map((module, index) => (
          <Section
            key={module.id ?? index}
            data-type={module.type}
            data-full-width={module.styles?.layout === 'full_width'}
          >
            <Module page={page} module={module} />
          </Section>
        ))}
        {page.settings.show_social_icons && page.socials && (
          <Section data-aos="set-page-fade-up">
            <SocialIcons socials={page.socials} />
          </Section>
        )}
      </Body>
      <Footer crop={page.settings.crop_page_width} />
      {page.integrations?.quickLinks && (
        <QuickLinks
          links={page.integrations.quickLinks}
          data-aos="set-page-fade-up"
        />
      )}
    </Container>
  );
};
