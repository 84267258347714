import { SetPageMedia } from '@max/common/setpage';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { Image } from '../image';

const Container = styled.div`
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;

  div.swiper-button-prev {
    color: #fff;
    height: 100%;
    top: 5%;
    left: 0;
    width: 10%;
    justify-content: flex-start;
  }
  div.swiper-button-prev::after {
    font-weight: 900;
    margin-left: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
  div.swiper-button-next {
    color: #fff;
    top: 5%;
    right: 0;
    height: 100%;
    width: 10%;
    justify-content: flex-end;
  }
  div.swiper-button-next::after {
    font-weight: 900;
    margin-right: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }

  div.swiper-button-prev {
    color: #fff;
    height: 100%;
    top: 5%;
    left: 0;
    width: 10%;
    justify-content: flex-start;
  }
  div.swiper-button-prev::after {
    font-weight: 900;
    margin-left: calc(20px - 0.5vw);
  }
  div.swiper-button-next {
    color: #fff;
    top: 5%;
    right: 0;
    height: 100%;
    width: 10%;
    justify-content: flex-end;
  }
  div.swiper-button-next::after {
    font-weight: 900;
    margin-right: calc(20px - 0.5vw);
  }
`;

const Item = styled.div`
  aspect-ratio: 1;
`;

interface CardSwiperLayoutProps {
  images: SetPageMedia[];
}

export const CardSwiperLayout = ({ images }: CardSwiperLayoutProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <Container data-aos="set-page-fade-right">
      <Swiper
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={images.length === 1 ? 1 : 1.3}
        navigation={true}
        centeredSlides={images.length === 1}
      >
        {images.map((image, index) => (
          <SwiperSlide
            key={index}
            style={{ maxWidth: 350, maxHeight: 350 }}
            onClick={() => track?.({ type: 'click', id: image.id })}
          >
            <Item>
              <Image
                url={assetUrl({ url: image.url }) ?? ''}
                title={image.title}
              />
            </Item>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
