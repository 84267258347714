import { SetPage, SetPageTheme } from '@max/common/setpage';
import styled from 'styled-components';
import { useAssetsContext } from '../../contexts';
import { useSetPageTheme } from '../../theme';
import { MOBILE_SELECTOR } from '../../utils';
import { Card } from '../card';
import { CTA } from '../cta';
import { SocialIcons } from '../social-icons';

const ProfileCard = styled(Card)`
  position: relative;
  z-index: 1;
`;
const StyledCTA = styled.div`
  grid-area: cta;
  [data-set-page-theme='tropic-colour'] & {
    width: 100%;
  }
`;
const StyledSocialIcons = styled(SocialIcons)`
  grid-area: socials;
  [data-set-page-theme='tropic-colour'] & {
    @container (max-width: 600px) {
      width: 100%;
    }
  }
`;

const Picture = styled.img`
  width: 135px;
  height: 135px;
  border-radius: 135px;
  object-fit: cover;
  border: 2px solid var(--set-page-main-border-color);
  flex-shrink: 0;
  grid-area: picture;

  [data-set-page-theme='rabun'] &,
  [data-set-page-theme='springer'] & {
    margin: -65px auto 0 auto;
  }
`;

const Content = styled.div<{
  $gridTemplateAreas: { desktop: string; mobile: string };
}>`
  display: flex;
  flex-direction: column;
  column-gap: 16px;
  row-gap: 8px;
  text-align: center;

  [data-set-page-theme='tropic-colour'] & {
    display: grid;
    justify-items: flex-start;
    grid-template-columns: 135px auto;
    align-items: center;
    grid-template-areas: ${({ $gridTemplateAreas }) =>
      $gridTemplateAreas.desktop};
    text-align: left;
  }
  @container (${MOBILE_SELECTOR}) {
    [data-set-page-theme='tropic-colour'] & {
      grid-template-areas: ${({ $gridTemplateAreas }) =>
        $gridTemplateAreas.mobile};
    }
  }
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  grid-area: title;
`;

const Bio = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  grid-area: bio;
`;

interface ProfileProps {
  page: SetPage;
}

const getGridTemplateAreas = (
  hasBio: boolean,
  hasCta: boolean,
  hasSocials: boolean,
): { desktop: string; mobile: string } => {
  const gridTemplateAreas = {
    desktop: `
      'picture .'
      'picture title'
    `,
    mobile: `
      'picture .'
      'picture title'
    `,
  };

  // if no other items, append bottom "margin"
  if (!hasBio && !hasSocials && !hasCta) {
    return {
      desktop: `
          ${gridTemplateAreas.desktop}
          'picture .'
        `,
      mobile: `
          ${gridTemplateAreas.mobile}
          'picture .' 
        `,
    };
  }

  if (hasBio) {
    gridTemplateAreas.desktop = `
        ${gridTemplateAreas.desktop}
        'picture bio'
      `;
    gridTemplateAreas.mobile = `
        ${gridTemplateAreas.mobile}
        'picture bio'
      `;
  }

  if (!hasSocials && !hasCta) {
    return {
      desktop: `
          ${gridTemplateAreas.desktop}
          'picture .'
        `,
      mobile: `
          ${gridTemplateAreas.mobile}
          'picture .' 
        `,
    };
  }

  if (hasSocials) {
    gridTemplateAreas.desktop = `
        ${gridTemplateAreas.desktop}
        'picture socials'
      `;
    gridTemplateAreas.mobile = `
        ${gridTemplateAreas.mobile}
        'picture .'
        'socials socials'
        '. .'
      `;
  }

  if (!hasCta) {
    gridTemplateAreas.desktop = `
        ${gridTemplateAreas.desktop}
        'picture .'
      `;
  } else {
    gridTemplateAreas.desktop = `
        ${gridTemplateAreas.desktop}
        'picture .'
        '. .'
        'cta cta'
        '. .'
      `;

    if (!hasSocials) {
      gridTemplateAreas.mobile = `
          ${gridTemplateAreas.mobile}
          'picture .'
        `;
    }

    gridTemplateAreas.mobile = `
       ${gridTemplateAreas.mobile}
       'cta cta'
       '. .'
      `;
  }

  return gridTemplateAreas;
};

export const Profile = ({ page }: ProfileProps) => {
  const version = page.version ?? 1;
  const showCta = page.settings.show_header_cta ?? true;
  const { assetUrl } = useAssetsContext();
  const { id: themeId } = useSetPageTheme();

  const hasBio = !!page.bio;
  const hasCta = !!(version === 1 && showCta && page.cta);
  const hasSocials = page.settings.show_social_icons && !!page.socials;

  return (
    <ProfileCard>
      <Content
        $gridTemplateAreas={getGridTemplateAreas(hasBio, hasCta, hasSocials)}
        data-bg-type="none"
      >
        <Picture
          src={assetUrl({ url: page.profileImage })}
          data-aos={getThemeAnimation(themeId, 'picture')}
        />
        {page.title && (
          <Title data-aos={getThemeAnimation(themeId, 'title')}>
            {page.title}
          </Title>
        )}
        {hasBio && (
          <Bio data-aos={getThemeAnimation(themeId, 'bio')}>{page.bio}</Bio>
        )}
        {hasSocials && page.socials && (
          <StyledSocialIcons
            socials={page.socials}
            aos={getThemeAnimation(themeId, 'socials')}
          />
        )}
        {version === 1 && showCta && page.cta && (
          <StyledCTA data-aos="set-page-fade-up">
            <CTA cta={page.cta} />
          </StyledCTA>
        )}
      </Content>
    </ProfileCard>
  );
};

const getThemeAnimation = (
  theme: SetPageTheme['id'], // 'springer' | 'rabun' | 'tropic-colour',
  component: 'picture' | 'title' | 'bio' | 'socials',
) => {
  switch (component) {
    case 'picture':
      return theme === 'springer' || theme === 'rabun'
        ? 'set-page-fade-down'
        : 'set-page-fade-right';
    case 'title':
    case 'bio':
    case 'socials':
      return theme === 'springer' || theme === 'rabun'
        ? 'set-page-fade-up'
        : 'set-page-fade-socials';
  }
};
