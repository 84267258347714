import {
  SetPageMusicLinkAlbum,
  SetPageMusicLinkSong,
} from '@max/common/setpage';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { MOBILE_SELECTOR } from '../../utils';
import { LineClamp } from '../../utils/text';
import { SwiperModal } from './swiper-modal';

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const ButtonWrapper = styled.div<{ $bgColor?: string; $reverse: boolean }>`
  color: var(--set-page-accent-color);
  animation: var(--set-page-accent-animation);
  background-size: var(--set-page-accent-background-size);
  background-color: var(--set-page-accent-background-color);
  background-image: var(--set-page-accent-background-image);
  display: flex;
  text-decoration: none;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  ${({ $reverse }) =>
    $reverse &&
    css`
      flex-direction: row-reverse;
      & > img {
        rotate: -20deg;
        transform: translate(5px, 5px);
      }
    `}
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 5px;
  flex-grow: 1;
`;
const Image = styled.img`
  object-fit: contain;
  display: block;
  width: 15%;
  aspect-ratio: 1;
  rotate: 20deg;
  scale: 1.2;
  transform: translate(-5px, 5px);
  border: 3px solid #ffffff;
`;
const Text = styled(LineClamp)`
  font-size: 22px;
  font-weight: 600;

  @container (${MOBILE_SELECTOR}) {
    font-size: 18px;
  }
`;

const Description = styled.div`
  font-size: 16px;

  @container (${MOBILE_SELECTOR}) {
    font-size: 12px;
  }
`;

interface ListLayoutProps {
  items: (SetPageMusicLinkAlbum | SetPageMusicLinkSong)[];
  defaultImage?: string;
  layout: string;
}

export const ListLayout = ({
  items,
  layout,
  defaultImage,
}: ListLayoutProps) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  return (
    <List>
      {items.map((item, index) => (
        <ButtonWrapper
          data-aos="set-page-fade-right"
          key={index}
          $reverse={layout === 'image-left'}
          onClick={() => {
            setOpen(true);
            setIndex(index);
          }}
        >
          <Content>
            <Text $lines={2}>{item.name}</Text>
            <Description>Listen Now</Description>
          </Content>
          {(item.image ?? defaultImage) && (
            <Image src={item.image ?? defaultImage} />
          )}
        </ButtonWrapper>
      ))}
      <SwiperModal open={open} setOpen={setOpen} items={items} index={index} />
    </List>
  );
};
