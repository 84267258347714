import { SetPageQuickLinkNames } from '@max/common/setpage';
import { IconType } from 'react-icons';
import {
  FaCalendar,
  FaCommentDots,
  FaHeart,
  FaHome,
  FaImage,
  FaMusic,
  FaNewspaper,
  FaPhone,
  FaRocket,
  FaSms,
  FaTshirt,
  FaUser,
  FaUserGraduate,
  FaVideo,
} from 'react-icons/fa';

export const ICONS: Record<SetPageQuickLinkNames, IconType> = {
  calendar: FaCalendar,
  chat: FaCommentDots,
  education: FaUserGraduate,
  heart: FaHeart,
  home: FaHome,
  image: FaImage,
  music: FaMusic,
  newspaper: FaNewspaper,
  phone: FaPhone,
  rocket: FaRocket,
  sms: FaSms,
  tshirt: FaTshirt,
  user: FaUser,
  video: FaVideo,
};
