import { forwardRef } from 'react';
import styled, {
  PolymorphicComponentProps,
  WebTarget,
} from 'styled-components';
import sketch from '../../assets/masks/sketch.png';
import tornEdges from '../../assets/masks/torn-edges.png';

const StyledButton = styled.button`
  --height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: var(--height);
  text-decoration: none;
  background: none;
  font-size: 13px;
  font-weight: 600;
  font-family: inherit;
  line-height: 1;
  text-align: center;
  border: 0;
  margin: 0;
  padding: 0 16px;
  cursor: pointer;
  color: var(--set-page-main-color);
  transition: all 0.2s ease-in-out;

  &:disabled {
    opacity: 0.5;
  }

  [data-set-page-button='dashed'] & {
    border-radius: 12px;
    border: 2px dashed var(--set-page-accent-background-color);

    &:hover {
      color: var(--set-page-accent-color);
      background: var(--set-page-accent-background-color);
    }
  }

  [data-set-page-button='framed'] & {
    color: var(--set-page-accent-color);
    background: var(--set-page-accent-background-color);
    outline-offset: 3px;
    outline: 3px solid var(--set-page-accent-background-color);
  }

  [data-set-page-button='framed-border'] & {
    border: 2px solid var(--set-page-accent-background-color);
    transform-style: preserve-3d;

    &:before {
      content: '';
      background: var(--set-page-accent-background-color);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 6px;
      left: 6px;
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out;
      transform: translateZ(-1px);
    }

    &:hover:before {
      opacity: 1;
    }
  }

  [data-set-page-button='nodes'] & {
    border: 2px solid var(--set-page-accent-background-color-50);
    background: var(--set-page-accent-background-color-50);

    &:hover {
      border-color: var(--set-page-accent-background-color);
    }

    [data-nodes] {
      display: block;
    }
  }

  [data-set-page-button='radius'] & {
    border-radius: 12px;
    color: var(--set-page-accent-color);
    animation: var(--set-page-accent-animation);
    background-size: var(--set-page-accent-background-size);
    background-color: var(--set-page-accent-background-color);
    background-image: var(--set-page-accent-background-image);
  }

  [data-set-page-button='round-border'] & {
    border: 2px solid var(--set-page-accent-background-color);
    border-radius: 100px;

    &:hover {
      color: var(--set-page-accent-color);
      background: var(--set-page-accent-background-color);
    }
  }

  [data-set-page-button='rounded'] & {
    border-radius: 100px;
    color: var(--set-page-accent-color);
    animation: var(--set-page-accent-animation);
    background-size: var(--set-page-accent-background-size);
    background-color: var(--set-page-accent-background-color);
    background-image: var(--set-page-accent-background-image);
  }

  [data-set-page-button='shadow'] & {
    color: var(--set-page-accent-color);
    background: var(--set-page-accent-background-color);
    box-shadow: 6px 6px 0 var(--set-page-accent-background-color-50);
  }

  [data-set-page-button='sketch'] & {
    border-radius: 5px;
    border: 2px solid var(--set-page-accent-background-color);

    &:hover {
      background: var(--set-page-accent-background-color);
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      height: calc(100% + 5px);
      border-radius: 5px;
      border: 2px solid var(--set-page-accent-background-color);
    }

    &:after {
      content: '';
      background: var(--set-page-accent-background-color);
      border-radius: 0 0 5px 5px;
      display: block;
      height: 5px;
      mask: url(${sketch});
      mask-repeat: repeat-x;
      mask-size: 10px 8px;
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
    }
  }

  [data-set-page-button='skewed'] & {
    clip-path: polygon(2.5% 0%, 100% 0%, 97.5% 100%, 0% 100%);
    color: var(--set-page-accent-color);
    animation: var(--set-page-accent-animation);
    background-size: var(--set-page-accent-background-size);
    background-color: var(--set-page-accent-background-color);
    background-image: var(--set-page-accent-background-image);
  }

  [data-set-page-button='squared'] & {
    color: var(--set-page-accent-color);
    animation: var(--set-page-accent-animation);
    background-size: var(--set-page-accent-background-size);
    background-color: var(--set-page-accent-background-color);
    background-image: var(--set-page-accent-background-image);
  }

  [data-set-page-button='torn-edges'] & {
    color: var(--set-page-accent-color);
    background: var(--set-page-accent-background-color);
    height: calc(var(--height) - 6px);

    &:before,
    &:after {
      content: '';
      display: block;
      background: var(--set-page-accent-background-color);
      height: 10px;
      position: absolute;
      left: 0;
      right: 0;
      mask-image: url(${tornEdges});
    }

    &:before {
      top: -3px;
    }

    &:after {
      bottom: -3px;
      transform: rotate(180deg);
    }
  }
`;

const Dots = styled.div`
  display: none;

  > div {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: var(--set-page-accent-background-color);

    &:nth-of-type(1) {
      top: -6px;
      left: -6px;
    }

    &:nth-of-type(2) {
      top: -6px;
      left: calc(50% - 5px);
    }

    &:nth-of-type(3) {
      top: -6px;
      right: -6px;
    }

    &:nth-of-type(4) {
      bottom: -6px;
      left: -6px;
    }

    &:nth-of-type(5) {
      bottom: -6px;
      left: calc(50% - 5px);
    }

    &:nth-of-type(6) {
      bottom: -6px;
      right: -6px;
    }
  }
`;

const InnerButton = <T extends WebTarget = 'button'>(
  { children, ref, ...props }: PolymorphicComponentProps<'web', object, T, T>,
  _ref: typeof ref,
) => {
  return (
    <StyledButton ref={_ref} {...props}>
      {children}
      <Dots data-nodes>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </Dots>
    </StyledButton>
  );
};

export const Button = forwardRef(InnerButton) as <
  T extends WebTarget = 'button',
>(
  props: PolymorphicComponentProps<'web', object, T, T>,
) => ReturnType<typeof InnerButton>;
