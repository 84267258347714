import {
  SetPage,
  SetPageMerchModule,
  SetPageProduct,
} from '@max/common/setpage';
import { FaAngleRight } from 'react-icons/fa';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAnalytics, useAssetsContext } from '../../contexts';

const SwiperWrapper = styled.div`
  overflow: hidden;
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;

  div.swiper-button-prev {
    color: #fff;
    height: 100%;
    top: 5%;
    left: 0;
    width: 10%;
    justify-content: flex-start;
  }
  div.swiper-button-prev::after {
    font-weight: 900;
    margin-left: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
  div.swiper-button-next {
    color: #fff;
    top: 5%;
    right: 0;
    height: 100%;
    width: 10%;
    justify-content: flex-end;
  }
  div.swiper-button-next::after {
    font-weight: 900;
    margin-right: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
`;
const ContentWrapper = styled.a<{ $src?: string; $gradient?: string }>`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.022),
      rgba(0, 0, 0, 0.782)
    ),
    url(${({ $src }) => $src});
  aspect-ratio: 1;
  background-size: cover;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  color: #fff;
  text-decoration: none;
`;
const Title = styled.div`
  font-size: 22px;
  position: relative;
  display: flex;
  width: 100%;
  font-weight: 600;
  align-items: center;
  justify-content: flex-start;
  & > span {
    position: relative;
    color: var(--set-page-main-color);
    background-color: var(--set-page-main-background-color);
    width: fit-content;
    padding: 0 10px;
    z-index: 5;
  }
`;
const Description = styled.div`
  font-size: 16px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;
const Content = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
`;
const IconContainer = styled.div`
  align-items: center;
  border: 1px solid #fff;
  border-radius: 100%;
  display: flex;
  height: 18px;
  justify-content: center;
  width: 18px;
  flex-shrink: 0;
  margin-left: auto;
  margin-bottom: 5px;
`;

interface SwiperLayoutProps {
  page?: SetPage;
  module: SetPageMerchModule;
  merch: SetPageProduct[];
}

export const SwiperLayout = ({ page, module, merch }: SwiperLayoutProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <SwiperWrapper data-aos="set-page-fade-right">
      <Swiper
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={merch.length === 1 ? 1 : 1.3}
        navigation={true}
        centeredSlides={merch.length === 1 && !module.displayMerchStoreButton}
      >
        {merch.map((item, index) => (
          <SwiperSlide key={index} style={{ maxWidth: 350, maxHeight: 350 }}>
            <ContentWrapper
              $src={assetUrl({ url: item.photo })}
              href={item.link}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => track?.({ type: 'click', id: module.id })}
            >
              <Content>
                <Title>{item.title}</Title>
                {!!item.price && <Description>{item.price}</Description>}
              </Content>
              <IconContainer>
                <FaAngleRight size={12} />
              </IconContainer>
            </ContentWrapper>
          </SwiperSlide>
        ))}
        {module.displayMerchStoreButton && (
          <SwiperSlide style={{ maxWidth: 350, maxHeight: 350 }}>
            <ContentWrapper
              $src={page?.profileImage || ''}
              href={module.merchStoreButtonUrl}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => track?.({ type: 'click', id: module.id })}
            >
              <Content>
                <Title>{module.merchStoreButtonText}</Title>
              </Content>
              <IconContainer>
                <FaAngleRight size={12} />
              </IconContainer>
            </ContentWrapper>
          </SwiperSlide>
        )}
      </Swiper>
    </SwiperWrapper>
  );
};
