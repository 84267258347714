import { SetPageButtonLink } from '@max/common/setpage';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ButtonLinkSwiperItem } from './button-link-swiper-item';

const SwiperWrapper = styled.div`
  overflow: hidden;
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;

  div.swiper-button-prev {
    color: #fff;
    height: 100%;
    top: 5%;
    left: 0;
    width: 10%;
    justify-content: flex-start;
  }
  div.swiper-button-prev::after {
    font-weight: 900;
    margin-left: calc(20px - 0.5vw);
  }
  div.swiper-button-next {
    color: #fff;
    top: 5%;
    right: 0;
    height: 100%;
    width: 10%;
    justify-content: flex-end;
  }
  div.swiper-button-next::after {
    font-weight: 900;
    margin-right: calc(20px - 0.5vw);
  }
`;

interface SwiperLayoutProps {
  buttons: SetPageButtonLink[];
  defaultImage?: string;
}

export const SwiperLayout = ({ buttons, defaultImage }: SwiperLayoutProps) => {
  return (
    <SwiperWrapper data-aos="set-page-fade-right">
      <Swiper
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={1.35}
        navigation={true}
        style={{ width: '100%' }}
        centeredSlides={buttons.length === 1}
      >
        {buttons.map((button, index) => (
          <SwiperSlide key={index}>
            <ButtonLinkSwiperItem link={button} defaultImage={defaultImage} />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  );
};
