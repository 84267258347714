import { PropsWithChildren, createContext, useContext } from 'react';
import { SignupFormValues } from '../types';

export type SignupFormSubmit = (options: {
  values: SignupFormValues;
  close?: () => void;
}) => Promise<void>;

export interface SignupFormContext {
  hasSignup?: boolean;
  onSubmit?: SignupFormSubmit;
}

const Context = createContext<SignupFormContext>({});

export const SignupFormProvider = ({
  children,
  ...props
}: PropsWithChildren<SignupFormContext>) => (
  <Context.Provider value={props}>{children}</Context.Provider>
);

export const useSignupFormContext = () => useContext(Context);
