import AmazonSvg from '../../assets/svg/Amazon.svg?react';
import AppleSvg from '../../assets/svg/Apple.svg?react';
import DeezerSvg from '../../assets/svg/Deezer.svg?react';
import PandoraSvg from '../../assets/svg/Pandora.svg?react';
import SpotifySvg from '../../assets/svg/Spotify.svg?react';
import TidalSvg from '../../assets/svg/Tidal.svg?react';
import YouTubeSvg from '../../assets/svg/YouTube.svg?react';

export type Services =
  | 'Spotify'
  | 'Apple_Music'
  | 'Amazon_Music'
  | 'Deezer'
  | 'YouTube'
  | 'Tidal'
  | 'Pandora';

export type IconType = {
  [key in Services]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const ICONS = {
  Deezer: DeezerSvg,
  YouTube: YouTubeSvg,
  Pandora: PandoraSvg,
  Tidal: TidalSvg,
  Spotify: SpotifySvg,
  Amazon_Music: AmazonSvg,
  Apple_Music: AppleSvg,
} as IconType;
