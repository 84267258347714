import styled from 'styled-components';
import SvgEditIcon from '../../assets/svg/edit.svg?react';

export const IncompletePromotion = () => {
  return (
    <IncompletePreviewDialog>
      <DialogInterior>
        <EditIconContainer>
          <SvgEditIcon width={40} height={41} />
        </EditIconContainer>
        <IncompleteHeaderText>
          Take-Over Promotion awaiting user input
        </IncompleteHeaderText>
        <IncompleteBodyText>
          Please add content to see preview
        </IncompleteBodyText>
      </DialogInterior>
    </IncompletePreviewDialog>
  );
};

const IncompletePreviewDialog = styled.div`
  position: relative;
  border-radius: 12px;
  outline: none;
  color: var(--set-page-main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 37px;
  height: 75%;
  width: 100%;
  height: 100%;

  background: var(--set-page-white);
`;

const DialogInterior = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f5f7;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  padding: 10px;
  gap: 8px;
`;

const EditIconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 6.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dae0e4;
`;

const IncompleteHeaderText = styled.div`
  font-size: 15px;
  line-height: 18px;
  font-family: 'Poppins';
  font-weight: 600;
  text-align: center;
  color: #666;
`;

const IncompleteBodyText = styled.div`
  font-family: 'Poppins';
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #666;
`;
