import * as Select from '@radix-ui/react-select';
import { FaCheck, FaChevronDown, FaGlobe } from 'react-icons/fa';
import {
  CountryIso2,
  FlagImage,
  defaultCountries,
  parseCountry,
} from 'react-international-phone';
import styled from 'styled-components';
import { useHostContext } from '../../contexts';
import { ThemePage } from '../../theme';

const SelectTrigger = styled(Select.Trigger)`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 44px;
  padding: 0 13px;
  background: var(--set-page-white);
  border: 1px solid #cccccc;
  border-radius: 6px;
  outline: none;
  font-size: 13px;
  font-family: var(--set-page-base-font-family);
  color: var(--set-page-black);

  &:focus-within {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
  }

  &[data-placeholder] {
    font-size: 12px;
    color: #999999;
  }

  &[data-addon='true'] {
    height: auto;
    border-radius: 6px 0 0 6px;
    border-width: 0 1px 0 0;
  }

  &[data-invalid='true'] {
    z-index: 1;
    border-color: #e45c52;
    outline: 1px solid #e45c52;
    outline-offset: -2px;
  }
`;

const SelectIcon = styled(Select.Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

const SelectContent = styled(Select.Content)`
  overflow: hidden;
  border-radius: 6px;
  background: var(--set-page-white);
  color: var(--set-page-black);
  z-index: var(--z-index-modal-content);
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
`;

const SelectItem = styled(Select.Item)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 14px;
  font-size: 13px;
  user-select: none;
  color: var(--set-page-black);

  &[data-highlighted] {
    outline: none;
    background: #fafafa;
  }
`;

const SelectItemIndicator = styled(Select.ItemIndicator)`
  margin-left: auto;
`;

interface CountrySelectProps {
  id?: string;
  name?: string;
  value?: CountryIso2;
  placeholder?: string;
  tabIndex?: number;
  isAddon?: boolean;
  isInvalid?: boolean;
  onChange?: (value: CountryIso2) => void;
  onBlur?: () => void;
}

export const CountrySelect = ({
  id,
  name,
  value,
  placeholder,
  tabIndex,
  isAddon,
  isInvalid,
  onChange,
  onBlur,
}: CountrySelectProps) => {
  const host = useHostContext();

  return (
    <Select.Root
      name={name}
      value={value}
      disabled={host.isPhonePreview}
      onValueChange={(v) => onChange?.(v)}
    >
      <SelectTrigger
        id={id}
        data-addon={isAddon}
        data-invalid={isInvalid}
        tabIndex={tabIndex}
        onBlur={onBlur}
      >
        {!isAddon &&
          (value ? (
            <FlagImage iso2={value} size={18} />
          ) : (
            <FaGlobe size={18} />
          ))}
        <Select.Value placeholder={placeholder}>
          {isAddon && (
            <FlagImage
              iso2={value}
              size={18}
              style={{ verticalAlign: 'middle' }}
            />
          )}
        </Select.Value>
        <SelectIcon>
          <FaChevronDown size={12} />
        </SelectIcon>
      </SelectTrigger>
      <Select.Portal>
        <ThemePage asChild>
          <SelectContent
            onCloseAutoFocus={(e) => isAddon && e.preventDefault()}
          >
            <Select.Viewport>
              {defaultCountries.map((c) => {
                const country = parseCountry(c);

                return (
                  <SelectItem key={country.iso2} value={country.iso2}>
                    <FlagImage iso2={country.iso2} size={18} />
                    <Select.ItemText>{country.name}</Select.ItemText>
                    <SelectItemIndicator>
                      <FaCheck />
                    </SelectItemIndicator>
                  </SelectItem>
                );
              })}
            </Select.Viewport>
          </SelectContent>
        </ThemePage>
      </Select.Portal>
    </Select.Root>
  );
};
