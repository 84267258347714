import { SetPageTheme } from '@max/common/setpage';
import { readableColor, transparentize } from 'polished';
import { PropsWithChildren, useEffect, useState } from 'react';
import * as styled from 'styled-components';
import { ThemeProvider as BaseThemeProvider } from 'styled-components';
import WebFont from 'webfontloader';
import { getThemeFontFamily } from './utils';

const GlobalRuntime = styled.createGlobalStyle`
  [data-set-page-id='${(p) => p.theme.runtime.id}'] {
    color: var(--set-page-main-color);
    font-family: ${(p) =>
      p.theme.runtime.fontFamily && p.theme.runtime.fontFamily};
    font-size: 16px;
    line-height: 1.4;
    --set-page-main-font-family: ${(p) =>
      p.theme.runtime.fontFamily && p.theme.runtime.fontFamily};

    &[data-set-page-body='true'] {
      background-color: var(--set-page-main-background-color);
      background-image: var(--set-page-texture-url);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
    }

    &[data-set-page-source='custom-color'] {
      --set-page-accent-color: ${(p) =>
        p.theme.tab?.custom && readableColor(p.theme.tab?.custom)};
      --set-page-accent-background-color: ${(p) => p.theme.tab?.custom};
      --set-page-accent-background-color-50: ${(p) =>
        p.theme.tab?.custom && transparentize(0.5, p.theme.tab?.custom)};
      --set-page-accent-background-image: none;
      --set-page-accent-start-color: ${(p) => p.theme.tab?.custom};
      --set-page-accent-end-color: ${(p) => p.theme.tab?.custom};
    }

    * {
      box-sizing: border-box;
    }
  }
`;

interface ThemeProviderProps {
  theme: SetPageTheme;
}

let currentId = 0;

export const ThemeProvider = ({
  theme,
  children,
}: PropsWithChildren<ThemeProviderProps>) => {
  const fontFamily = getThemeFontFamily(theme);
  const [id] = useState(() => ++currentId);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [...new Set(['Poppins:600,700,900', fontFamily])],
      },
    });
  }, [fontFamily]);

  return (
    <BaseThemeProvider
      theme={{ ...theme, runtime: { id: String(id), fontFamily } }}
    >
      <GlobalRuntime />
      {children}
    </BaseThemeProvider>
  );
};

export const useSetPageTheme = styled.useTheme;
