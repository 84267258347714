import { SetPageMedia } from '@max/common/setpage';
import styled from 'styled-components';
import { useAssetsContext } from '../../contexts';
import { Image } from '../image';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

interface GridItemProps {
  size: number;
}

const GridItem = styled.div<GridItemProps>`
  aspect-ratio: 1;

  &:nth-child(4n-7) {
    aspect-ratio: auto;
    grid-row-end: span 2;

    img {
      object-fit: cover;
    }
  }
`;

interface MasonryGridLayoutProps {
  images: SetPageMedia[];
}

export const MasonryGridLayout = ({ images }: MasonryGridLayoutProps) => {
  const { assetUrl } = useAssetsContext();

  return (
    <Grid>
      {images.map((image, index) => (
        <GridItem
          key={index}
          size={images.length}
          data-aos="set-page-fade-right"
        >
          <Image url={assetUrl({ url: image.url }) ?? ''} title={image.title} />
        </GridItem>
      ))}
    </Grid>
  );
};
