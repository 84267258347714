import { SetPageMedia } from '@max/common/setpage';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { Video } from '../video';

const Container = styled.div`
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;

  div.swiper-button-prev {
    color: #fff;
    height: 100%;
    top: 5%;
    left: 0;
    width: 10%;
    justify-content: flex-start;
  }
  div.swiper-button-prev::after {
    font-weight: 900;
    margin-left: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
  div.swiper-button-next {
    color: #fff;
    top: 5%;
    right: 0;
    height: 100%;
    width: 10%;
    justify-content: flex-end;
  }
  div.swiper-button-next::after {
    font-weight: 900;
    margin-right: calc(20px - 0.5vw);
    text-shadow: 1px 1px 3px #0000008f;
  }
`;

const Item = styled.div`
  aspect-ratio: 1;
`;

interface SwiperLayoutProps {
  videos: SetPageMedia[];
}

export const SwiperLayout = ({ videos }: SwiperLayoutProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <Container data-aos="set-page-fade-right">
      <Swiper
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={videos.length === 1 ? 1 : 1.3}
        navigation={true}
        centeredSlides={videos.length === 1}
      >
        {videos.map((video, index) => (
          <SwiperSlide key={index} style={{ maxWidth: 350, maxHeight: 350 }}>
            <Item onClick={() => track?.({ type: 'click', id: video.id })}>
              <Video
                url={video.url}
                title={video.title}
                thumbnail={
                  video.background
                    ? assetUrl({ url: video.background })
                    : undefined
                }
              />
            </Item>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
