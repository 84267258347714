import { PresaveSource, ReleasePresave } from '@max/common/artists/presave';
import { SetPageRelease } from '@max/common/setpage';
import { createContext, useContext, useEffect, useState } from 'react';

type Service = SetPageRelease['preSaveOn'][0];

interface Context {
  releaseEndpoint?: string;
  userId?: string;
  authorizeSpotify?: (releaseId: string, source: PresaveSource) => void;
  authorizeAppleMusic?: (releaseId: string, source: PresaveSource) => void;
  savingRelease?: Service | null;
  savedRelease?: Service | null;
  preSaves?: ReleasePresave[];
  preSavesLoading?: boolean;
  optInAllPreSaves?: (releaseId: string, preSave: ReleasePresave) => void;
  spotify?: {
    authEndpoint: string;
    clientId: string;
    workerEndpoint: string;
  };
  apple?: {
    musicKitCdn: string;
    workerEndpoint: string;
  };
}

const PreSaveContext = createContext<Context | undefined>(undefined);

type PreSaveProviderProps = {
  children: React.ReactNode;
} & Pick<
  Context,
  | 'userId'
  | 'preSaves'
  | 'preSavesLoading'
  | 'optInAllPreSaves'
  | 'spotify'
  | 'apple'
  | 'releaseEndpoint'
>;

const SPOTIFY_SCOPES = [
  'user-read-email',
  'user-read-recently-played',
  'user-top-read',
  'user-library-read',
  'user-library-modify',
];

export const PreSaveProvider = ({
  children,
  preSaves,
  preSavesLoading,
  optInAllPreSaves,
  userId,
  spotify,
  apple,
  releaseEndpoint,
}: PreSaveProviderProps) => {
  const [savingRelease, setSavingRelease] = useState<Service | null>(null);
  const [savedRelease, setSavedRelease] = useState<Service | null>(null);

  const authorizeSpotify = (releaseId: string, source: PresaveSource) => {
    if (!spotify) return;

    setSavingRelease('spotify');
    const authUrl = new URL(spotify.authEndpoint);
    authUrl.searchParams.set('client_id', spotify.clientId);
    authUrl.searchParams.set('redirect_uri', spotify.workerEndpoint);
    authUrl.searchParams.set('response_type', 'code');
    authUrl.searchParams.set('scope', SPOTIFY_SCOPES.join(','));
    authUrl.searchParams.set(
      'state',
      JSON.stringify({
        uid: userId,
        releaseId,
        redirectUri: encodeURIComponent(window.location.href),
        source,
      }),
    );
    window.location.href = authUrl.toString();
  };

  const checkForSpotifyPreSave = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isPresaved = searchParams.get('spotify_presaved');
    if (isPresaved === 'true') {
      const url = new URL(window.location.toString());
      url.searchParams.delete('spotify_presaved');
      window.history.replaceState({}, document.title, url.toString());
      setSavedRelease('spotify');
      setSavingRelease(null);
    }
  };

  const loadMusicKitApple = (callback: () => void) => {
    if (!apple) return;

    const script = document.createElement('script');
    script.src = apple.musicKitCdn;
    script.async = true;
    script.onload = async () => {
      try {
        const response = await fetch(apple.workerEndpoint, {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ action: 'token' }),
        }).then((res) => res.json());

        await window.MusicKit.configure({
          developerToken: response?.token,
          app: {
            name: 'SETBio',
            build: '1',
          },
        });
        callback();
      } catch (error) {
        console.error(error);
      }
    };
    document.body.appendChild(script);
  };

  const authorizeAppleMusic = async (
    releaseId: string,
    source: PresaveSource,
  ) => {
    if (!apple) return;

    setSavingRelease('apple_music');
    try {
      const authorize = async () => {
        const music = window.MusicKit.getInstance();
        const code = await music.authorize();

        await fetch(apple.workerEndpoint, {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            code,
            releaseId,
            redirectUri: '',
            uid: userId,
            source,
          }),
        });
      };
      if (!window.MusicKit) {
        loadMusicKitApple(authorize);
      } else {
        authorize();
      }
      setSavedRelease('apple_music');
    } catch (error) {
      console.error(error);
    }
    setSavingRelease(null);
  };

  useEffect(() => {
    checkForSpotifyPreSave();
  }, []);

  const value = {
    authorizeSpotify,
    authorizeAppleMusic,
    savingRelease,
    savedRelease,
    preSaves,
    preSavesLoading:
      preSavesLoading === undefined && optInAllPreSaves
        ? true
        : preSavesLoading,
    optInAllPreSaves,
    spotify,
    apple,
    releaseEndpoint,
  };

  return (
    <PreSaveContext.Provider value={value}>{children}</PreSaveContext.Provider>
  );
};

export const usePreSaveContext = () => useContext(PreSaveContext);
