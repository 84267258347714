import {
  SetPageCustomPromotionModule,
  SetPageMusicLinksModule,
  SetPagePreSaveReleaseModule,
  SetPageSignupFormModule,
  SetPageSurveyLinkModule,
} from '@max/common/setpage';
import * as Dialog from '@radix-ui/react-dialog';
import { useEffect, useRef, useState } from 'react';
import { FaCircle, FaTimesCircle } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';
import { useHostContext, useSignupFormContext } from '../../contexts';
import { useDialogProps } from '../../hooks';
import { ThemePage } from '../../theme';
import { MOBILE_SELECTOR } from '../../utils';
import { DialogOverlay } from '../dialog';
import { MusicLinksGallery } from '../music-links/music-links-gallery';
import {
  SignupFormContent,
  DialogContent as SignupFormDialogContent,
} from '../signup-form';
import { Custom } from './custom';
import { DialogClose, DialogContent } from './dialog';
import { IncompletePromotion } from './incomplete';
import { PreSaveReleaseDialog } from './pre-save-release';
import { SurveyLink } from './survey-link';

interface TakeOverPromotionProps {
  module:
    | SetPageSignupFormModule
    | SetPageCustomPromotionModule
    | SetPageSurveyLinkModule
    | SetPageMusicLinksModule
    | SetPagePreSaveReleaseModule;
}

export const TakeOverPromotion = ({ module }: TakeOverPromotionProps) => {
  const dialog = useDialogProps();
  const host = useHostContext();
  const [open, setOpen] = useState(module.type !== 'signup_form');
  const [showed, setShowed] = useState(false);
  const overlay = useRef<HTMLDivElement>(null);
  const signup = useSignupFormContext();
  const hasFlagInContext = 'hasSignup' in signup;

  const handleOpenChange = (open = false) => {
    host.onTakeoverOpenChange?.(open);
    setOpen(open);
  };

  useEffect(() => {
    const handle = (event: AnimationEvent) => {
      setShowed(
        event.animationName === show.name &&
          event.target === overlay.current?.querySelector('div[role="dialog"]'),
      );
    };
    document.addEventListener('animationend', handle);
    return () => document.removeEventListener('animationend', handle);
  }, []);

  useEffect(() => {
    if (module.type !== 'signup_form') {
      return;
    }

    // No flag available, show it by default for storybook, previews, etc.
    if (!hasFlagInContext) {
      return setOpen(true);
    }

    if (signup.hasSignup != null) {
      setOpen(!signup.hasSignup);
    }
  }, [hasFlagInContext, module.type, signup.hasSignup]);

  const takeoverModule = (() => {
    switch (module.type) {
      case 'signup_form':
        return (
          <SignupFormContent
            dialogClose={<CloseComponent />}
            module={module}
            onClose={handleOpenChange}
          />
        );
      case 'music_links':
        return module.items?.length ? (
          <StyledMusicLinksGallery
            index={0}
            items={module.items}
            dialogClose={<CloseComponent />}
          />
        ) : (
          <IncompletePromotion />
        );
      case 'survey_link':
        return <SurveyLink module={module} />;
      case 'custom_promotion':
        return module.imageUrl ? (
          <Custom module={module} />
        ) : (
          <IncompletePromotion />
        );
      case 'pre_save_release': {
        return module.release ? (
          <PreSaveReleaseDialog module={module} />
        ) : (
          <IncompletePromotion />
        );
      }
    }
  })();

  return (
    <Dialog.Root
      open={open}
      onOpenChange={handleOpenChange}
      {...dialog.rootProps}
    >
      <Dialog.Portal {...dialog.portalProps}>
        <ThemePage asChild>
          <BottomAlignedDialogOverlay
            ref={overlay}
            style={{ overflow: showed ? 'auto' : 'hidden' }}
            {...dialog.overlayProps}
          >
            {takeoverModule}
          </BottomAlignedDialogOverlay>
        </ThemePage>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const CloseComponent = () => (
  <DialogClose>
    <FaCircle size={25} color="white" />
    <FaTimesCircle
      size={25}
      style={{ position: 'absolute', top: 0, left: 0 }}
    />
  </DialogClose>
);

const show = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const BottomAlignedDialogOverlay = styled(DialogOverlay)`
  &[data-is-phone-preview='true'] {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  div[role='dialog'] {
    animation: ${show} 300ms 500ms;
    animation-fill-mode: backwards;
    -o-animation-fill-mode: backwards;
    -webkit-animation-fill-mode: backwards;
  }

  button[type='submit'] {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }

  @container (${MOBILE_SELECTOR}) {
    ${SignupFormDialogContent}, ${DialogContent} {
      height: 100%;
      max-height: 100%;
    }
  }
`;

const StyledMusicLinksGallery = styled(MusicLinksGallery)`
  & > div {
    max-width: 550px;
  }
`;
