import { SetPageButtonLink } from '@max/common/setpage';
import styled from 'styled-components';
import { useAssetsContext } from '../../contexts';
import { MOBILE_SELECTOR } from '../../utils';
import { LineClamp } from '../../utils/text';

const Link = styled.a<{ $src?: string }>`
  display: block;
  top: 0;
  left: 0;
  aspect-ratio: 1;
  width: 100%;
  background-image: url(${({ $src }) => $src});
  background-size: 100%;
  background-position: center;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-size: 105%;
  }
`;
const Text = styled(LineClamp)`
  font-size: 18px;
  font-weight: 600;
  color: white;

  @container (${MOBILE_SELECTOR}) {
    font-size: 16px;
  }
`;
const Description = styled(LineClamp)`
  font-size: 14px;
  color: white;

  @container (${MOBILE_SELECTOR}) {
    font-size: 12px;
  }
`;
const Content = styled.div`
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding-right: 20px;
  flex-direction: column;
  gap: 5px;
`;

const Container = styled.div`
  position: relative;
`;

interface ButtonLinkGridItemProps {
  link: SetPageButtonLink;
  defaultImage?: string;
  showImageText?: boolean;
}

export const ButtonLinkGridItem = ({
  link,
  defaultImage,
  showImageText,
}: ButtonLinkGridItemProps) => {
  const { assetUrl } = useAssetsContext();

  return (
    <Container data-aos="set-page-fade-right">
      <Link
        $src={assetUrl({ url: link.photo || defaultImage || '' })}
        href={link.url}
        rel="noopener noreferrer"
        target="_blank"
      />
      {showImageText && (
        <Content>
          {link.text && <LineClamp as={Text}>{link.text}</LineClamp>}
          {link.description && (
            <Description $lines={2}>{link.description}</Description>
          )}
        </Content>
      )}
    </Container>
  );
};
