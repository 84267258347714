import { SetPageButtonLink } from '@max/common/setpage';
import styled from 'styled-components';
import { ButtonLinkGridItem } from './button-link-grid-item';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  &[data-columns='one-image'] {
    grid-template-columns: 1fr;
  }

  &[data-columns='two-images'] {
    grid-template-columns: repeat(2, 1fr);
  }

  &[data-columns='three-images'] {
    grid-template-columns: repeat(3, 1fr);
  }

  &[data-columns='four-images'] {
    grid-template-columns: repeat(4, 1fr);
  }
`;

interface GridLayoutProps {
  buttons: SetPageButtonLink[];
  columns: string;
  defaultImage?: string;
  showImageText?: boolean;
}

export const GridLayout = ({
  buttons,
  columns,
  defaultImage,
  showImageText,
}: GridLayoutProps) => {
  return (
    <Grid data-columns={columns}>
      {buttons?.map((button, index) => (
        <ButtonLinkGridItem
          data-aos="set-page-fade-right"
          key={index}
          link={button}
          defaultImage={defaultImage}
          showImageText={showImageText}
        />
      ))}
    </Grid>
  );
};
