import { SetPageMedia } from '@max/common/setpage';
import styled from 'styled-components';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { Image } from '../image';

const Container = styled.div`
  display: grid;
  gap: 16px;

  &[data-columns='one-image'] {
    grid-template-columns: 1fr;
  }

  &[data-columns='two-images'] {
    grid-template-columns: repeat(2, 1fr);
  }

  &[data-columns='three-images'] {
    grid-template-columns: repeat(3, 1fr);
  }

  &[data-columns='four-images'] {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ImageContainer = styled.div`
  aspect-ratio: 1;
`;

interface ImageLinksLayoutProps {
  images: SetPageMedia[];
  columns?: string;
}

export const ImageLinksLayout = ({
  images,
  columns,
}: ImageLinksLayoutProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <Container data-columns={columns}>
      {images.map((image, index) => (
        <ImageContainer
          data-aos="set-page-fade-right"
          key={index}
          onClick={() => track?.({ type: 'click', id: image.id })}
        >
          <Image url={assetUrl({ url: image.url }) ?? ''} title={image.title} />
        </ImageContainer>
      ))}
    </Container>
  );
};
