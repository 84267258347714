import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

export const DialogOverlay = styled(Dialog.Overlay)`
  position: fixed;
  inset: 0;
  overflow: auto;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: var(--z-index-modal-overlay);
  container-type: inline-size;

  &[data-is-phone-preview='true'] {
    position: absolute;
  }
`;
