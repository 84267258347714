import { SetPagePreSaveReleaseModule } from '@max/common/setpage';
import { forwardRef } from 'react';
import { FaCircle, FaTimesCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { useDialogProps } from '../../hooks';
import { PreSaveRelease } from '../pre-save-release/pre-save-release';
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from './dialog';

interface SurveyLinkProps {
  module: SetPagePreSaveReleaseModule;
}

export const PreSaveReleaseDialog = forwardRef<HTMLDivElement, SurveyLinkProps>(
  ({ module }, ref) => {
    const dialog = useDialogProps();

    return (
      <StyledDialogContent ref={ref} {...dialog.contentProps}>
        <DialogClose>
          <FaCircle
            size={25}
            color={module.release?.colorMode === 'light' ? 'white' : 'black'}
          />
          <FaTimesCircle
            color={module.release?.colorMode === 'light' ? 'black' : 'white'}
            size={25}
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        </DialogClose>
        <DialogTitle />
        <DialogDescription />
        <PreSaveRelease module={module} />
      </StyledDialogContent>
    );
  },
);

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
  overflow: hidden;
  width: 100%;
  max-width: 605px;

  [data-is-phone-preview='true'] & {
    margin-top: 90px;
  }
`;
