import {
  SetPageMusicLinkAlbum,
  SetPageMusicLinkSong,
} from '@max/common/setpage';
import * as Dialog from '@radix-ui/react-dialog';
import { useDialogProps } from '../../hooks';
import { ThemePage } from '../../theme';
import { DialogOverlay } from '../dialog';
import { MusicLinksGallery } from './music-links-gallery';

interface SwiperModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  items: (SetPageMusicLinkAlbum | SetPageMusicLinkSong)[];
  index: number;
  defaultImage?: string;
}
export const SwiperModal = ({
  open,
  setOpen,
  items,
  index,
  defaultImage,
}: SwiperModalProps) => {
  const dialog = useDialogProps();

  return (
    <Dialog.Root open={open} onOpenChange={setOpen} {...dialog.rootProps}>
      <Dialog.Portal {...dialog.portalProps}>
        <ThemePage asChild>
          <DialogOverlay {...dialog.overlayProps}>
            <MusicLinksGallery
              items={items}
              index={index}
              defaultImage={defaultImage}
            />
          </DialogOverlay>
        </ThemePage>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
