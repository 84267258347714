import { SetPageCTA } from '@max/common/setpage';
import styled from 'styled-components';
import { Button } from '../button';
import { MailchimpForm } from '../mailchimp-form';

const CTAButton = styled(Button)`
  min-width: 150px;

  &[data-override='true'] {
    --set-page-accent-color: var(--set-page-black);
    --set-page-accent-animation: none;
    --set-page-accent-background-size: none;
    --set-page-accent-background-color: var(--set-page-white);
    --set-page-accent-background-color-50: rgba(255, 255, 255, 0.5);
    --set-page-accent-background-image: none;
  }
`;

interface CTAProps {
  cta: SetPageCTA;
  override?: boolean;
}

export const CTA = ({ cta, override }: CTAProps) => {
  return (
    <>
      {cta.type === 'link' && (
        <CTAButton forwardedAs="a" data-override={override} href={cta.action}>
          {cta.buttonText}
        </CTAButton>
      )}
      {cta.type === 'email' && (
        <CTAButton
          forwardedAs="a"
          data-override={override}
          href={`mailto:${cta.action}`}
        >
          {cta.buttonText}
        </CTAButton>
      )}
      {cta.type === 'phone' && (
        <CTAButton
          forwardedAs="a"
          data-override={override}
          href={`tel:+1:${cta.action}`}
        >
          {cta.buttonText}
        </CTAButton>
      )}
      {cta.type === 'text' && (
        <CTAButton
          forwardedAs="a"
          data-override={override}
          href={`sms:+1:${cta.action}`}
        >
          {cta.buttonText}
        </CTAButton>
      )}
      {cta.type === 'form' && (
        <CTAButton data-override={override} onClick={() => alert('TODO: Form')}>
          {cta.buttonText}
        </CTAButton>
      )}
      {cta.type === 'mailchimp' && <MailchimpForm />}
    </>
  );
};
