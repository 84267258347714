import { SetPageButtonsLinksModule } from '@max/common/setpage';
import { Card } from '../card';
import { GridLayout } from './grid-layout';
import { ListLayout } from './list-layout';
import { SwiperLayout } from './swiper-layout';

interface ButtonsLinksProps {
  module: SetPageButtonsLinksModule;
  defaultImage?: string;
}

export const ButtonsLinks = ({ module, defaultImage }: ButtonsLinksProps) => {
  const layout = module.styles?.layout ?? 'standard-buttons';
  const columns = module.styles?.columnsLayout ?? 'three-images';

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      {layout === 'image-links' && (
        <GridLayout
          buttons={module.links}
          showImageText={module.showImageText}
          columns={columns}
          defaultImage={defaultImage}
        />
      )}
      {layout === 'card-swiper' && (
        <SwiperLayout buttons={module.links} defaultImage={defaultImage} />
      )}
      {(layout === 'standard-buttons' ||
        layout === 'image-buttons-right' ||
        layout === 'image-buttons-left') && (
        <ListLayout
          buttons={module.links}
          layout={layout}
          defaultImage={defaultImage}
        />
      )}
    </Card>
  );
};
