import { SetPageSpotifyEmbed } from '@max/common/setpage';
import styled from 'styled-components';
import { Card } from '../card';
import { CodeEmbed } from '../code-embed';

interface SpotifyEmbedProps {
  module: SetPageSpotifyEmbed;
}

const StyledCard = styled(Card)<{ $compact?: boolean }>`
  iframe {
    border-radius: 20px;
    height: ${({ $compact }) => ($compact ? '152px' : '352px')};
  }
`;

export const SpotifyEmbed = ({ module }: SpotifyEmbedProps) => {
  return (
    <StyledCard
      data-aos="set-page-fade-right"
      title={module.title}
      $compact={module.compactSize}
      align={module.styles?.heading.align}
    >
      <CodeEmbed
        code={module.code}
        domains={['spotify.com', 'open.spotify.com']}
      />
    </StyledCard>
  );
};
