import parse, { DOMNode, Element } from 'html-react-parser';

const replacer = (domains?: string[]) => (node: DOMNode) => {
  const EMPTY = <></>; // eslint-disable-line react/jsx-no-useless-fragment

  if (node instanceof Element) {
    if (node.tagName === 'script') {
      return EMPTY;
    }

    if (node.tagName === 'iframe') {
      if (!domains) {
        return EMPTY;
      }

      if (!node.attribs.src) {
        return EMPTY;
      }

      try {
        const url = new URL(node.attribs.src);

        if (!domains.includes(url.hostname) || url.protocol !== 'https:') {
          return EMPTY;
        }
      } catch {
        return EMPTY;
      }
    }
  }
};

interface CodeEmbedProps {
  code?: string;
  domains?: string[];
  compact?: boolean;
}

export const CodeEmbed = ({ code, domains }: CodeEmbedProps) => {
  if (!code) {
    return null;
  }

  return parse(code, { replace: replacer(domains) });
};
