import { SocialPlatforms } from '@max/common/setpage';
import {
  FaApple,
  FaBandcamp,
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPatreon,
  FaSnapchat,
  FaSoundcloud,
  FaSpotify,
  FaTiktok,
  FaTwitch,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { FaBluesky, FaThreads } from 'react-icons/fa6';
import { SiKick } from 'react-icons/si';
import Lemon8 from './../../assets/svg/lemon8.svg?react';

export const ICONS: Record<SocialPlatforms, React.FC> = {
  apple: FaApple,
  bandcamp: FaBandcamp,
  discord: FaDiscord,
  facebook: FaFacebook,
  kick: SiKick,
  instagram: FaInstagram,
  linkedIn: FaLinkedin,
  patreon: FaPatreon,
  snapchat: FaSnapchat,
  soundcloud: FaSoundcloud,
  spotify: FaSpotify,
  threads: FaThreads,
  tiktok: FaTiktok,
  twitch: FaTwitch,
  twitter: FaTwitter,
  youtube: FaYoutube,
  bluesky: FaBluesky,
  lemon8: Lemon8,
};

export const NAMES: Record<SocialPlatforms, string> = {
  apple: 'Apple Music',
  bandcamp: 'Bandcamp',
  discord: 'Discord',
  facebook: 'Facebook',
  kick: 'Kick',
  instagram: 'Instagram',
  linkedIn: 'LinkedIn',
  patreon: 'Patreon',
  snapchat: 'Snapchat',
  soundcloud: 'SoundCloud',
  spotify: 'Spotify',
  threads: 'Threads',
  tiktok: 'TikTok',
  twitch: 'Twitch',
  twitter: 'Twitter',
  youtube: 'YouTube',
  lemon8: 'Lemon8',
  bluesky: 'Bluesky',
};
