import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const isUSAPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumberForRegion(
      phoneUtil.parseAndKeepRawInput(phone),
      'US',
    );
  } catch (error) {
    return false;
  }
};
