import * as Dialog from '@radix-ui/react-dialog';
import { useHostContext } from '../contexts';

export const useDialogProps = () => {
  const host = useHostContext();

  const rootProps = {
    modal: !host.isPhonePreview,
  } satisfies Dialog.DialogProps;

  const portalProps = {
    container: host.portalContainer,
  } satisfies Dialog.DialogPortalProps;

  const overlayProps = {
    as: host.isPhonePreview ? 'div' : undefined,
    'data-is-phone-preview': host.isPhonePreview,
  } satisfies Dialog.DialogOverlayProps & {
    as?: string;
    'data-is-phone-preview'?: boolean;
  };

  const contentProps = {
    onEscapeKeyDown: (event) => {
      if (host.isPhonePreview) {
        event.preventDefault();
      }
    },
    onPointerDownOutside: (event) => {
      if (host.isPhonePreview) {
        if (!host.portalContainer?.contains(event.target as Node)) {
          event.preventDefault();
        }
      }
    },
    onInteractOutside: (event) => {
      if (host.isPhonePreview) {
        if (!host.portalContainer?.contains(event.target as Node)) {
          event.preventDefault();
        }
      }
    },
  } satisfies Dialog.DialogContentProps;

  return {
    rootProps,
    portalProps,
    overlayProps,
    contentProps,
  } as const;
};
