import { SetPageTestimonialsModule } from '@max/common/setpage';
import { FaStar } from 'react-icons/fa';
import styled, { useTheme } from 'styled-components';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Card } from '../card';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  div.swiper {
    width: 100%;
    height: 100%;
  }
  div.swiper-slide {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.swiper-button-prev {
    color: var(--set-page-main-color);
  }
  div.swiper-button-prev::after {
    font-size: 22px;
    font-weight: 900;
  }
  div.swiper-button-next {
    color: var(--set-page-main-color);
  }
  div.swiper-button-next::after {
    font-size: 22px;
    font-weight: 900;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  gap: 10px;
  padding: 30px;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
`;
const Name = styled.div`
  color: var(--set-page-accent-background-color);
  font-size: 15px;
  font-weight: 600;
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
`;
const Stars = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  color: var(--set-page-accent-background-color);
  justify-content: center;
  align-items: center;
`;

export type TestimonialsProps = {
  module: SetPageTestimonialsModule;
};

export const Testimonials = ({ module }: TestimonialsProps) => {
  const { runtime } = useTheme();

  return (
    <Card title={module.title} align={module.styles?.heading.align}>
      <Wrapper>
        <Swiper
          grabCursor={true}
          loop={true}
          navigation={true}
          modules={[Navigation]}
        >
          {module.testimonials.map((review, i) => (
            <SwiperSlide key={i}>
              <Content>
                <Stars>
                  {Array(review.stars)
                    .fill(0)
                    .map((n, i) => (
                      <FaStar key={i} fill={`url(#${runtime.id})`} />
                    ))}
                </Stars>
                <Content>{review.content}</Content>
                <Name>{review.name}</Name>
                <Title>{review.title}</Title>
              </Content>
            </SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>
    </Card>
  );
};
