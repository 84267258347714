import amazonDark from '../../assets/image/amazon-music-dark.png';
import amazonLight from '../../assets/image/amazon-music-light.png';
import appleMusicDark from '../../assets/image/apple-music-dark.png';
import appleMusicLight from '../../assets/image/apple-music-light.png';
import deezerDark from '../../assets/image/deezer-dark.png';
import deezerLight from '../../assets/image/deezer-light.png';
import googlePlayDark from '../../assets/image/google-play-dark.png';
import googlePlayLight from '../../assets/image/google-play-light.png';
import soundcloudDark from '../../assets/image/soundcloud-dark.png';
import soundcloudLight from '../../assets/image/soundcloud-light.png';
import spotifyDark from '../../assets/image/spotify-dark.png';
import spotifyLight from '../../assets/image/spotify-light.png';
import youtubeMusicDark from '../../assets/image/youtube-music-dark.png';
import youtubeMusicLight from '../../assets/image/youtube-music-light.png';
import youtubeVideoDark from '../../assets/image/youtube-videos-dark.png';
import youtubeVideoLight from '../../assets/image/youtube-videos-light.png';

export const Services = [
  'Youtube_Music',
  'Deezer',
  'Youtube_Videos',
  'Soundcloud',
  'Spotify',
  'Google_Play',
  'Amazon_Music',
  'Apple_Music',
] as const;
type Service = (typeof Services)[number];

export type LinksType = {
  service: Service;
  image: string;
  url: string;
  title?: string;
};

export const streamingIconsDark = {
  Deezer: deezerDark,
  Youtube_Videos: youtubeVideoDark,
  Youtube_Music: youtubeMusicDark,
  Soundcloud: soundcloudDark,
  Spotify: spotifyDark,
  Google_Play: googlePlayDark,
  Amazon_Music: amazonDark,
  Apple_Music: appleMusicDark,
} as const;

export const streamingIconsLight = {
  Deezer: deezerLight,
  Youtube_Videos: youtubeVideoLight,
  Youtube_Music: youtubeMusicLight,
  Soundcloud: soundcloudLight,
  Spotify: spotifyLight,
  Google_Play: googlePlayLight,
  Amazon_Music: amazonLight,
  Apple_Music: appleMusicLight,
} as const;

export const gradients = [
  '120deg, rgba(254, 140, 0, .6), rgba(248, 54, 0, .6)',
  '120deg, rgba(252, 0, 255, 0.6), rgba(0, 219, 222, 0.6)',
  '120deg,rgba(0,242,96,.6),rgba(5,117,230,.6)',
  '120deg, rgba(218, 34, 255, .6), rgba(151, 51, 238, .6)',
  '120deg, rgba(244, 92, 67, .6), rgba(235, 51, 73, .6)',
  '120deg,rgba(0,210,255,.6),rgba(58,123,213,.6)',
  '120deg, rgba(155, 233, 49, .6), rgba(86, 187, 68, .6)',
  '120deg,rgba(255,0,153,.6),rgba(73,50,64,.6)',
];
