import { SetPageQuickLinksIntegration } from '@max/common/setpage';
import styled from 'styled-components';
import { Entries } from 'type-fest';
import { ICONS } from './constants';

const Container = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 10;
  overflow: auto;
  background: var(--set-page-black);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
`;

const Link = styled.a`
  display: flex;
  width: 132px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px;
  text-decoration: none;
  color: var(--set-page-white);
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  letter-spacing: 1px;
  text-transform: capitalize;

  &:not(:last-of-type) {
    border-right: 1px solid #222;
  }
`;

interface QuickLinksProps {
  links: Partial<SetPageQuickLinksIntegration>;
}

export const QuickLinks = ({ links }: QuickLinksProps) => {
  const entries = Object.entries(
    links,
  ) as Entries<SetPageQuickLinksIntegration>;

  return (
    <Container>
      <Content>
        {entries.map(([type, data], index) => {
          const Icon = ICONS[type];

          return (
            <Link
              key={index}
              href={data.link}
              title={data.title}
              target="_blank"
              rel="noreferrer"
            >
              <Icon size={18} />
              {data.title}
            </Link>
          );
        })}
      </Content>
    </Container>
  );
};
