import * as Sentry from '@sentry/react';
import { createRootRoute, Outlet } from '@tanstack/react-router';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';

const RouteComponent = () => {
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.uid,
        email: user.email ?? undefined,
      });
    }
  }, [user]);

  return <Outlet />;
};

export const Route = createRootRoute({
  component: RouteComponent,
});
