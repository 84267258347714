import { AnalyticsTrackEvent } from '@max/common/setpage/analytics';
import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';

export interface AnalyticsContext {
  track?: (event: AnalyticsTrackEvent) => void;
}

const Context = createContext<AnalyticsContext>({});

export const AnalyticsProvider = ({
  children,
  ...props
}: PropsWithChildren<AnalyticsContext>) => {
  useEffect(() => {
    if (import.meta.env.MODE === 'production') {
      TagManager.initialize({
        gtmId: import.meta.env.VITE_GTM_ID,
      });
    }
  }, []);

  return <Context.Provider value={props}>{children}</Context.Provider>;
};

export const useAnalytics = () => useContext(Context);
