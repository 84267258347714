import { PropsWithChildren, createContext, useContext } from 'react';
import { Coordinates } from '../types';

export interface CoordinatesContext {
  coordinates?: Coordinates;
  countryCode?: string;
}

const Context = createContext<CoordinatesContext>({});

export const CoordinatesProvider = ({
  children,
  ...props
}: PropsWithChildren<CoordinatesContext>) => (
  <Context.Provider value={props}>{children}</Context.Provider>
);

export const useCoordinatesContext = () => useContext(Context);
