import { SetPageMedia } from '@max/common/setpage';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAnalytics, useAssetsContext } from '../../contexts';
import { Video } from '../video';

const Container = styled.div`
  --swiper-theme-color: white;
  --swiper-navigation-size: 22px;
`;

const Item = styled.div`
  height: 200px;
`;

interface CarouselLayoutProps {
  videos: SetPageMedia[];
}

export const CarouselLayout = ({ videos }: CarouselLayoutProps) => {
  const { track } = useAnalytics();
  const { assetUrl } = useAssetsContext();

  return (
    <Container data-aos="set-page-fade-right">
      <Swiper
        modules={[Navigation, EffectCoverflow]}
        effect="coverflow"
        coverflowEffect={{ depth: 650 }}
        slidesPerView={1.25}
        navigation={true}
        centeredSlides={true}
        spaceBetween={0}
        loop={true}
      >
        {videos.map((video, index) => (
          <SwiperSlide key={index}>
            <Item onClick={() => track?.({ type: 'click', id: video.id })}>
              <Video
                url={video.url}
                title={video.title}
                thumbnail={
                  video.background
                    ? assetUrl({ url: video.background })
                    : undefined
                }
              />
            </Item>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};
